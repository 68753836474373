import { select, put, call, take } from 'redux-saga/effects'
import { name, createAsyncSaga, getInApp } from '../dependencies'
import { getPublisherId } from '@/entities/Publisher'
import { 
    generateClaim, 
    getRefreshTokens 
} from '@/entities/Authentication'
import { setRedirectBase, setRedirectPath } from '@/entities/Progress'
import { putResolve } from 'redux-saga/effects'

const constructRedirect = createAsyncSaga(
    `${name}/constructRedirect`,
    function* ({
        fulfilled,
        rejected,
    }) {
        try {            
            const publisherId = yield select(getPublisherId)
            const inApp = yield select(getInApp)

            if (publisherId === null) {
                throw new Error('publisher not provided')
            }
            const tokens = yield select(getRefreshTokens)
            
            if (tokens.refresh_token.default === null) {
                throw new Error('refresh_token not provided')
            }

            yield put(yield call(generateClaim, {
                publisherId,
                token: tokens.access_token.default,
            }))

            yield take(generateClaim.pending)

            const claimResult = yield take([generateClaim.fulfilled, generateClaim.rejected])

            const claim = claimResult?.payload?.claim

            if (claimResult.type === generateClaim.rejected || !claim) {
                throw new Error('Claim rejected')
            }
            if (inApp) {
                yield putResolve(setRedirectBase('hype://callback'))
                yield putResolve(setRedirectPath(`claim=${claim}&`))
            } else {
                yield putResolve(setRedirectPath(`_claim=${claim}&`))
            }

            yield fulfilled(claimResult)

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default constructRedirect
