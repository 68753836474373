import { createAsyncSaga, name, endpoints } from '../dependencies'

const signupWithEmailPassword = createAsyncSaga(
    `${name}/signupWithEmailPassword`,
    function* ({
        payload: {
            email = null,
            password = null,
            firstName = null,
            tagId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!email) {
                throw Error('email must be provided')
            }
    
            if (!password) {
                throw Error('password must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url:  endpoints.SIGNUP(),
                data: {
                    email,
                    password,
                    first_name: firstName,
                    tag_id: tagId,
                    shouldIssueToken: true,
                },
            })

            yield fulfilled({
                ...response,
                calledFrom: 'signupWithEmailPassword'
            })
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default signupWithEmailPassword

