import {
    createSlice,
    createDraftSafeSelector
} from '@reduxjs/toolkit'
import requestKitIdFromSourceForm from './requestKitIdFromSourceForm'
import { name } from '../dependencies'
import { createAction } from '@reduxjs/toolkit'

const initialState = {
    sourceFormId: null,
    remixInProgress: false,
    remixError: null,
}

const setSourceFormIdFromStepOne = createAction(`${name}/SET_SOURCE_FORM_ID`)

const remixSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [setSourceFormIdFromStepOne]: (state: any, { payload }: any) => {
            state.sourceFormId = payload.sourceFormId
        },
        [requestKitIdFromSourceForm.pending]: (state: any) => {
            state.remixInProgress = true
        },
        [requestKitIdFromSourceForm.fulfilled]: (state: any, { payload }: any) => {
            state.remixInProgress = false
        },
        [requestKitIdFromSourceForm.rejected]: (state: any, { error }: any) => {
            state.remixInProgress = false
            state.remixError = error
        },
        'PURGE': (state: any) => {
            state = initialState
        }
    }
})

// SELECTORS
const getRemix = (state: any) => state[name]
const getSourceFormIdFromStepOne = createDraftSafeSelector(
    getRemix,
    (Remix: any) => Remix?.sourceFormId,
)

// GENERAL

const takes = [
    ...requestKitIdFromSourceForm.takes,
]

const RemixInitialState = {
    [name]: initialState,
}

const RemixReducer = {
    [name]: remixSlice.reducer,
}

const RemixActions = {
    ...remixSlice.actions
}

// EXPORTS
export default remixSlice.reducer

export {
    RemixInitialState as initialState,
    RemixReducer as reducer,
    RemixActions as actions,
    takes,
    getRemix,
    getSourceFormIdFromStepOne,
    setSourceFormIdFromStepOne,
    requestKitIdFromSourceForm
}
