import { createAsyncSaga, name } from '../dependencies'
import { call } from 'redux-saga/effects'

const identify = createAsyncSaga(
    `${name}/identify`,
    function* ({
        payload: {
            analytics,
            publisherId,
            eventType,
            eventData = null,
        } = {},
        fulfilled,
        rejected,
    }) {
        try {
            if (!eventType) {
                throw Error('eventType must be provided')
            }
            if (!publisherId && !eventData?.publisher_id) {
                throw Error('publisherId must be provided')
            }
            yield call(analytics.identify, publisherId || eventData.publisher_id, eventData, eventType)
            yield fulfilled({ eventType, eventData })
        } catch (error) {
            yield rejected(error?.message)
            console.error(`${name}/identify`, { error })
        }
    }
)

export default identify
