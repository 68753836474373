import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import getTrialInfo from './getTrialInfo'
import generateCheckoutSession from './generateCheckoutSession'

const initialState = {
    trial: null,
    trialInProgress: false,
    trialError: null,
    generateCheckoutSessionInProgress: false,
    generateCheckoutSessionError: null,
    generateCheckoutSessionSuccess: null,
}

const purchasesSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [getTrialInfo.pending]: (state: any, { payload }: any) => {
            state.trial = null
            state.trialInProgress = true
            state.trialError = null
        },
        [getTrialInfo.fulfilled]: (state: any, { payload }: any) => {
            state.trial = payload
            state.trialInProgress = false
            state.trialError = null
        },
        [getTrialInfo.rejected]: (state: any, { payload: error }: any) => {
            state.trial = null
            state.trialInProgress = false
            state.trialError = error
        },
        [generateCheckoutSession.pending]: (state: any, { payload }: any) => {
            state.generateCheckoutSessionLoading = true
            state.generateCheckoutSessionError = null
            state.generateCheckoutSessionSuccess = null
        },
        [generateCheckoutSession.fulfilled]: (state: any, { payload }: any) => {
            state.generateCheckoutSessionLoading = false
            state.generateCheckoutSessionError = null
            state.generateCheckoutSessionSuccess = payload
        },
        [generateCheckoutSession.rejected]: (state: any, { payload: error }: any) => {
            state.generateCheckoutSessionLoading = false
            state.generateCheckoutSessionError = error
            state.generateCheckoutSessionSuccess = null
        },
    },
})

// SELECTORS

const getPurchases = (state) => state[name]

const getTrial = createDraftSafeSelector(
    getPurchases,
    (Purchases: any) => Purchases?.trial
)
const getTrialInProgress = createDraftSafeSelector(
    getPurchases,
    (Purchases: any) => Purchases?.trialInProgress
)
const getTrialError = createDraftSafeSelector(
    getPurchases,
    (Purchases: any) => Purchases?.trialError
)
const getShowTrial = createDraftSafeSelector(
    getTrial,
    (trial: any) => trial?.trial_period_days > 0 && trial?.trial_app_tier_label !== null
)
const getGenerateCheckoutSessionInProgress = createDraftSafeSelector(
    getPurchases,
    (Purchases: any) => Purchases.generateCheckoutSessionInProgress,
)
const getGenerateCheckoutSessionError = createDraftSafeSelector(
    getPurchases,
    (Purchases: any) => Purchases.generateCheckoutSessionError,
)
const getGenerateCheckoutSessionSuccess = createDraftSafeSelector(
    getPurchases,
    (Purchases: any) => Purchases.generateCheckoutSessionSuccess,
)


// GENERAL
const purchasesInitialState = {
    [name]: initialState,
}

const purchasesReducer = {
    [name]: purchasesSlice.reducer,
}

const takes = [
    ...getTrialInfo.takes,
    ...generateCheckoutSession.takes,
]

// EXPORTS
export default purchasesSlice.reducer

export {
    purchasesInitialState as initialState,
    purchasesReducer as reducer,
    takes,
    getTrial,
    getTrialInProgress,
    getTrialError,
    getTrialInfo,
    getShowTrial,
    generateCheckoutSession,
    getGenerateCheckoutSessionInProgress,
    getGenerateCheckoutSessionError,
    getGenerateCheckoutSessionSuccess,
}
