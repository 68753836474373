import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePublisher } from '../Publisher'
import { isEmpty } from './dependencies'
import { 
    selectAll, 
    getSelectedKitOptionId, 
    requestToSetKitOptionId, 
    setKitOptionIdSelected, 
    setKits, 
    getIsKitOptionsSettingInProgress, 
    getHasKitOptionsBeenFetched, 
} from '@/entities/KitOptions'
import { showConsoleLogs } from '@/utils/console'

const useKitOptions = () => {
    const { publisherId, selectedPublisher } = usePublisher()
    const _selectedKitOptionId = useSelector(getSelectedKitOptionId)
    const dispatch = useDispatch()
    const kitOptions = useSelector(selectAll)
    const hasBeenFetched = useSelector(getHasKitOptionsBeenFetched)
    const isKitOptionsSettingInProgress = useSelector(getIsKitOptionsSettingInProgress)

    const requestToSetKits = useCallback((kits: any) => {
        if (!hasBeenFetched && !isEmpty(kits)) {
            dispatch(
                setKits(kits)
            )
        }
    }, [dispatch, hasBeenFetched])

    const setKitOptionId = useCallback((selectedKitOptionId = null) => {
        dispatch(
            setKitOptionIdSelected({ kitId: selectedKitOptionId })
        )
    }, [dispatch])

    const sendRequestToSetKitOptionId = useCallback((selectedKitOptionId: string, tagId: string) => {
        showConsoleLogs() && console.log(selectedKitOptionId, 'kitoptionid')
        showConsoleLogs() && console.log('publisherId', publisherId, 'selectedPublisher', selectedPublisher)
        dispatch(
            requestToSetKitOptionId({ 
                kitId: selectedKitOptionId, 
                publisherId: publisherId,
                tagId
            })
        )
    }, [dispatch, publisherId, selectedPublisher])

    return {
        kitOptions,
        selectedKitOptionId: _selectedKitOptionId,
        sendRequestToSetKitOptionId,
        requestToSetKits,
        isKitOptionsSettingInProgress,
        hasBeenFetched,
        setKitOptionId,
    }
}

export default useKitOptions
