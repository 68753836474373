import { getPublisherId } from '@/entities/Publisher'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import { select } from 'redux-saga/effects'
import { getEmail, getUserId } from '@/entities/Authentication'
import { getUsername } from '@/entities/QueryParams'

const claimPageWithEmail = createAsyncSaga(
    `${name}/claimPageWithEmail`,
    function* ({
        payload: {
            code,
        },
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const publisherid = yield select(getPublisherId)
            const username = yield select(getUsername)
            const userId = yield select(getUserId)
            const email = yield select(getEmail)

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `setup/claim`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    email,
                    code,
                    user_id: userId,
                    username
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default claimPageWithEmail

