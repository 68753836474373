import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'
import { name } from '../dependencies'

const initialState = {
    claim: null,
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
    sourceFormId: null,
    username: null,
    email: null,
    name: null,
    tagId: null,
    tagType: null,
    tagPackSize: null,
    inApp: null,
    login: null,
    tagColor: null,
    processed: null,
    goTo: null,
}

const queryParamsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setQueryParams(state: any, { payload: queryParams = {} }) {
            const {
                claim,
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,
                sourceFormId,
                username,
                email,
                name,
                tagId,
                tagType,
                tagColor,
                tagPackSize,
                inApp,
                login,
                goTo
            }: any = queryParams

            if (claim) {
                state.claim = claim
            }

            if (utmSource) {
                state.utmSource = utmSource
            }

            if (utmMedium) {
                state.utmMedium = utmMedium
            }

            if (utmCampaign) {
                state.utmCampaign = utmCampaign
            }

            if (utmTerm) {
                state.utmTerm = utmTerm
            }

            if (utmContent) {
                state.utmContent = utmContent
            }

            if (sourceFormId) {
                state.sourceFormId = sourceFormId
            }

            if (username) {
                state.username = username
            }

            if (email) {
                state.email = email
            }

            if (name) {
                state.name = name
            }

            if (tagId) {
                state.tagId = tagId
            }

            if (tagType) {
                state.tagType = tagType
            }

            if (tagColor) {
                state.tagColor = tagColor
            }

            if (tagPackSize) {
                state.tagPackSize = tagPackSize
            }

            if (inApp) {
                state.inApp = inApp
            }

            if (login) {
                state.login = login
            }

            if (goTo) {
                state.goTo = goTo
            }

            state.processed = true

            return state
        },
    },
})

const {
    setQueryParams,
} = queryParamsSlice.actions

// SELECTORS
const getQueryParams = (state: any) => state[name]

const getProcessed = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.processed,
)

const getClaimFromQueryParams = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.claim,
)

const getUtmSource = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.utmSource,
)

const getUtmMedium = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.utmMedium,
)

const getUtmCampaign = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.utmCampaign,
)

const getUtmTerm = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.utmTerm,
)

const getUtmContent = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.utmContent,
)

const getSourceFormId = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.sourceFormId,
)

const getUsername = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.username,
)

const getEmail = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.email,
)

const getName = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.name,
)

const getTagId = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.tagId,
)

const getTagType = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.tagType,
)

const getTagColor = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.tagColor,
)

const getTagPackSize = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.tagPackSize,
)

const getInApp = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.inApp,
)

const getLogin = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.login,
)

const getGoTo = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.goTo,
)

// GENERAL
const QueryParamsInitialState = {
    [name]: initialState,
}

const QueryParamsReducer = {
    [name]: queryParamsSlice.reducer,
}

// EXPORTS
export default queryParamsSlice.reducer

export {
    QueryParamsInitialState as initialState,
    QueryParamsReducer as reducer,

    setQueryParams,

    getClaimFromQueryParams,
    getUtmSource,
    getUtmMedium,
    getUtmCampaign,
    getUtmTerm,
    getUtmContent,
    getSourceFormId,
    getUsername,
    getEmail,
    getName,
    getTagId,
    getTagType,
    getTagColor,
    getTagPackSize,
    getInApp,
    getLogin,
    getProcessed,
    getGoTo,
}
