import { useSelector } from 'react-redux'
import { 
    getSetupPublisherError,
    getUsernameCheckError,
    getPublisherUpdateError,
    getMeError
} from '@/entities/Publisher'
import { 
    getClaimError
} from '@/features/ClaimUsernameCheck'
import {
    getEmailAuthError,
    getSocialAuthError,
    getEmailSignupError,
} from '@/entities/Authentication'
import { getStepError } from '@/entities/Progress'

const useErrors = () => {
    const _emailAuthError = useSelector(getEmailAuthError)
    const _setupPublisherError = useSelector(getSetupPublisherError)
    const _emailSignupError = useSelector(getEmailSignupError)
    const _socialAuthError = useSelector(getSocialAuthError)
    const _stepError = useSelector(getStepError)
    const _usernameCheckError = useSelector(getUsernameCheckError)
    const _publisherUpdateError = useSelector(getPublisherUpdateError)
    const _claimError = useSelector(getClaimError)
    const _getMeError = useSelector(getMeError)

    return {
        emailAuthError: _emailAuthError,
        setupPublisherError: _setupPublisherError,
        emailSignupError: _emailSignupError,
        socialAuthError: _socialAuthError,
        stepError: _stepError,
        usernameCheckError: _usernameCheckError,
        publisherUpdateError: _publisherUpdateError,
        claimError: _claimError,
        getMeError: _getMeError
    }
}

export default useErrors
