import { createAsyncSaga, name, endpoints } from '../dependencies'

const confirmOneTimeCode = createAsyncSaga(
    `${name}/confirmOneTimeCode`,
    function* ({
        payload: {
            code = null,
            email = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
             
            if(!code) {
                throw Error('code must be provided')
            }

            if (!email) {
                throw new Error('email not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `auth/publisher/code/confirm`,
          
                data: {
                    code,
                    ...email ? { 
                        email
                    } : {

                    }
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.response?.data?.message || 'Could not confirm code.'
            })
        }
    }
)

export default confirmOneTimeCode
