import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'
import requestToSetKitOptionId from './requestToSetKitOptionId'

const kitOptionsAdapter = createEntityAdapter({
    selectId: (kitOption: any) => kitOption?.id,
    sortComparer: (a: any, b: any) => a.priority - b.priority,
})

const initialState = kitOptionsAdapter.getInitialState({
    data: [],
    error: null,
    selectedKitId: null,
    settingInProgress: false,
    kitOptionsFetchedAndSet: false,
    kitIdFetchingInProgress: false,
})

const kitOptionsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setKits: (state: any, { payload }: any) => {
            kitOptionsAdapter.setAll(state, payload)
            state.kitOptionsFetchedAndSet = true
        },
        setKitOptionIdSelected: (state: any, { payload }: any) => {
            state.selectedKitId = payload?.kitId
        },
        setKitsError: (state: any, { error }: any) => {
            state.error = error
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(requestToSetKitOptionId.pending, (state: any) => {
                state.settingInProgress = true
            })
            .addCase(requestToSetKitOptionId.fulfilled, (state: any, { payload }: any) => {
                state.selectedKitId = payload
                state.settingInProgress = false
            })
            .addCase(requestToSetKitOptionId.rejected, (state: any, { error }: any) => {
                state.settingInProgress = false
                state.error = error
            })
            .addCase('PURGE', (state: any) => {
                kitOptionsAdapter.removeAll(state)
                state = initialState
        })
    }
})

// SELECTORS
const getKitOptions = (state: any) => state[name]

const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
} = kitOptionsAdapter.getSelectors((state: any) => state[name])

const {
    setKits,
    setKitsError,
    setKitOptionIdSelected,
} = kitOptionsSlice.actions

const getKitOptionsData = createDraftSafeSelector(
    getKitOptions,
    (KitOptions: any) => KitOptions?.data,
)

// const getAllKitOptions = createDraftSafeSelector(
//     selectAll,
//     (kitOptions: any) => kitOptions,
// )

const getSelectedKitOptionId = createDraftSafeSelector(
    getKitOptions,
    (KitOptions: any) => KitOptions?.selectedKitId,
)

const getHasKitOptionsBeenFetched = createDraftSafeSelector(
    getKitOptions,
    (KitOptions: any) => KitOptions?.kitOptionsFetchedAndSet,
)

const getIsKitOptionsSettingInProgress = createDraftSafeSelector(
    getKitOptions,
    (KitOptions: any) => KitOptions?.settingInProgress,
)

const getSelectedKitOption = createDraftSafeSelector(
    [
        getSelectedKitOptionId,
        (state: any) => (id = null) => selectById(state, id),
    ],
    (selectedKitOptionId: any, _selectById: any) => {
        return _selectById?.(selectedKitOptionId)
    },
)

const getKitsError = createDraftSafeSelector(
    getKitOptions,
    (KitOptions: any) => KitOptions?.error,
)

// GENERAL

const takes = [
    ...requestToSetKitOptionId.takes,
]

const KitOptionsInitialState = {
    [name]: initialState,
}

const KitOptionsReducer = {
    [name]: kitOptionsSlice.reducer,
}

// EXPORTS
export default kitOptionsSlice.reducer

export {
    takes,

    KitOptionsInitialState as initialState,
    KitOptionsReducer as reducer,
    getKitOptionsData,
    requestToSetKitOptionId,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    setKits,
    setKitOptionIdSelected,
    getSelectedKitOptionId,
    getSelectedKitOption,
    setKitsError,
    getKitsError,
    getHasKitOptionsBeenFetched,
    getIsKitOptionsSettingInProgress,
}
