import { createAsyncSaga, name, endpoints } from '../dependencies'

const generateClaim = createAsyncSaga(
    `${name}/generateClaim`,
    function* ({
        payload: {
            publisherId = null,
            token,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.AUTH_PUBLISHER_CLAIM()}`,
                headers: {
                    publisherid: publisherId,
                    // use the token with Bearer
                    Authorization: `Bearer ${token}`,
                },
            })

            if (!response.claim) {
                throw new Error('Could not get claim')
            }

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default generateClaim
