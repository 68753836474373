import { _network } from '@/utils/store/middleware/networkMiddleware'
import { combineReducers } from '@reduxjs/toolkit'

import Analytics from '@/entities/Analytics'
import Authentication from '@/entities/Authentication'
import QueryParams from '@/entities/QueryParams'
import Publisher from '@/entities/Publisher'
import KitOptions from '@/entities/KitOptions'
// import User from '@/entities/User'
// import RefreshTokens from '@/entities/RefreshTokens'
import Progress from '@/entities/Progress'
import Scrapeyard from '@/entities/Scrapeyard'
import ClaimUsernameCheck from '@/features/ClaimUsernameCheck'
import Remix from '@/entities/Remix'
import FeatureFlags from '@/entities/FeatureFlags'
import Tags from '@/entities/Tags'
import Purchases from '@/entities/Purchases'

export const initialAppState = {
    ...Analytics.initialState,
    ...Authentication.initialState,
    ...QueryParams.initialState,
    ...Publisher.initialState,
    ...KitOptions.initialState,
    ...Progress.initialState,
    ...Scrapeyard.initialState,
    ...ClaimUsernameCheck.initialState,
    ...Remix.initialState,
    ...FeatureFlags.initialState,
    ...Tags.initialState,
    ...Purchases.initialState,
    // ...User.initialState,
    // ...RefreshTokens.initialState,
}

const rootReducer = combineReducers({
    _network,
    ...Analytics.reducer,
    ...Authentication.reducer,
    ...QueryParams.reducer,
    ...Publisher.reducer,
    ...KitOptions.reducer,
    ...Progress.reducer,
    ...Scrapeyard.reducer,
    ...ClaimUsernameCheck.reducer,
    ...Remix.reducer,
    ...FeatureFlags.reducer,
    ...Tags.reducer,
    ...Purchases.reducer,
    // ...User.reducer,
    // ...RefreshTokens.reducer
})

export default rootReducer
