import { maskEmail } from '../../dependencies'
import Button from '@/components/Button'
import cx from 'classnames'
import useOneTimeCode from '@/entities/Authentication/useOneTimeCode'
import React, { useCallback, useEffect, useState } from 'react'

import OneTimePasscode from '@piconetworks/pkg-otp'
import { useDispatch } from 'react-redux'
import { useProgress } from '@/entities/Progress'
import useClaimUsername from '../../useClaimUsername'


const Email = () => {
    const dispatch = useDispatch()
    const [currentPasscode, setCurrentPasscode] = useState('')
    const { goToNextStep } = useProgress()
    const {
        socialUsernames,
        claimPageWithEmail,
        claimInprogress,
        claimError,
        claimConfirmed
    } = useClaimUsername()
    const {
        requestOneTimeCode,
        oneTimeCodeSent,
    } = useOneTimeCode()

    const processCode = useCallback(() => {
        try {
            claimPageWithEmail({ code: currentPasscode })
        } catch (error) {
            console.error(error)
        }
    }, [currentPasscode, claimPageWithEmail])


    const resendOTP = useCallback(() => {
        requestOneTimeCode({ email: socialUsernames.email })
    }, [socialUsernames.email, requestOneTimeCode])


    useEffect(() => {
        if (currentPasscode.length === 6) {
            processCode()
        }
    }, [currentPasscode])


    if (oneTimeCodeSent || claimConfirmed) {
        return (
            <div>
                <div>
                    <div className='mb-3'>
                        <label className='text-picoblack text-opacity-70 text-sm font-normal '>
                            {`A one-time passcode has been sent to ${maskEmail(socialUsernames.email)}.`}
                        </label>
                    </div>
                    <div className={claimInprogress ? 'animate-pulse' : ''}>
                        <OneTimePasscode
                            isDisabled={claimInprogress}
                            isErrored={false}
                            isInputNum={true}
                            className='otp'
                            shouldAutoFocus={true}
                            numInputs={6}
                            currentPasscode={currentPasscode}
                            setCurrentPasscode={setCurrentPasscode}
                            errorText={'something went wrong'}
                        />
                    </div>
                </div>
                <div className='mt-3' >
                    <p className='text-xs text-gray-700 font-medium'>
                        Didn’t receive a code? <span className='cursor-pointer text-violet-500' onClick={resendOTP}>Resend.</span>
                    </p>
                </div>
                {claimInprogress && (
                    <div className="flex items-center mt-4 " role="alert">
                        <svg aria-hidden="true" className="inline w-4 h-4 mr-2 text-green-300 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="font-medium text-sm text-green-500">Just a moment, we&apos;re processing your code.</span>
                    </div>
                )}
                {claimConfirmed && (
                    <div className="flex items-center mt-4 " role="alert" data-testid='alert-success'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" className="inline w-4 h-4 mr-2 fill-green-500">
                            <path d="M1.41 6.48L5.87742 10.9441L16.59 0.229996L18 1.65L5.87868 13.7724L0 7.89L1.41 6.48Z" fill="currentFill" />
                        </svg>
                        <span className="font-medium text-sm text-green-500">Hooray! Your email has been successfully verified.</span>
                    </div>
                )}
                {claimError && (
                    <div className="flex items-center mt-4 " role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" className="inline w-4 h-4 mr-2 fill-red-500">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7043 1.28987C15.0947 1.68024 15.0947 2.30893 14.7043 2.69929L9.41073 7.9971L14.7043 13.2949C15.0947 13.6853 15.0947 14.314 14.7043 14.7043C14.314 15.0947 13.6853 15.0947 13.2949 14.7043L7.9971 9.41073L2.69929 14.7043C2.30893 15.0947 1.68024 15.0947 1.28987 14.7043C0.89951 14.314 0.89951 13.6853 1.28987 13.2949L6.58347 7.9971L1.28987 2.69929C0.89951 2.30893 0.89951 1.68024 1.28987 1.28987C1.68024 0.89951 2.30893 0.89951 2.69929 1.28987L7.9971 6.58347L13.2949 1.28987C13.6853 0.89951 14.314 0.89951 14.7043 1.28987Z" fill="currentFill" />
                        </svg>
                        <span className="font-medium text-sm text-red-500">Oops! The code you entered doesn&apos;t match.</span>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            <Button
                className={cx('mt-8 md:mt-16 block')}
                fullWidth
                color='primary'
                type='submit'
                onClick={(e) => {
                    requestOneTimeCode({ email: socialUsernames.email })
                }}
            >
                Verify with email
            </Button>
        </>
    )
}
export default Email
