import '@/styles/globals.scss'
import '@piconetworks/pkg-form/dist/bundle.css'
import '@piconetworks/pkg-progress-bar/dist/bundle.css'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Provider } from 'react-redux'

import { useEffect, useState } from 'react'
import { store } from '@/utils/store/index'
import { detectIncognito } from 'detectincognitojs'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { METRICS_ENDPOINT } from '@piconetworks/pkg-endpoints'
import { PicoAnalyticsProvider } from '@piconetworks/pkg-pico-analytics'
import { showConsoleLogs } from '@/utils/console'

const gtmArgs = {
    gtmId: process.env.GTM_TAG,
    dataLayer: {
        SiteType: 'signup',
    },
}

const App = ({ Component, pageProps }: AppProps) => {
    const [useDark, setUseDark] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const [isDark, setIsDark] = useState(false)
    let faviconColor, faviconFolder: string

    switch (process.env.STAGE) {
        case 'staging':
            faviconColor = '#FCAF2F'
            faviconFolder = 'stagingFavicon'
            break
        case 'production':
            faviconColor = '#000000'
            faviconFolder = 'productionFavicon'
            break
        default:
            faviconColor = '#FF4440'
            faviconFolder = 'localFavicon'
            break
    }

    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }
        detectIncognito().then((res: any) => {
            setIsPrivate(res?.isPrivate)
        })

        setIsDark((window?.matchMedia('(prefers-color-scheme: dark)') as any)?.matches)

        if ((isPrivate || isDark) && faviconFolder === 'productionFavicon') {
            setUseDark(true)
        }
    }, [isPrivate, isDark])

    showConsoleLogs() && console.log('isPrivate', isPrivate, 'isDark', isDark, 'useDark', useDark, 'faviconFolder', faviconFolder)

    useEffect(() => {
        ReactGA.initialize(process.env.GA)
        TagManager.initialize(gtmArgs)
    }, [gtmArgs])

    const analyticsConfig = {
        context: 'signup',
        metrics_endpoint: METRICS_ENDPOINT(),
        plugins: {
            segment: {
                writeKey: process.env.SEGMENT_WRITE_KEY,
            },
            twitter: {
                twitter_pixel_id: process.env.TWITTER_PIXEL_ID || null,
                twitter_pixel_event_map: JSON.parse(process.env.TWITTER_PIXEL_EVENT_MAP || '{}'),
                enabled: false,
            }
        }
    }
    return (
        <>
            <Head>
                <title>Sign Up - Hype</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" viewport-fit="cover" shrink-to-fit="no" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover  shrink-to-fit=no" /> */}
                <link rel="shortcut icon" href={`/kittens/${faviconFolder}/favicon.ico`} type="image/png" />
                <link rel="apple-touch-icon" sizes="180x180" href={`/kittens/${faviconFolder}/apple-touch-icon.png`} />
                {!useDark && <link rel="icon" type="image/png" sizes="32x32" href={`/kittens/${faviconFolder}/favicon-32x32-dark.png`} />}
                {useDark && <link rel="icon" type="image/png" sizes="32x32" href={`/kittens/${faviconFolder}/favicon-32x32.png`} />}
                {!useDark && <link rel="icon" type="image/png" sizes="16x16" href={`/kittens/${faviconFolder}/favicon-16x16-dark.png`} />}
                {useDark && <link rel="icon" type="image/png" sizes="16x16" href={`/kittens/${faviconFolder}/favicon-16x16.png`} />}
                <link rel="mask-icon" href={`/kittens/${faviconFolder}/safari-pinned-tab.svg`} color={`${faviconColor}`}></link>
                <meta property="og:title" content="Sign Up - Hype" key="title" />
                <meta property="og:description" content="Build landing pages, grow your audience, and get paid on your terms, all in one place." />
                <meta property="og:url" content="https://www.hype.co/signup" />
                <meta property="og:image" content="/kittens/og-image.png" />
                <meta property="og:image:type" content="image/png" />
                <meta charSet="utf-8" />
                <noscript><img height="1" width="1" src="https://www.facebook.com/tr?id=445316090450755&ev=PageView&noscript=1"/></noscript>
            </Head>
            <PicoAnalyticsProvider config={analyticsConfig}>
                <Provider store={store}>
                    <Component {...pageProps} />
                </Provider>
            </PicoAnalyticsProvider>
        </>
    )
}

export default App