import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFlow } from './dependencies'
import { getHydrated, hydrate } from '@/entities/Scrapeyard'

const useHydration = (fetchData = false) => {
    const dispatch = useDispatch()
    const flow = useSelector(getFlow)
    const hydrated = useSelector(getHydrated)

    const requestHydration = useCallback((payload) => {
        if (!hydrated && flow !== 'claim') {
            dispatch(hydrate({
                ...payload,
                flow
            }))
        }
    }, [dispatch, flow, hydrated])

    return {
        requestHydration
    }
}

export default useHydration
