const Loading = ({
    title = null,
}) => {
    return (
        <>
            <h1 className="text-center text-xl my-3 font-semibold tracking-tighter animate-pulse">
                {title}
            </h1>
        </>
    )
}

export default Loading
