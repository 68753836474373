import { useRouter } from "next/router"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    getClaimFromQueryParams,
    setQueryParams,
    getUtmSource,
    getUtmMedium,
    getUtmCampaign,
    getUtmTerm,
    getUtmContent,
    getSourceFormId,
    getUsername,
    getEmail,
    getName,
    getTagId,
    getTagType,
    getTagColor,
    getTagPackSize,
    getInApp,
    getLogin,
    getGoTo,
} from '@/entities/QueryParams'

const useQueryParams = () => {
    const router = useRouter()
    const dispatch = useDispatch()
    const query = router?.query
    const _queryToKeep = useRef()
    const _processed = useRef(false)
    const _claim = useSelector(getClaimFromQueryParams)
    const _utmSource = useSelector(getUtmSource)
    const _utmMedium = useSelector(getUtmMedium)
    const _utmCampaign = useSelector(getUtmCampaign)
    const _utmTerm = useSelector(getUtmTerm)
    const _utmContent = useSelector(getUtmContent)
    const _sourceFormId = useSelector(getSourceFormId)
    const _username = useSelector(getUsername)
    const _email = useSelector(getEmail)
    const _name = useSelector(getName)
    const _tagId = useSelector(getTagId)
    const _tagType = useSelector(getTagType)
    const _tagColor = useSelector(getTagColor)
    const _tagPackSize = parseInt(useSelector(getTagPackSize))
    const _inApp = useSelector(getInApp)
    const _login = useSelector(getLogin)
    const _goTo = useSelector(getGoTo)

    const claimQuery = query?.claim
    const utmSourceQuery = query?.utm_source
    const utmMediumQuery = query?.utm_medium
    const utmCampaignQuery = query?.utm_campaign
    const utmTermQuery = query?.utm_term
    const utmContentQuery = query?.utm_content
    const sourceFormIdQuery = query?.id
    const usernameQuery = query?.username
    const emailQuery = query?.email
    const nameQuery = query?.name
    const tagIdQuery = query?.tag_id
    const tagTypeQuery = query?.tag_type
    const tagColorQuery = query?.tag_color
    const tagPackSizeQuery = parseInt(query?.tag_pack_size)
    const inAppQuery = query?.in_app
    const loginQuery = query?.login
    const goToQuery = query?.go_to

    useEffect(() => {
        if ((!claimQuery) 
            && ((utmSourceQuery === _utmSource) || !utmSourceQuery)
            && ((utmMediumQuery === _utmMedium) || !utmMediumQuery)
            && ((utmCampaignQuery === _utmCampaign) || !utmCampaignQuery)
            && ((utmTermQuery === _utmTerm) || !utmTermQuery)
            && ((utmContentQuery === _utmContent) || !utmContentQuery)
            && ((sourceFormIdQuery === _sourceFormId) || !sourceFormIdQuery)
            && ((usernameQuery === _username) || !usernameQuery)
            && ((emailQuery === _email) || !emailQuery)
            && ((nameQuery === _name) || !nameQuery)
            && ((tagIdQuery === _tagId) || !tagIdQuery)
            && ((tagTypeQuery === _tagType) || !tagTypeQuery)
            && ((tagColorQuery === _tagColor) || !tagColorQuery)
            && ((tagPackSizeQuery === _tagPackSize) || !tagPackSizeQuery)
            && ((inAppQuery === _inApp) || !inAppQuery)
            && ((loginQuery === _login) || !loginQuery)
            && ((goToQuery === _goTo) || !goToQuery)
        ) {
            _processed.current = true
            return
        }

        dispatch(setQueryParams({
            claim: claimQuery,
            utmSource: utmSourceQuery,
            utmMedium: utmMediumQuery,
            utmCampaign: utmCampaignQuery,
            utmTerm: utmTermQuery,
            utmContent: utmContentQuery,
            sourceFormId: sourceFormIdQuery,
            username: usernameQuery,
            email: emailQuery,
            name: nameQuery,
            tagId: tagIdQuery,
            tagType: tagTypeQuery,
            tagColor: tagColorQuery,
            tagPackSize: tagPackSizeQuery,
            inApp: inAppQuery,
            login: loginQuery,
            goTo: goToQuery,
        }))

        const queryToKeepObject = {
            ...({ utm_source: utmSourceQuery }),
            ...({ utm_medium: utmMediumQuery }),
            ...({ utm_campaign: utmCampaignQuery }),
            ...({ utm_term: utmTermQuery }),
            ...({ utm_content: utmContentQuery }),
        }

        _queryToKeep.current = Object.entries(queryToKeepObject)
            .reduce((acc, [key, value]) => {
                if (!value) {
                    return acc
                }

                const ampersand = acc !== '' ? '&' : ''

                return `${acc}${ampersand}${key}=${value}`
            }, '')
    }, [
        dispatch,
        claimQuery,
        router.replace,
        _utmSource,
        _utmMedium,
        _utmCampaign,
        _utmTerm,
        _utmContent,
        _sourceFormId,
        _username,
        _email,
        _name,
        _tagId,
        _tagType,
        _tagColor,
        _tagPackSize,
        _inApp,
        _login,
        _goTo,
        utmSourceQuery,
        utmMediumQuery,
        utmCampaignQuery,
        utmTermQuery,
        utmContentQuery,
        sourceFormIdQuery,
        usernameQuery,
        emailQuery,
        nameQuery,
        tagIdQuery,
        tagTypeQuery,
        tagColorQuery,
        tagPackSizeQuery,
        inAppQuery,
        loginQuery,
        goToQuery,
    ])
    return {
        claim: _claim,
        utmSource: _utmSource,
        utmMedium: _utmMedium,
        utmCampaign: _utmCampaign,
        utmTerm: _utmTerm,
        utmContent: _utmContent,
        sourceFormId: _sourceFormId,
        username: _username,
        email: _email,
        name: _name,
        tagId: _tagId,
        tagType: _tagType,
        tagColor: _tagColor,
        tagPackSize: _tagPackSize,
        inApp: _inApp,
        login: _login,
        goTo: _goTo,
        processed: _processed.current,
        queryToKeep: _queryToKeep.current ? `${_queryToKeep.current}&` : null,
    }
}

export default useQueryParams
