import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
    createAction,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'
import constructRedirect from './constructRedirect'

const stepsAdapter = createEntityAdapter({
    selectId: (step: any) => step?.order
})

const initialState = stepsAdapter.getInitialState({
    initialized: false, // should only happen once
    currentStep: 1,
    totalSteps: null,
    stepChangeInProgress: false,
    stepChangeError: null,
    flow: null,
    redirectBase: null,
    redirectPath: '',
    redirectInProgress: false,
    redirectError: null,
    debug: [],
    continueFlowAfterLogin: null,
})

const setContinueFlowAfterLogin = createAction(`${name}/SET_CONTINUE_FLOW_AFTER_LOGIN`, function prepare(continueFlowAfterLogin: boolean) {
    return {
      payload: {
        continueFlowAfterLogin
      }
    }
  })

const progressSlice = createSlice({
    name,
    initialState,
    reducers: {
        initializeSteps: (state: any, { payload }: any) => {
            state.initialized = true
            // stepsAdapter.setAll(state, payload || [])
        },
        transitionToNextStep: (state: any, { payload }: any) => {
            state.debug.push({
                currentStep: state.currentStep,
                nextStep: state.currentStep + 1,
                ...payload
            })
            state.currentStep += 1
            state.stepChangeInProgress = true
        },
        transitionComplete: (state: any, {}: any) => {
            state.stepChangeInProgress = false
            state.stepChangeError = null
        },
        setComplete: stepsAdapter.updateOne,
        setCompleted: (state: any, { payload: { step } }: any) => {
            stepsAdapter.updateOne(state, { id: step, changes: { completed: true } })
        },
        goBackAStep: (state: any) => {
            state.currentStep -= 1
        },
        resetProgress: (state: any) => {
            stepsAdapter.setAll(state, initialState.entities)
            state.currentStep = 1
        },
        setStepError: (state: any, { payload: error }: any) => {
            state.stepChangeError = error
        },
        setFlow: (state: any, { payload: flow }: any) => {
            state.flow = flow
        },
        setRedirectBase: (state: any, { payload: redirectBase }: any) => {
            state.redirectBase = redirectBase
        },
        setRedirectPath: (state: any, { payload: redirectPath }: any) => {
            // combine the redirect path with the current redirect path
            // if the path hasn't been added already
            if (!state.redirectPath.includes(redirectPath)) {
                state.redirectPath = `${state.redirectPath}${redirectPath}`
            }
        },
        setTotalSteps: (state: any, { payload: totalSteps }: any) => {
            state.totalSteps = totalSteps
        },
        goToSecondLastStep: (state: any, { payload }: { payload: any }) => {
            state.debug.push({
                currentStep: state.currentStep,
                nextStep: state.totalSteps - 1,
                ...payload
            })
            state.currentStep = state.totalSteps - 1
            state.stepChangeInProgress = true
        },
        goToLastStep: (state: any, { payload }: { payload: any }) => {
            state.debug.push({
                currentStep: state.currentStep,
                nextStep: state.totalSteps,
                ...payload
            })
            state.currentStep = state.totalSteps
            state.stepChangeInProgress = true
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase('PURGE', (state: any) => {
                stepsAdapter.removeAll(state)
                state.flow = null
                state.currentStep = 1
            })
            .addCase(`${name}/SET_CONTINUE_FLOW_AFTER_LOGIN`, (state: any, { payload }: any) => {
                state.continueFlowAfterLogin = payload?.continueFlowAfterLogin
            })
            .addCase(constructRedirect.pending, (state: any, { payload }: any) => {
                state.redirectInProgress = true
                state.redirectError = null
            })
            .addCase(constructRedirect.fulfilled, (state: any, { payload }: any) => {
                state.redirectInProgress = false
                state.redirectError = null
            })
            .addCase(constructRedirect.rejected, (state: any, { payload: error }: any) => {
                state.redirectInProgress = false
                state.redirectError = error
            })
    }
})
const {
    initializeSteps,
    transitionToNextStep,
    transitionComplete,
    setCompleted,
    goBackAStep,
    resetProgress,
    setStepError,
    setFlow,
    setTotalSteps,
    goToLastStep,
    goToSecondLastStep,
    setRedirectBase,
    setRedirectPath,
} = progressSlice.actions

// SELECTORS
const getProgress = (state: any) => state[name]

const { selectById }= stepsAdapter.getSelectors((state: any) => state[name])

const getIsInitialized = (state: any) => state[name].initialized

const getFlow = (state: any) => state[name].flow

const getCurrentStep = createDraftSafeSelector(
    getProgress,
    (Progress: any) => Progress?.currentStep,
)

const getCurrentStepInfo = createDraftSafeSelector([
        getCurrentStep,
        (state: any) => (currentStep = null) => {
            return selectById(state, currentStep)
        },
    ],
    (currentStep: any, _selectById: any) => {
        return _selectById?.(currentStep)
    },
)

const getCurrentStepIsCompleted = createDraftSafeSelector(
    getCurrentStepInfo,
    (Step: any) => Step?.completed
)

const getNextStepInfo = createDraftSafeSelector([
    getCurrentStep,
    (state: any) => (currentStep: number) => selectById(state, currentStep+1),
],
(currentStep: any, _selectById: any) => {
    return _selectById?.(currentStep)
})

const getStepChangeInProgress = createDraftSafeSelector(
    getProgress,
    (Progress: any) => Progress?.stepChangeInProgress,
)

const getStepError = createDraftSafeSelector(
    getProgress,
    (Progress: any) => Progress?.stepChangeError
)

const getTotalSteps = createDraftSafeSelector(
    getProgress,
    (Progress: any) => Progress?.totalSteps
)
const getContinueFlowAfterLogin = createDraftSafeSelector(
    getProgress,
    (Progress: any) => Progress?.continueFlowAfterLogin
)
const getRedirectUrl = createDraftSafeSelector(
    getProgress,
    (Progress: any) => `${Progress?.redirectBase}?${Progress?.redirectPath}`,
)
const getRedirectInProgress = createDraftSafeSelector(
    getProgress,
    (Progress: any) => Progress?.redirectInProgress,
)

const takes = [
    ...constructRedirect.takes,
]

const progressInitialState = {
    [name]: initialState,
}

const progressReducer = {
    [name]: progressSlice.reducer,
}
const actions = progressSlice.actions

// EXPORTS
export default progressSlice.reducer

export {
    takes,
    progressInitialState as initialState,
    progressReducer as reducer,
    constructRedirect,
    getCurrentStep,
    getStepChangeInProgress,
    getStepError,
    setStepError,
    getCurrentStepIsCompleted,
    getNextStepInfo,
    getCurrentStepInfo,
    setCompleted,
    initializeSteps,
    getIsInitialized,
    transitionToNextStep,
    transitionComplete,
    goBackAStep,
    resetProgress,
    actions,
    getFlow,
    setFlow,
    setRedirectBase,
    setRedirectPath,
    setTotalSteps,
    getTotalSteps,
    goToLastStep,
    goToSecondLastStep,
    setContinueFlowAfterLogin,
    getContinueFlowAfterLogin,
    getRedirectUrl,
    getRedirectInProgress,
}
