import {
    takeEvery,
    takeLatest,
    put,
    putResolve,
    delay,
    call,
} from 'redux-saga/effects'

import { REQUEST } from './middleware/networkMiddleware'

const PENDING = 'pending'
const FULFILLED = 'fulfilled'
const REJECTED = 'rejected'
const INITIALIZE = 'initialize'

const createAsyncSaga = (name, saga, sagaTakeEvery = false) => {
    const sagaAction = (payload) => ({
        type: name,
        payload,
    })

    sagaAction.PENDING = PENDING
    sagaAction.FULFILLED = FULFILLED
    sagaAction.REJECTED = REJECTED
    sagaAction.INITIALIZE = INITIALIZE

    sagaAction.pending = `${name}/${PENDING}`
    sagaAction.fulfilled = `${name}/${FULFILLED}`
    sagaAction.rejected = `${name}/${REJECTED}`
    sagaAction.initialize = `${name}/${INITIALIZE}`
    sagaAction.toString = () => name
    sagaAction.saga = function* (args) {
        yield put({
            type: sagaAction[PENDING],
        })

        yield saga({
            ...args,
            fulfilled: function* (payload) {
                yield put({
                    type: sagaAction[FULFILLED],
                    payload,
                })
            },
            rejected: function* (payload) {
                yield put({
                    type: sagaAction[REJECTED],
                    payload,
                })
            },
            request: function* ({ headers = {}, ...config }) {
                const response = yield putResolve({
                    type: REQUEST,
                    config: {
                        timeout: 30000,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            ...headers,
                        },
                        ...config,
                    }
                })

                return response?.data
            },
            initialize: function* () {
                yield put({
                    type: sagaAction[INITIALIZE],
                })
            },
        })
    }

    if (sagaTakeEvery) {
        sagaAction.takes = [
            takeEvery(name, sagaAction.saga),
        ]
    } else {
        sagaAction.takes = [
            takeLatest(
                name,
                function* delayCall(payload) {
                    yield delay(500)
                    yield call(sagaAction.saga, payload)
                },
            ),
        ]
    }

    sagaAction.actions = {
        initialize: () => ({ type: sagaAction.initialize })
    }

    return sagaAction
}

export default createAsyncSaga

export {
    PENDING,
    FULFILLED,
    REJECTED,
}
