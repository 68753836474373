import { select } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherIdFromAuthentication, setPublisherIdFromAuthenticationOnClaimTokenExchange } from '../dependencies'

const switchToPublisher = createAsyncSaga(
    `${name}/switchToPublisher`,
    function* ({
        payload,
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const old_publisher_id = yield select(getPublisherIdFromAuthentication)
            // console.log('old_publisher_id', old_publisher_id)
            // console.log('payload', payload)
            if (!payload.publisher_id) {
                throw Error('publisher_id must be provided')
            }
            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: '/oauth/publisher/switch',
                data: {
                    publisher_id: old_publisher_id,
                    new_publisher_id: payload.publisher_id,
                }
            })

            setPublisherIdFromAuthenticationOnClaimTokenExchange({
                payload: {
                    publisher_id: payload.publisher_id,
                }
            })

            yield fulfilled({
                ...response,
                calledFrom: 'switchToPublisher'
            })
        } catch (error) {
            yield rejected(error.message)
        }
    }
)

export default switchToPublisher
