import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPublisherId, getTagId } from './dependencies'

import {
    fetchTags,
    countActiveTags,
    selectById,
    getAddTagToPublisherInProgress,
    getAddTagToPublisherError,
    getAddTagToPublisherSuccess,
    getHasThirdPartySalesChannels,
    getSalesChannels,
    getFetchTagsInProgress,
    getFetchTagsError,
    getFetchTagsSuccess,
} from './slice'

const useTags = (fetch = false) => {
    const dispatch = useDispatch()
    const publisherId = useSelector(getPublisherId)
    const activeTagCount = useSelector((state) => countActiveTags(state, publisherId))
    const _tagId = useSelector(getTagId)
    // we are seeing if the tag id has been added to the publisher successfully
    const tagAdded = useSelector((state) => selectById(state, _tagId))
    const addTagToPublisherInProgress = useSelector(getAddTagToPublisherInProgress)
    const addTagToPublisherError = useSelector(getAddTagToPublisherError)
    const addTagToPublisherSuccess = useSelector(getAddTagToPublisherSuccess)
    const hasThirdPartySalesChannels = useSelector(getHasThirdPartySalesChannels)
    const salesChannels = useSelector(getSalesChannels)
    const fetchTagsInProgress = useSelector(getFetchTagsInProgress)
    const fetchTagsError = useSelector(getFetchTagsError)
    const fetchTagsSuccess = useSelector(getFetchTagsSuccess)

    const _fetchTags = useCallback(() => {
        dispatch(fetchTags({ publisherId }))
    }, [
        dispatch,
        publisherId,
    ])

    return {
        fetchTags: _fetchTags,
        activeTagCount,
        selectByTagId: tagAdded,
        hasTagId: !!tagAdded,
        addTagToPublisherInProgress,
        addTagToPublisherError,
        addTagToPublisherSuccess,
        hasThirdPartySalesChannels,
        salesChannels,
        fetchTagsInProgress,
        fetchTagsError,
        fetchTagsSuccess,
    }
}

export default useTags
