import { createSlice } from '@reduxjs/toolkit'

import { name } from '../dependencies'

import page from './page'
import track from './track'
import identify from './identify'

const initialState = {
    eventType: null,
    eventPayload: {},
    eventError: null,
}

const analyticsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    // extraReducers: {
    //     [page.pending]: (state: any, { payload }) => {
    //         state.eventType = initialState.eventType
    //         state.eventPayload = initialState.eventPayload
    //     },
    //     [page.fulfilled]: (state: any, { payload }: any) => {
    //         state.eventType = payload.eventType
    //         state.eventPayload = payload.eventPayload
    //     },
    //     [page.rejected]: (state: any, { payload: error }: any) => {
    //         state.eventError = error
    //     },
    //     [track.pending]: (state: any, { payload }) => {
    //         state.eventType = initialState.eventType
    //         state.eventPayload = initialState.eventPayload
    //     },
    //     [track.fulfilled]: (state: any, { payload }: any) => {
    //         state.eventType = payload.eventType
    //         state.eventPayload = payload.eventPayload
    //     },
    //     [track.rejected]: (state: any, { payload: error }: any) => {
    //         state.eventError = error
    //     },
    //     [identify.pending]: (state: any, { payload }) => {
    //         state.eventType = initialState.eventType
    //         state.eventPayload = initialState.eventPayload
    //     },
    //     [identify.fulfilled]: (state: any, { payload }: any) => {
    //         state.eventType = payload.eventType
    //         state.eventPayload = payload.eventPayload
    //     },
    //     [identify.rejected]: (state: any, { payload: error }: any) => {
    //         state.eventError = error
    //     },
    // },
})

// GENERAL
const takes = [...page.takes, ...track.takes, ...identify.takes]

const AnalyticsInitialState = {
    [name]: initialState,
}

const AnalyticsReducer = {
    [name]: analyticsSlice.reducer,
}

const AnalyticsActions = {}

// EXPORTS
export default analyticsSlice.reducer

export {
    takes,
    AnalyticsInitialState as initialState,
    AnalyticsReducer as reducer,
    AnalyticsActions as actions,
    page,
    track,
    identify,
}
