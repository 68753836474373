import { createAsyncSaga, name } from '../dependencies'
import { call } from 'redux-saga/effects'
const track = createAsyncSaga(
    `${name}/track`,
    function* ({
        payload: { analytics, eventType, eventData = null } = {},
        fulfilled,
        rejected,
    }) {
        try {
            if (!eventType) {
                throw Error('eventType must be provided')
            }
            if (eventData.password) {
                delete eventData.password
            }
            yield call(analytics.track, eventType, eventData)
            yield fulfilled({ eventType, eventData })
        } catch (error) {
            yield rejected(error?.message)
            console.error(`${name}/track`, { error })
        }
    }
)

export default track
