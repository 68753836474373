import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getIsAuthenticated,
    authWithSocial,
    checkExistingEmail,
    emailCheckMessage,
    getEmailCheckInProgress,
    getIsEmailChecked,
    signupWithEmailPassword,
    checkPasswordStrength,
    getPasswordStrength,
    getPasswordStrengthInProgress,
    authWithEmailPassword,
    isEmailExists,
} from '@/entities/Authentication'
import { getPublisherId, usePublisherUser } from '@/entities/Publisher'
import { showConsoleLogs } from '@/utils/console'

const useAuthentication = () => {
    const dispatch = useDispatch()
    const publisherId = useSelector(getPublisherId)
    const publisherUser = usePublisherUser()
    const [emailEvaluated, setEmailEvaluated] = useState(null)
    const _isAuthenticated = useSelector(getIsAuthenticated)
    const _isEmailChecked = useSelector(getIsEmailChecked)
    const _emailCheckMessage = useSelector(emailCheckMessage)
    const _emailCheckInProgress = useSelector(getEmailCheckInProgress)
    const _passwordStrength = useSelector(getPasswordStrength)
    const _passwordStrengthInProgress = useSelector(getPasswordStrengthInProgress)
    const _isEmailExists = useSelector(isEmailExists)

    const doesEmailExist = useCallback((email) => {
        showConsoleLogs() && console.log('checking if email exists', email)
        setEmailEvaluated(email)
        dispatch(
            checkExistingEmail({
                email
            })
        )
    }, [dispatch])

    const runPasswordStrengthCheck = useCallback((password) => {
        showConsoleLogs() && console.log('checking if password is strong', password)
        dispatch(
            checkPasswordStrength({
                password
            })
        )
    }, [dispatch])

    const onEmailLogin = useCallback((payload) => {
        return dispatch(
            authWithEmailPassword({
                email: payload.email,
                password: payload.password,
                tagId: payload?.tagId,
            })
        )
    }, [dispatch])

    const onAuthenticate = useCallback((payload) => {
        showConsoleLogs() && console.log('onAuthenticate payload', payload)
        if (payload.method === 'email') {
            return dispatch(
                signupWithEmailPassword({
                    email: payload.email,
                    password: payload.password,
                    firstName: payload?.firstName,
                    tagId: payload?.tagId,
                })
            )

        } else if (payload.method === 'google' || payload.method === 'apple') {
            return dispatch(
                authWithSocial({
                    email: payload.email,
                    provider: payload.method,
                    first_name: payload?.first_name,
                    refresh_token: payload?.refresh_token,
                    token: payload?.token,
                    tag_id: payload?.tag_id,
                    publisher_id: payload?.publisher_id,
                    new_account: payload?.new_account,
                    is_a_god: payload?.is_a_god,
                })
            )
        }
    }, [dispatch])

    return {
        publisherId,
        publisherUser,
        doesEmailExist,
        runPasswordStrengthCheck,
        isEmailExists: _isEmailExists,
        onEmailLogin,
        onAuthenticate,
        emailEvaluated,
        emailCheckMessage: _emailCheckMessage,
        emailCheckInProgress: _emailCheckInProgress,
        isEmailChecked: _isEmailChecked,
        isAuthenticated: _isAuthenticated,
        passwordStrength: _passwordStrength,
        passwordStrengthInProgress: _passwordStrengthInProgress,
    }

}

export default useAuthentication
