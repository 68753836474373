import {
    takes,
    initialState,
    reducer,
    requestToSetKitOptionId,
    getSelectedKitOptionId,
    getSelectedKitOption,
    selectById,
    getIsKitOptionsSettingInProgress,
    setKitOptionIdSelected,
    selectAll, 
    setKits, 
    getHasKitOptionsBeenFetched, 
} from './slice'

import useKitOptions from './useKitOptions'

export default {
    initialState,
    reducer,
    takes
}

export {
    useKitOptions,
    requestToSetKitOptionId,
    getSelectedKitOptionId,
    getSelectedKitOption,
    selectById,
    getIsKitOptionsSettingInProgress,
    setKitOptionIdSelected,

    selectAll, 
    setKits, 
    getHasKitOptionsBeenFetched, 
    
}
