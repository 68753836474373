import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    oneTimeCodeError,
    oneTimeCodeInProgress,
    confirmOneTimeCodeInProgress,
    oneTimeCodeSent,
    requestOneTimeCode,
    oneTimeCodeConfirmed,
    verifyFlowId,
    confirmOneTimeCode,
    getEmail,
    actions
} from '@/entities/Authentication'

const useOneTimeCode = () => {
    const dispatch = useDispatch()
    const _oneTimeCodeError = useSelector(oneTimeCodeError)
    const _oneTimeCodeInProgress = useSelector(oneTimeCodeInProgress)
    const _confirmOneTimeCodeInProgress = useSelector(confirmOneTimeCodeInProgress)
    const _oneTimeCodeSent = useSelector(oneTimeCodeSent)
    const _verifyFlowId = useSelector(verifyFlowId)
    const _oneTimeCodeConfirmed = useSelector(oneTimeCodeConfirmed)
    const _email = useSelector(getEmail)

    const _requestOneTimeCode = useCallback(({ email = null, phone_number = null}: any) => {

        dispatch(requestOneTimeCode({
            email,
            phone_number,
            verifyFlowId: _verifyFlowId,
        }))
    }, [
        dispatch,
        _verifyFlowId,
    ])

    const _confirmOneTimeCode = useCallback(({ code = null }: any) => {
        dispatch(confirmOneTimeCode({
            code,
            email: _email,
        }))
    }, [
        dispatch,
        _email
    ])

    return {
        oneTimeCodeError: _oneTimeCodeError,
        oneTimeCodeSent: _oneTimeCodeSent,
        oneTimeCodeInProgress: _oneTimeCodeInProgress,
        confirmOneTimeCodeInProgress: _confirmOneTimeCodeInProgress,
        oneTimeCodeConfirmed: _oneTimeCodeConfirmed,
        email: _email,
        resetOTP: actions.resetOTP,
        requestOneTimeCode: _requestOneTimeCode,
        confirmOneTimeCode: _confirmOneTimeCode,
    }
}

export default useOneTimeCode
