import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFeatureFlagsEnabled, areFeatureFlagsFetched } from './dependencies'

import { exchangeClaimForToken, getUserId } from '@/entities/Authentication'
import { proceedToNextStep } from '@/utils/store/sagas'
import { 
    getSocialUsernames, 
    setClaimUsername, 
    getClaimUsername, 
    setClaimError, 
    resetClaim, 
    requestSocialUsernames, 
    getSocialFetchInProgress, 
    getSocialFetchError 
} from '@/features/ClaimUsernameCheck'
import { claimPageWithEmail, getClaimConfirmed, getClaimError, getClaimProgress } from './slice'

const useClaimUsername = () => {
    const dispatch = useDispatch()
    const userId = useSelector(getUserId)
    const claimUsername = useSelector(getClaimUsername)
    const socialUsernames = useSelector(getSocialUsernames)
    const featureFlagsFetched = useSelector(areFeatureFlagsFetched)
    const socialFetchInProgress = useSelector(getSocialFetchInProgress)
    const socialFetchError = useSelector(getSocialFetchError)
    const claimError = useSelector(getClaimError)
    const claimInprogress = useSelector(getClaimProgress)
    const claimConfirmed = useSelector(getClaimConfirmed)

    const showInstagram = useSelector(state => getFeatureFlagsEnabled(
        state,
        'claim-show-instagram'
    ))


    const initializeClaimAttempt = useCallback((payload) => {
        dispatch(setClaimUsername(payload))
    }, [dispatch])

    const makeRequestForSocialUsernames = useCallback((payload) => {
        dispatch(requestSocialUsernames(payload))
    }, [dispatch])

    const onClaimedAccountSuccess = useCallback((payload) => {
        dispatch(
            exchangeClaimForToken({
                claim: payload.claim,
            })
        )
        return dispatch(proceedToNextStep())
    }, [dispatch])


    const _claimPageWithEmail = useCallback((payload) => {
        dispatch(claimPageWithEmail(payload))
    }, [dispatch])

    const onClaimedAccountError = useCallback((payload) => {
        dispatch(setClaimError(payload.error))
        console.error('useClaimUsername onClaimedAccountError failure', payload)
    }, [dispatch])

    const clearClaimError = useCallback(() => dispatch(resetClaim()), [dispatch])

    return {
        claimUsername,
        clearClaimError,
        initializeClaimAttempt,
        getSocialUsernames,
        makeRequestForSocialUsernames,
        claimInprogress,
        claimError,
        claimConfirmed,
        onClaimedAccountError,
        claimPageWithEmail: _claimPageWithEmail,
        onClaimedAccountSuccess,
        socialUsernames,
        userId,
        showInstagram,
        featureFlagsFetched,
        socialFetchInProgress,
        socialFetchError
    }
}

export default useClaimUsername
