import { showConsoleLogs } from '@/utils/console'
import { useSelector } from 'react-redux'

import { publisherUser } from '@/entities/Publisher'

const usePublisherUser = () => {
    const _publisherUser = useSelector(publisherUser) || {}

    // if (fetchData) {
    //     dispatch(fetchData())
    // }
    showConsoleLogs() && console.log('inside of usePublisherUser', _publisherUser)

    return _publisherUser
}

export default usePublisherUser
