import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import SocialLoginButton from '@piconetworks/pkg-social-login-button'
import { composeValidators, fieldValidations } from '@/utils/form/validators'
import isEmpty from 'lodash/isEmpty'
import createAsyncSaga from '@/utils/store/createAsyncSaga'  
import * as endpoints from '@piconetworks/pkg-endpoints'
import { call, putResolve } from 'redux-saga/effects'
import { getFeatureFlagsEnabled, isFetched } from '@/entities/FeatureFlags'
import { getClaimFromQueryParams as queryParamClaim } from '@/entities/QueryParams'

import Button from '@/components/Button'

const name = 'claimUsernameCheck'

function maskEmail(email) {
    const [username, domain] = email.split('@');
    const maskedUsername = username.slice(0, 3) + '*'.repeat(username.length - 3);
    return `${maskedUsername}@${domain}`;
  }

export {
    PICO_API_URL,
    SocialLoginButton,
    composeValidators,
    fieldValidations,
    maskEmail,
    isEmpty,
    Button,
    name,
    call,
    putResolve,
    createAsyncSaga,
    endpoints,
    queryParamClaim,
    getFeatureFlagsEnabled,
    isFetched as areFeatureFlagsFetched,
}
