import {
    initialState,
    reducer,
    takes,
    actions,
    featureFlags,
    requestFeatureFlags,
    getFeatureFlagsEnabled,
    isFetched
} from './slice'

import useFeatureFlags from './useFeatureFlags'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    featureFlags,
    requestFeatureFlags,
    getFeatureFlagsEnabled,
    useFeatureFlags,
    isFetched
}
