import createAsyncSaga from '@/utils/store/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { getSelectedKitOptionId } from '../KitOptions'
import { getFlow } from '@/entities/Progress'

const name = 'scrapeyard'

export {
    name,
    createAsyncSaga,
    endpoints,
    getSelectedKitOptionId,
    getFlow
}