import {
    initialState,
    reducer,
    takes,
    getTrial,
    getTrialError,
    getTrialInProgress,
    getTrialInfo,
    getShowTrial,
    generateCheckoutSession,
    getGenerateCheckoutSessionInProgress,
    getGenerateCheckoutSessionError,
    getGenerateCheckoutSessionSuccess,
} from './slice'
import useTrial from './useTrial'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useTrial,
    getTrial,
    getTrialError,
    getTrialInProgress,
    getTrialInfo,
    getShowTrial,
    generateCheckoutSession,
    getGenerateCheckoutSessionInProgress,
    getGenerateCheckoutSessionError,
    getGenerateCheckoutSessionSuccess,
}
