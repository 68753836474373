
import { createAsyncSaga, name, endpoints } from '../dependencies'

const fetchTiktokstatus = createAsyncSaga(
    `${name}/tiktokStatus`,
    function* ({
        payload: {
            tiktok = null,
            publisherId = null,
            scrape_id = null,
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {

        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `/scrapeyard/status/${scrape_id}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    social_handle: tiktok,
                    social_platform: 'tiktok',
                }
            })

            yield fulfilled(response)
            

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default fetchTiktokstatus

