import { put, call, take } from 'redux-saga/effects'
import { createAsyncSaga, name } from '../dependencies'
import { generateClaim } from '@/entities/Authentication'

const iosLogin = createAsyncSaga(
    `${name}/iosLogin`,
    function* ({
        payload: {
            publisherId = null,
            token,
            isGod = false,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            console.log('iosLogin')
            yield put(yield call(generateClaim, {
                publisherId,
                token,
            }))

            yield take(generateClaim.pending)
            const claimResult = yield take([generateClaim.fulfilled, generateClaim.rejected])

            const claim = claimResult?.payload?.claim

            if (claimResult.type === generateClaim.rejected || !claim) {
                throw new Error('Claim rejected')
            }

            // these aren't necessary because they should not reach the last step
            // yield putResolve(setRedirectBase('hype://'))
            // yield putResolve(setRedirectPath(`callback?claim=${claim}&`))

            yield fulfilled({
                redirectUrl: `hype://callback?claim=${claim}`,
            })
        } catch (error) {
            console.log('iosLogin error', error)
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default iosLogin
