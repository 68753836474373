import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '@/utils/store/sagas'
import networkMiddleware from '@/utils/store/middleware/networkMiddleware'
import rootReducer from './rootReducer'

const SagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: rootReducer,
    // devTools: {
    //     actionCreators: {
    //         ...progressActions,
    //     }
    // },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            thunk: false,
            serializableCheck: false,
        }).prepend(
            SagaMiddleware,
            networkMiddleware,
        )
    },
})

SagaMiddleware.run(rootSaga)
