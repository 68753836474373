import { createAsyncSaga, name, endpoints } from '../dependencies'

// we could end up reusing the updatePublisher saga instead, but keeping this separate because of tag id updates might be needed separately

const requestToSetKitOptionId = createAsyncSaga(
    `${name}/requestToSetKitOptionId`,
    function* ({
        payload: {
            kitId = null,
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw Error('publisherId must be provided')
            }
            
            const response = yield request({
                method: 'put',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.PUBLISHER()}`,
                data: {
                    hype_kit_ref: kitId,
                },
                headers: {
                    publisherid: publisherId
                }
            })

            yield fulfilled({
                ...response,
                kitId,
                calledFrom: 'requestToSetKitOptionId'
            })
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default requestToSetKitOptionId

