import sortBy from 'lodash/sortBy'
import createAsyncSaga from '@/utils/store/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { getTagId } from '@/entities/QueryParams'
import { getPublisherId, updatePublisher, } from '@/entities/Publisher'

const name = 'tags'

export {
    name,
    endpoints,
    createAsyncSaga,
    getTagId,
    getPublisherId,
    updatePublisher,
    sortBy,
}
