import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSourceFormIdFromStepOne, requestKitIdFromSourceForm, setSourceFormIdFromStepOne } from '@/entities/Remix'
import { getPublisher } from './dependencies'

const useRemix = () => {
    const dispatch = useDispatch()
    const sourceFormId = useSelector(getSourceFormIdFromStepOne)
    const publisher = useSelector(getPublisher)

    const initializeRemix = useCallback(({ sourceFormId, currentStep }) => {
        dispatch(setSourceFormIdFromStepOne({
            sourceFormId
        }))
        dispatch(
            requestKitIdFromSourceForm({
                sourceFormId,
                publisherId: publisher?.id
            })
        )
    }, [dispatch, publisher?.id])

    return {
        initializeRemix,
        sourceFormId
    }
}

export default useRemix
