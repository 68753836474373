import { select } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import { getSelectedPublisher } from '@/entities/Publisher'

const checkUsername = createAsyncSaga(
    `${name}/checkUsername`,
    function* ({
        payload: {
            username = null,
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!username) {
                throw new Error('username not provided')
            }
            if (!publisherId) {
                throw new Error('publisher not provided')
            }

            const selectedPublisher = yield select(getSelectedPublisher)

            if (selectedPublisher?.username === username) {
                yield fulfilled({ username })
            } else {
                yield request({
                    method: 'get',
                    baseURL: endpoints.PICO_API_URL(),
                    url: `${endpoints.CLIENT()}query/${username}`,
                    headers: {
                        publisherid: publisherId
                    },
                    timeout: 5000,
                })

                throw new Error('Username is already taken')
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                yield fulfilled({ username })
            } else {
                yield rejected({
                    username,
                    error: error?.message || 'Could not check username'
                })
            }

        }
    }
)



export default checkUsername
