import qs from 'qs'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import { call } from 'redux-saga/effects'

const setupPublisher = createAsyncSaga(
    `${name}/setupPublisher`,
    function* ({
        payload,
        fulfilled,
        rejected,
        request,
    }) {
        const { utmParams, ...rest } = payload

        const utmString = yield call(qs.stringify, utmParams, { skipNulls: true })

        try {
            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `/setup/creator?${utmString}`,
                data: {
                    ...rest,
                }
            })

            yield fulfilled({
                ...response,
                calledFrom: 'setupPublisher'
            })
        } catch (error) {
            yield rejected(error.message)
        }
    }
)

export default setupPublisher
