import { showConsoleLogs } from '@/utils/console'
import { useSelector } from 'react-redux'

import { 
    getIsKitOptionsSettingInProgress,
} from './dependencies'

import { 
    getSetupPublisherInProgress,
    getUsernameCheckInProgress,
    getPublisherUpdateInProgress,
    getMeInProgress,
} from '@/entities/Publisher'

import { 
    getEmailAuthInProgress, 
    getEmailCheckInProgress,
    getSocialAuthInProgress,
    getEmailSignupInProgress,
} from '@/entities/Authentication'

const useProcessing = () => {
    const _emailCheckInProgress = useSelector(getEmailCheckInProgress)
    const _emailAuthInProgress = useSelector(getEmailAuthInProgress)
    const _setupPublisherInProgress = useSelector(getSetupPublisherInProgress)
    const _emailSignupInProgress = useSelector(getEmailSignupInProgress)
    const _socialAuthInProgress = useSelector(getSocialAuthInProgress)
    const _usernameCheckInProgress = useSelector(getUsernameCheckInProgress)
    const _publisherUpdateInProgress = useSelector(getPublisherUpdateInProgress)
    const _isKitOptionsSettingInProgress = useSelector(getIsKitOptionsSettingInProgress)
    const _getMeInProgress = useSelector(getMeInProgress)

    if (showConsoleLogs()) {
        console.groupCollapsed('useProcessing')
        console.log('emailCheckInProgress', _emailCheckInProgress)
        console.log('emailAuthInProgress', _emailAuthInProgress)
        console.log('setupPublisherInProgress', _setupPublisherInProgress)
        console.log('emailSignupInProgress', _emailSignupInProgress)
        console.log('socialAuthInProgress', _socialAuthInProgress)
        console.log('usernameCheckInProgress', _usernameCheckInProgress)
        console.log('publisherUpdateInProgress', _publisherUpdateInProgress)
        console.log('isKitOptionsSettingInProgress', _isKitOptionsSettingInProgress)
        console.log('getMeInProgress', _getMeInProgress)
        console.groupEnd()
    }
    
    return {
        emailCheckInProgress: _emailCheckInProgress,
        emailAuthInProgress: _emailAuthInProgress,
        setupPublisherInProgress: _setupPublisherInProgress,
        emailSignupInProgress: _emailSignupInProgress,
        socialAuthInProgress: _socialAuthInProgress,
        usernameCheckInProgress: _usernameCheckInProgress,
        publisherUpdateInProgress: _publisherUpdateInProgress,
        isKitOptionsSettingInProgress: _isKitOptionsSettingInProgress,
        getMeInProgress: _getMeInProgress
    }
}

export default useProcessing
