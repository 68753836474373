export const showConsoleLogs = (specifier = null) => {
    if (typeof window !== 'undefined') {
        if (window?.localStorage && window.localStorage.getItem(specifier) === 'true') {
            return true
        } else if (window?.localStorage && window.localStorage.getItem('DEBUG') === 'true') {
            return true
        }
    }
    return false
}

export const stopRedirect = () => {
    if (typeof window !== 'undefined') {
        if (window?.localStorage && window.localStorage.getItem('stop') === 'true') {
            return true
        }
    }
    return false
}
