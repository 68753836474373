import { useCallback } from 'react'
import { showConsoleLogs } from '@/utils/console'
import { useSelector, useDispatch } from 'react-redux'

import { 
    getIsUserWithoutPublisherUser, 
    getPublications, 
    getPublicationsHaveBeenFetched, 
    getPublisherId, 
    getSelectedPublisher, 
    getSwitchToPublisherCompleted, 
    switchToPublisher,
    getBillingInProgress,
    getBillingError,
    getBillingInfo,
    getBillingInfoStatuses, 
} from '@/entities/Publisher'
import { getIsNewUser } from './dependencies'
import { SET_SCOPE } from '@/utils/store/middleware/networkMiddleware'

const usePublisher = (fetchData = false) => {
    const _publisherId = useSelector(getPublisherId)
    const selectedPublisher = useSelector(getSelectedPublisher)
    const _publications = useSelector(getPublications) || []
    const _publicationsHaveBeenFetched = useSelector(getPublicationsHaveBeenFetched) === true
    const _isNewUser = useSelector(getIsNewUser)
    const dispatch = useDispatch()
    const hasUserButNoPublisherUser = useSelector(getIsUserWithoutPublisherUser)
    const switchToPublisherCompleted = useSelector(getSwitchToPublisherCompleted)
    const billingInProgress = useSelector(getBillingInProgress)
    const billingError = useSelector(getBillingError)
    const billingInfo = useSelector(getBillingInfo)
    const billingInfoStatuses = useSelector(getBillingInfoStatuses)
    showConsoleLogs() && console.log('inside of usePublisher', _publisherId)

    const requestToSwitchToPublisher = useCallback((payload: any) => {
        if (!switchToPublisherCompleted) {
            dispatch(switchToPublisher(payload))
        }
    }, [dispatch, switchToPublisherCompleted])

    const requestToSetScopeAndCallSetup = useCallback(() => {
        dispatch({
            type: SET_SCOPE
        })
    }, [dispatch])

    return {
        publisherId: _publisherId,
        publications: _publications,
        requestToSwitchToPublisher,
        requestToSetScopeAndCallSetup,
        publicationsHaveBeenFetched: _publicationsHaveBeenFetched,
        isNewUser: _isNewUser,
        selectedPublisher,
        hasUserButNoPublisherUser,
        billingInProgress,
        billingError,
        billingInfo,
        billingInfoStatuses,
    }
}

export default usePublisher
