
import { createAsyncSaga, name, endpoints } from '../dependencies'

const checkPasswordStrength = createAsyncSaga(
    `${name}/checkPasswordStrength`,
    function* ({
        payload: {
            password = null
    } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!password) {
                throw new Error('Password not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: endpoints.SETUP_PASSWORD_CHECK(),
                data: {
                    password
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default checkPasswordStrength
