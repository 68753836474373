import {
    initialState,
    reducer,
    takes,
    authWithEmailPassword,
    signupWithEmailPassword,
    getIsAuthenticated,
    actions,
    authWithSocial,
    getIsNewUser,
    getRefreshTokenInProgress,
    getSignupDetails,
    getEmailAuthError,
    getEmailSignupError,
    getSocialAuthError,
    getScopeIsSet,
    getSocialAuthInProgress,
    getEmailAuthInProgress,
    getEmailSignupInProgress,
    getEmailCheckInProgress,
    oneTimeCodeError,
    oneTimeCodeInProgress,
    oneTimeCodeSent,
    requestOneTimeCode,
    oneTimeCodeConfirmed,
    verifyFlowId,
    confirmOneTimeCode,
    confirmOneTimeCodeInProgress,
    getEmail,
    exchangeClaimForToken,
    exchangeClaimForTokenError,
    exchangeClaimForTokenInProgress,
    getRefreshTokens,
    setUserIdFromDecodedJwt,
    getUserId,
    getPublisherIdFromAuthentication,
    getName,
    generateClaim,
    handleStoreTokens,
    deauthorize,
    checkExistingEmail,
    emailCheckMessage,
    getIsEmailChecked,
    checkPasswordStrength,
    getPasswordStrength,
    getPasswordStrengthInProgress,
    isEmailExists,
    iosLogin,
    godLogin,
    whatToDoAfterStoringTokens,
    setPublisherIdFromAuthenticationOnClaimTokenExchange,
    getOutboundClaim,
    getGenerateClaimError,
    getGenerateClaimInProgress,
} from './slice'

import useSignupDetails from './useSignupDetails'
import useAuthentication from './useAuthentication'
import useOneTimeCode from './useOneTimeCode'
import useClaim from './useClaim'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    authWithEmailPassword,
    authWithSocial,
    signupWithEmailPassword,
    handleStoreTokens,
    deauthorize,
    getRefreshTokenInProgress,
    getIsNewUser,
    getIsAuthenticated,
    useSignupDetails,
    useAuthentication,
    useOneTimeCode,
    useClaim,
    getSignupDetails,
    getScopeIsSet,
    getEmailAuthError,
    getEmailSignupError,
    getSocialAuthError,
    getSocialAuthInProgress,
    getEmailAuthInProgress,
    getEmailSignupInProgress,
    getEmailCheckInProgress,

    oneTimeCodeError,
    oneTimeCodeInProgress,
    oneTimeCodeSent,
    requestOneTimeCode,
    oneTimeCodeConfirmed,
    verifyFlowId,
    confirmOneTimeCode,
    confirmOneTimeCodeInProgress,
    getEmail,
    actions,
    exchangeClaimForToken,
    exchangeClaimForTokenError,
    exchangeClaimForTokenInProgress,
    getRefreshTokens,
    setUserIdFromDecodedJwt,
    getUserId,
    getPublisherIdFromAuthentication,
    getName,
    generateClaim,
    isEmailExists,
    checkExistingEmail,
    emailCheckMessage,
    getIsEmailChecked,
    checkPasswordStrength,
    getPasswordStrength,
    getPasswordStrengthInProgress,
    iosLogin,
    godLogin,
    whatToDoAfterStoringTokens,
    setPublisherIdFromAuthenticationOnClaimTokenExchange,
    getOutboundClaim,
    getGenerateClaimError,
    getGenerateClaimInProgress,
}
