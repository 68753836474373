import { put, call, take, select } from 'redux-saga/effects'
import { createAsyncSaga, getInApp, name } from '../dependencies'
import { generateClaim } from '@/entities/Authentication'

const godLogin = createAsyncSaga(
    `${name}/godLogin`,
    function* ({
        payload: {
            publisherId = null,
            token,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            console.log('godLogin')
            const inApp = yield select(getInApp)
            yield put(yield call(generateClaim, {
                publisherId,
                token,
            }))

            yield take(generateClaim.pending)
            const claimResult = yield take([generateClaim.fulfilled, generateClaim.rejected])

            const claim = claimResult?.payload?.claim

            if (claimResult.type === generateClaim.rejected || !claim) {
                throw new Error('Claim rejected')
            }
            if (inApp) {
                const redirectUrl = `hype://callback?claim=${claim}`
                window.location.href=redirectUrl
            } else {
                const redirectUrl = `${process.env.DASHBOARD_URL}?_claim=${claim}`
                window.location.href = redirectUrl
            }

            console.log('claimResult', claim)

            yield fulfilled({})
        } catch (error) {
            console.log('godLogin error', error)
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default godLogin
