import React, { useCallback, useState } from 'react'
import { useClaimUsername } from '@/features/ClaimUsernameCheck'
import { useProgress } from '@/entities/Progress'
import InstagramLogin from './Instagram'
import TikTokLogin from './TikTok'
import { useFeatureFlags } from '@/entities/FeatureFlags'
import Loading from '@/components/Loading'
import Email from './Email'
import FacebookLogin from './Facebook'

const ClaimCheck = () => {
    const { claimUsername, clearClaimError, onClaimedAccountError, onClaimedAccountSuccess, socialUsernames, userId, showInstagram, featureFlagsFetched, socialFetchInProgress, socialFetchError } = useClaimUsername()
    const [processing, setProcessing] = useState(false)
    const { goToNextStep } = useProgress()

    // we shouldn't have to call this here, but because it requires the user id in order to get the feature flags, we have to call it here instead of in Layout
    useFeatureFlags(true)

    const handleTikTokSuccess = useCallback(
        ({ claim, ...rest }) => {
            console.log('tiktok login success', claim, rest)
            setProcessing(false)
            onClaimedAccountSuccess({ type: 'tiktok', claim })
            return goToNextStep('handleTikTokSuccess')
        },
        [goToNextStep, onClaimedAccountSuccess]
    )

    const handleTikTokFailure = useCallback(
        (error) => {
            setProcessing(false)
            onClaimedAccountError(error)
        },
        [onClaimedAccountError]
    )

    const handleInstagramSuccess = useCallback(
        ({ claim, ...rest }) => {
            console.log('instagram login success', claim, rest)
            setProcessing(false)
            onClaimedAccountSuccess({ type: 'instagram', claim })
            return goToNextStep('handleInstagramSuccess')
        },
        [goToNextStep, onClaimedAccountSuccess]
    )

    const handleInstagramFailure = useCallback(
        (error) => {
            setProcessing(false)
            onClaimedAccountError(error)
        },
        [onClaimedAccountError]
    )

    const handleButtonClick = () => {
        clearClaimError()
        setProcessing(true)
    }

    const onlyInstagramAndFacebook = !socialUsernames.tiktok &&
        ((!!socialUsernames.instagram || !!socialUsernames.facebook) &&
            !socialUsernames.email &&
            !(!!socialUsernames.instagram && !!socialUsernames.facebook && !!socialUsernames.email))

    const noSocialUsernames = !socialUsernames.instagram && !socialUsernames.facebook && !socialUsernames.tiktok && !socialUsernames.email;

    return (
        <>
            <div className="max-w-[375px] mx-auto">
                {socialFetchInProgress ?
                    <Loading title='Loading' /> :
                    <>
                        {onlyInstagramAndFacebook || noSocialUsernames ? (
                            <div>
                                <p className='mt-10 text-center w-100'>
                                    This Hype Site cannot be claimed at this time. Please contact <a href="mailto:support@hype.co">support@hype.co</a>.
                                </p>
                            </div>
                        ) : (
                            <>
                                {!!socialUsernames.email && (
                                    <Email />
                                )}
                                {/* {!!socialUsernames.facebook && (
                                    <FacebookLogin
                                        hypeUsername={claimUsername}
                                        socialUsername={socialUsernames.facebook}
                                        userId={userId}
                                        onLoginSuccess={handleInstagramSuccess}
                                        onLoginFailure={handleInstagramFailure}
                                        onButtonClick={handleButtonClick}
                                        processing={processing}
                                    />
                                )}
                                {showInstagram && !!socialUsernames.instagram && (
                                    <InstagramLogin
                                        hypeUsername={claimUsername}
                                        socialUsername={socialUsernames.instagram}
                                        userId={userId}
                                        onLoginSuccess={handleInstagramSuccess}
                                        onLoginFailure={handleInstagramFailure}
                                        onButtonClick={handleButtonClick}
                                        processing={processing}
                                    />
                                )} */}
                                {!!socialUsernames.tiktok && (
                                    <TikTokLogin
                                        hypeUsername={claimUsername}
                                        socialUsername={socialUsernames.tiktok}
                                        userId={userId}
                                        onLoginSuccess={handleTikTokSuccess}
                                        onLoginFailure={handleTikTokFailure}
                                        onButtonClick={handleButtonClick}
                                        processing={processing}
                                    />
                                )}
                            </>
                        )}
                    </>
                }
            </div>
        </>
    )
}
export default ClaimCheck