import { createAsyncSaga, name, endpoints } from '../dependencies'

const addTagToPublisher = createAsyncSaga(
    `${name}/addTagToPublisher`,
    function* ({
        payload,
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!payload.publisher_id) {
                throw new Error('publisher not provided')
            }
            if (!payload.tag_id) {
                throw new Error('tag not provided')
            }
            
            const response = yield request({
                method: 'put',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.PUBLISHER()}`,
                headers: {
                    publisherid: payload.publisher_id
                },
                data: {
                    tag_id: payload.tag_id
                }
            })

            yield fulfilled({
                ...response,
                calledFrom: 'addTagToPublisher'
            })
        } catch (error) {
            yield rejected(error?.response?.data?.message || error?.message || error)

        }
    }
)



export default addTagToPublisher
