import {
    initialState,
    reducer,
    actions,
    takes,
    getRemix,
    setSourceFormIdFromStepOne,
    getSourceFormIdFromStepOne,
    requestKitIdFromSourceForm,
} from './slice'

import useRemix from './useRemix'

export default {
    initialState,
    reducer,
    actions,
    takes
}

export {
    useRemix,
    getRemix,
    actions,
    setSourceFormIdFromStepOne,
    getSourceFormIdFromStepOne,
    requestKitIdFromSourceForm
}
