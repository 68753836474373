import { createAsyncSaga, name, endpoints } from '../dependencies'

const requestSocialUsernames = createAsyncSaga(
    `${name}/requestSocialUsernames`,
    function* ({
        payload: {
            username = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!username) {
                throw Error('username must be provided')
            }
            
            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `client/query/${username}?short=false`,
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default requestSocialUsernames

