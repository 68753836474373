import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { addSocialLinks } from '@/entities/Publisher'

const useSocials = (fetchData = false) => {
    const dispatch = useDispatch()

    const requestSocials = useCallback((payload) => {
        dispatch(addSocialLinks(payload))
    }, [dispatch])

    return {
        requestSocials
    }
}

export default useSocials
