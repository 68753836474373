import {
    all,
    call,
    put,
    putResolve,
    select,
    take,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects'
import { SET_SCOPE, } from '@/utils/store/middleware/networkMiddleware'
import Authentication, {
    authWithEmailPassword,
    authWithSocial,
    signupWithEmailPassword,
    exchangeClaimForToken,
    whatToDoAfterStoringTokens,
    confirmOneTimeCode,
    getPublisherIdFromAuthentication,
    getIsNewUser,
    getScopeIsSet,
    handleStoreTokens,
    iosLogin,
} from '@/entities/Authentication'
import ClaimUsernameCheck from '@/features/ClaimUsernameCheck'
import Progress, {
    getCurrentStep,
    getCurrentStepIsCompleted,
    getFlow,
    transitionToNextStep,
    getContinueFlowAfterLogin,
    goToLastStep,
    goToSecondLastStep,
} from '@/entities/Progress'
import Scrapeyard, {
    scrapeInstagram,
    scrapeTiktok,
    scrapeBeacons,
    pollBeaconsStatus,
    pollInstagramStatus,
    pollTiktokStatus,
} from '@/entities/Scrapeyard'
import Publisher, {
    setupPublisher,
    getSetupPublisherComplete,
    getSetupPublisherInProgress,
    updatePublisher,
    addSocialLinks,
    getMe,
    getPublicationsHaveBeenFetched,
    getPublisherId,
    getIsUserWithoutPublisherUser,
    getPublisherUpdateInProgress,
    getPublisherUpdateError,
    switchToPublisher,
    getSwitchToPublisherCompleted
} from '@/entities/Publisher'
import KitOptions, {
    setKitOptionIdSelected,
} from '@/entities/KitOptions'
import Analytics from '@/entities/Analytics'
import { showConsoleLogs } from '../console'
import Remix, { getSourceFormIdFromStepOne, requestKitIdFromSourceForm } from '@/entities/Remix'
import FeatureFlags from '@/entities/FeatureFlags'
import {
    getTagId,
    getUtmSource,
    getUtmMedium,
    getUtmCampaign,
    getUtmTerm,
    getUtmContent,
    getClaimFromQueryParams,
    getInApp
} from '@/entities/QueryParams'
import Tags, {
    addTagToPublisher,
    fetchTags,
} from '@/entities/Tags'
import Purchases, { getShowTrial, getTrialInfo } from '@/entities/Purchases'
import { claimPageWithEmail } from '@/features/ClaimUsernameCheck/slice'

// checks to see if the step is not complete and if it is not, it transitions to the next step (and completes that current step)
export function* proceedToNextStep(response: any): any {
    const isCompleted = yield select(getCurrentStepIsCompleted)
    const currentStep = yield select(getCurrentStep)

    if (showConsoleLogs('flow')) {
        console.group('proceedToNextStep')
        console.log('response', response)
        console.log('isCompleted', isCompleted)
        console.log('currentStep', currentStep)
        console.groupEnd()
    }

    if (!isCompleted) {
        yield putResolve(transitionToNextStep({
            calledFrom: response.calledFrom,
        }))
    }
}

// happens when you want to call setup after authenticating with social or email 
function* setScope(response: any): any {
    const scopeIsSet = yield select(getScopeIsSet)

    console.group('setScope')
    console.log('response', response)
    console.log('scopeIsSet', scopeIsSet)
    console.groupEnd()

    if (showConsoleLogs('flow')) {
        console.group('setScope')
        console.log('scopeIsSet', scopeIsSet)
        console.groupEnd()
    }

    if (!scopeIsSet) {
        yield putResolve({
            type: SET_SCOPE,
            scope: 'setup'
        })
    }
}

// happens after exchanging the claim for a token
// happens after logging in with social or email
// happens after signing up with social or email
// happens after setup is complete
function* storeTokens({
    payload,
    type
}: { payload: any, type: string }): any {
    yield put(yield call(handleStoreTokens, payload))
}


// this needs clean up
function* checkIfWeNeedToCallSetup(response: any): any {
    const isNewUser = yield select(getIsNewUser)
    const isNewPublisherUser = yield select(getIsUserWithoutPublisherUser)
    const flow = yield select(getFlow)
    const setupPublisherInProgress = yield select(getSetupPublisherInProgress)
    const setupPublisherComplete = yield select(getSetupPublisherComplete)

    if (showConsoleLogs('flow')) {
        console.group('checkIfWeNeedToCallSetup')
        console.log('response', response)
        console.log('isNewUser', isNewUser)
        console.log('isNewPublisherUser', isNewPublisherUser)
        console.log('flow', flow)
        console.log('setupPublisherInProgress', setupPublisherInProgress)
        console.log('setupPublisherComplete', setupPublisherComplete)
        console.groupEnd()
    }

    if (isNewPublisherUser || isNewUser) {
        if (!setupPublisherInProgress && !setupPublisherComplete) {
            if (flow !== 'claim' && flow !== 'remix') {
                yield call(proceedToNextStep, {
                    ...response,
                    calledFrom: 'checkIfWeNeedToCallSetup'
                })
            } else if (flow === 'remix') {
                yield call(setScope, {
                    ...response,
                    calledFrom: 'checkIfWeNeedToCallSetup'
                })
            }
        }
    }
}

function* afterUpdatingPublisher(response: any): any {
    const hasSwitchedPublishersAlready = yield select(getSwitchToPublisherCompleted)
    const publisherUpdateInProgress = yield select(getPublisherUpdateInProgress)
    const publisherUpdateError = yield select(getPublisherUpdateError)
    const isNewUser = yield select(getIsNewUser)
    const isNewPublisherUser = yield select(getIsUserWithoutPublisherUser)
    const isSetupComplete = yield select(getSetupPublisherComplete)
    const shouldShowTrial = yield select(getShowTrial)
    const inApp = yield select(getInApp)

    if (showConsoleLogs()) {
        console.group('afterUpdatingPublisher')
        console.log('response', response)
        console.log('isNewUser', isNewUser)
        console.log('isNewPublisherUser', isNewPublisherUser)
        console.log('hasSwitchedPublishersAlready', hasSwitchedPublishersAlready)
        console.log('publisherUpdateInProgress', publisherUpdateInProgress)
        console.log('publisherUpdateError', publisherUpdateError)
        console.log('isSetupComplete', isSetupComplete)
        console.groupEnd()
    }

    if (!publisherUpdateInProgress) {
        if (hasSwitchedPublishersAlready === true) {
            // they just want to log in as their publisher
            showConsoleLogs() && console.log('redirect To App because existing user logging in as publisher')
            if (shouldShowTrial && !inApp) {
                // show them the subscription wall
                yield putResolve(goToSecondLastStep({
                    why: 'redirect to second last step because we should show them the subscription wall'
                }))
            } else {
                // show them the download wall
                yield putResolve(goToLastStep({
                    why: 'redirect to last step because existing publisher user is logging in as publisher'
                }))
            }
        } else if (isNewUser || isNewPublisherUser) {
            yield call(proceedToNextStep, {
                ...response,
                calledFrom: 'afterUpdatingPublisher'
            })
        } else if (isSetupComplete) {
            yield call(proceedToNextStep, {
                ...response,
                calledFrom: 'afterUpdatingPublisher'
            })
        }
    }
}

function* callMeMaybe(response: any): any {
    const publisherIdFromAuthentication = yield select(getPublisherIdFromAuthentication)
    const hasCalledMeAlready = yield select(getPublicationsHaveBeenFetched)
    const hasUserButNoPublisherUser = yield select(getIsUserWithoutPublisherUser)
    const flow = yield select(getFlow)
    const claimFromQueryParams = yield select(getClaimFromQueryParams)

    console.group('callMeMaybe')
    console.log('response', response)
    console.log('publisherIdFromAuthentication', publisherIdFromAuthentication)
    console.log('hasCalledMeAlready', hasCalledMeAlready)
    console.log('flow', flow)
    console.log('hasUserButNoPublisherUser', hasUserButNoPublisherUser)
    console.groupEnd()

    if (!hasUserButNoPublisherUser && !hasCalledMeAlready && flow !== 'claim' && claimFromQueryParams === null) {
        // we should get the list of publications they have in order 
        // to determine if they want to use an existing publisher or create a new one
        yield putResolve(getMe())
    }
}

function* checkIfFlowIsRemix(response: any): any {
    const flow = yield select(getFlow)
    // If they already have a publisher they've selected, we don't need to call getMe again
    const hasSwitchedPublishersAlready = yield select(getSwitchToPublisherCompleted)
    const isNewUser = yield select(getIsNewUser)
    const isNewPublisherUser = yield select(getIsUserWithoutPublisherUser)
    const _currentStep = yield select(getCurrentStep)
    const publisherUpdateInProgress = yield select(getPublisherUpdateInProgress)
    if (showConsoleLogs()) {
        console.group('checkIfFlowIsRemix')
        console.log('response', response)
        console.log('hasSwitchedPublishersAlready', hasSwitchedPublishersAlready)
        console.log('isNewUser', isNewUser)
        console.log('isNewPublisherUser', isNewPublisherUser)
        console.log('_currentStep', _currentStep)
        console.log('publisherUpdateInProgress', publisherUpdateInProgress)
        console.log('flow', flow)
        console.groupEnd()
    }

    if (flow === 'remix' && !hasSwitchedPublishersAlready) {
        if (!isNewUser && !isNewPublisherUser) {
            showConsoleLogs() && console.log('redirect To App because existing user is attempting to remix')
            yield putResolve(goToLastStep({
                why: 'redirect to last step because existing publisher user is attempting to remix'
            }))
        } else if ((isNewUser || isNewPublisherUser) && _currentStep === 1) {
            yield call(proceedToNextStep, {
                ...response,
                calledFrom: 'checkIfFlowIsRemix'
            })
        }
    }
}

function* checkIfFlowIsClaim(response: any): any {
    const flow = yield select(getFlow)
    const _currentStep = yield select(getCurrentStep)
    if (showConsoleLogs()) {
        console.group('checkIfFlowIsClaim')
        console.log('response', response)
        console.log('flow', flow)
        console.log('_currentStep', _currentStep)
        console.groupEnd()
    }

    if (flow === 'claim' && _currentStep === 1) {
        yield call(proceedToNextStep, {
            ...response,
            calledFrom: 'checkIfFlowIsClaim'
        })
    }
}

// because they are an existing user and they are attempting to link a tag to their existing publisher,
// we redirect them to the tags page of dashboard
// or we just log them in as their publisher in afterUpdatingPublisher
function* existingPublisher(response: any): any {
    const _tagId = yield select(getTagId)
    const _publisherId = yield select(getPublisherId)
    const flow = yield select(getFlow)
    // switchToPublisher has completed (switch)
    const hasSwitchedPublishersAlready = yield select(getSwitchToPublisherCompleted)
    const claimFromQueryParams = yield select(getClaimFromQueryParams)
    if (showConsoleLogs()) {
        console.group('existingPublisher')
        console.log('from store', '_publisherId', _publisherId, '_tagId', _tagId)
        console.log('hasSwitchedPublishersAlready', hasSwitchedPublishersAlready)
        console.groupEnd()
    }
    // if they are new users or new publisherusers, we update them when they go to set a username
    if (_publisherId && hasSwitchedPublishersAlready && flow !== 'claim' && claimFromQueryParams === null) {

        // does this need to be here?
        // log them in as their publisher
        yield call(afterUpdatingPublisher, {
            ...response,
            calledFrom: 'existingPublisher'
        })
    }
}

function* addTagToPublisherMaybe(response: any): any {
    const _tagId = yield select(getTagId)
    const _publisherId = yield select(getPublisherId)
    if (_tagId) {
        yield putResolve(addTagToPublisher({
            publisher_id: _publisherId,
            tag_id: _tagId
        }))
    } else {
        yield putResolve({
            type: 'NO_TAG_ID'
        })
    }
}

function* fetchTagsAndSeeIfPublisherIsEligibleForTrial(response: any): any {
    const publisherId = yield select(getPublisherId)
    yield put(yield call(fetchTags, {
        publisherId,
    }))

    yield take(fetchTags.pending)
    yield take([fetchTags.fulfilled, fetchTags.rejected])

    yield putResolve(getTrialInfo({
        publisherId
    }))
}

function* nextStepAfterSetupPublisher(response: any): any {
    const _currentStep = yield select(getCurrentStep)
    const isNewUser = yield select(getIsNewUser)
    const isUserWithoutPublisherUser = yield select(getIsUserWithoutPublisherUser)
    const flow = yield select(getFlow)

    if (showConsoleLogs('flow')) {
        console.group('nextStepAfterSetupPublisher')
        console.log('going to next step after setup publisher')
        console.log('response', response)
        console.log('_currentStep', _currentStep)
        console.log('isNewUser', isNewUser)
        console.log('isUserWithoutPublisherUser', isUserWithoutPublisherUser)
        console.groupEnd()
    }

    // yield call(proceedToNextStep, {
    //     ...response,
    //     calledFrom: 'nextStepAfterSetupPublisher'
    // })

    // we want to skip the publisher selection step if they are a new user
    // but not if they are remixing, because the remix container doesn't have the publisher selection step
    if (flow !== 'remix') {
        yield call(proceedToNextStep, {
            ...response,
            calledFrom: 'nextStepAfterSetupPublisher'
        })
        // if ((isNewUser || isUserWithoutPublisherUser) && _currentStep === '2') {
        //     yield call(proceedToNextStep, {
        //         ...response,
        //         calledFrom: 'nextStepAfterSetupPublisher isNewUser'
        //     })
        // }
    }
}

function* nextStepAfterAddingSocialLinks(response: any): any {
    yield call(proceedToNextStep, {
        ...response,
        calledFrom: 'nextStepAfterAddingSocialLinks'
    })
}

function* whatToDoAfterGettingMe(response: any): any {
    showConsoleLogs() && console.log('whatToDoAfterGettingMe', response)
    console.log('whatToDoAfterGettingMe', response)
    const proceedAfterLogin = yield select(getContinueFlowAfterLogin)
    const flow = yield select(getFlow)
    if (flow !== 'claim') {
        if (proceedAfterLogin) {
            showConsoleLogs() && console.log('go ahead because proceedAfterLogin is', proceedAfterLogin)
            // console.log('comented out going to next step')
            yield call(proceedToNextStep, {
                ...response,
                calledFrom: 'whatToDoAfterGettingMe'
            })
        } else {
            showConsoleLogs() && console.log('go to last step because proceedAfterLogin is', proceedAfterLogin)
            yield putResolve(goToLastStep())
        }
    }
}

// this creates the publication and associates the user with it as a publisher user
// we do not want to call this when attempting to claim a page 
function* callSetupAfterAuthenticating(response: any): any {
    const _setupPublisherIsComplete = yield select(getSetupPublisherComplete)
    const _currentStep = yield select(getCurrentStep)
    const flow = yield select(getFlow)
    const _setupPublisherInProgress = yield select(getSetupPublisherInProgress)
    const _sourceFormId = yield select(getSourceFormIdFromStepOne)
    // const _tagId = yield select(getTagId)
    const isNewUser = yield select(getIsNewUser)
    const hasCalledMeAlready = yield select(getPublicationsHaveBeenFetched)
    const isUserWithoutPublisherUser = yield select(getIsUserWithoutPublisherUser)

    const _getUtmSource = yield select(getUtmSource)
    const _getUtmMedium = yield select(getUtmMedium)
    const _getUtmCampaign = yield select(getUtmCampaign)
    const _getUtmTerm = yield select(getUtmTerm)
    const _getUtmContent = yield select(getUtmContent)

    const utmParams = {
        utm_source: _getUtmSource,
        utm_medium: _getUtmMedium,
        utm_campaign: _getUtmCampaign,
        utm_term: _getUtmTerm,
        utm_content: _getUtmContent,
    }


    if (showConsoleLogs()) {
        console.group('callSetupAfterAuthenticating')
        console.log('_setupPublisherIsComplete', _setupPublisherInProgress)
        console.log('_currentStep', _currentStep)
        console.log('flow can not be claim', flow)
        console.log('_setupPublisherInProgress', _setupPublisherInProgress)
        console.log('_sourceFormId', _sourceFormId)
        console.groupEnd()
    }

    if ((hasCalledMeAlready || isNewUser || isUserWithoutPublisherUser) && flow !== 'claim' && !_setupPublisherIsComplete && !_setupPublisherInProgress) {
        yield putResolve(
            setupPublisher({
                ..._sourceFormId && {
                    remix_form_id: _sourceFormId
                },
                publication_name: null,
                utmParams,
            })
        )
    }
}

function* makeRedirectOnIosLogin(response: any): any {
    window.location.assign(response.payload?.redirectUrl)
}

// sets the kit id selection from the source page on remix
function* setKitOptionIdSelectedFromRemix(response: any): any {
    const { hype_kit_ref } = response.payload
    yield putResolve(
        setKitOptionIdSelected({
            kitId: hype_kit_ref
        })
    )
}

// pass the claim to the exchange token
function* handleExchange(response: any): any {
    const { claim } = response.payload
    yield putResolve(
        exchangeClaimForToken({
            claim,
        })
    )
}

function* resetAll(): any {
    console.log('reset all')
}

export default function* rootSagas() {
    yield all([
        ...Authentication.takes,
        ...Publisher.takes,
        ...Scrapeyard.takes,
        ...KitOptions.takes,
        ...Analytics.takes,
        ...Remix.takes,
        ...FeatureFlags.takes,
        ...Progress.takes,
        ...ClaimUsernameCheck.takes,
        ...Tags.takes,
        ...Purchases.takes,
        takeLatest(SET_SCOPE, callSetupAfterAuthenticating),
        takeLatest(whatToDoAfterStoringTokens.fulfilled, checkIfWeNeedToCallSetup),
        takeLatest(whatToDoAfterStoringTokens.fulfilled, callMeMaybe),
        takeLatest(whatToDoAfterStoringTokens.fulfilled, checkIfFlowIsClaim),
        takeLatest(whatToDoAfterStoringTokens.fulfilled, existingPublisher),
        takeLatest(whatToDoAfterStoringTokens.fulfilled, checkIfFlowIsRemix),
        takeLatest(getMe.fulfilled, whatToDoAfterGettingMe),
        takeEvery(confirmOneTimeCode.fulfilled, handleExchange),
        takeEvery('RESET_ALL', resetAll),
        takeLatest(exchangeClaimForToken.fulfilled, storeTokens),
        takeLatest(authWithSocial.fulfilled, storeTokens),
        takeLatest(signupWithEmailPassword.fulfilled, storeTokens),
        takeLatest(authWithEmailPassword.fulfilled, storeTokens),
        takeLatest(setupPublisher.fulfilled, storeTokens),
        takeLatest(switchToPublisher.fulfilled, storeTokens),
        takeLatest(switchToPublisher.fulfilled, addTagToPublisherMaybe),
        takeLatest(updatePublisher.fulfilled, addTagToPublisherMaybe),
        takeLatest(addTagToPublisher.fulfilled, fetchTagsAndSeeIfPublisherIsEligibleForTrial),
        takeLatest(addTagToPublisher.rejected, fetchTagsAndSeeIfPublisherIsEligibleForTrial),
        takeLatest('NO_TAG_ID', fetchTagsAndSeeIfPublisherIsEligibleForTrial),
        takeEvery(getTrialInfo.fulfilled, afterUpdatingPublisher),
        takeLatest(setupPublisher.fulfilled, nextStepAfterSetupPublisher),
        takeEvery(scrapeInstagram.fulfilled, pollInstagramStatus),
        takeEvery(scrapeBeacons.fulfilled, pollBeaconsStatus),
        takeEvery(scrapeTiktok.fulfilled, pollTiktokStatus),
        takeEvery(addSocialLinks.fulfilled, nextStepAfterAddingSocialLinks),
        takeEvery(iosLogin.fulfilled, makeRedirectOnIosLogin),
        takeEvery(requestKitIdFromSourceForm.fulfilled, setKitOptionIdSelectedFromRemix),
        takeEvery(claimPageWithEmail.fulfilled, proceedToNextStep)
    ])
}
