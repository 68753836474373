import createAsyncSaga from '@/utils/store/createAsyncSaga'  
import * as endpoints from '@piconetworks/pkg-endpoints'
import { useAnalytics } from '@piconetworks/pkg-pico-analytics'
const name = 'analytics'


export {
    name,
    createAsyncSaga,
    useAnalytics,
    endpoints
}
