import qs from 'qs'
import { call, put, take } from 'redux-saga/effects'
import {
    createAsyncSaga,
    name,
    endpoints,
    generateClaim,
} from '../dependencies'

const generateCheckoutSession = createAsyncSaga(
    `${name}/generateCheckoutSession`,
    function* ({
        payload: {
            publisherId = null,
            appTierLabel = null,
            queryToKeep = null,
        } = {},
        fulfilled,
        rejected,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            yield put(yield call(generateClaim, {
                publisherId,
            }))

            yield take(generateClaim.pending)
            const responseForStripe = yield take([generateClaim.fulfilled, generateClaim.rejected])
            const claimForStripe = responseForStripe?.payload?.claim

            if (!claimForStripe) {
                throw new Error('no claim for stripe returned')
            }
            yield put(yield call(generateClaim, {
                publisherId,
            }))
            yield take(generateClaim.pending)
            const responseForKittens = yield take([generateClaim.fulfilled, generateClaim.rejected])
            const claimForKittens = responseForKittens?.payload?.claim

            if (!claimForKittens) {
                throw new Error('no claim for kittens returned')
            }

            const redirectTo = window.location.href.replace(`${window.location.origin}`, `${window.location.origin}/signup?claim=${claimForKittens}&go_to=app&${queryToKeep && queryToKeep}`)
            
            console.log('redirectTo', redirectTo)
            
            const queryParams = qs.stringify({
                claim: claimForStripe,
                publisher_id: publisherId,
                label: appTierLabel,
                redirect_to: redirectTo,
                in_app_redirect: true
            })

            const portalSessionUrl = `${endpoints.PICO_API_URL()}/apps/checkout/begin?${queryParams}`
        
            yield fulfilled({
                portalSessionUrl,
            })

            window.location.href = portalSessionUrl
        } catch (error) {
            console.error('generateCheckoutSession error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default generateCheckoutSession
