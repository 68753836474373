import {
    initialState,
    reducer,
    getPublisherId,
    takes,
    setupPublisher,
    addSocialLinks,
    checkUsername,
    updatePublisher,
    getPublisher,
    getSetupPublisherError,
    getSetupPublisherComplete,
    getSetupPublisherInProgress,
    getUsernameCheckInProgress,
    getPublisherUpdateInProgress,
    getUsernameTemporary,
    getUsernameCheckError,
    getPublisherUpdateError,
    setPublisherOnClaimTokenExchange,
    getMe,
    getMeComplete,
    getMeError,
    getMeInProgress,
    getPublications,
    getPublicationsHaveBeenFetched,
    getSelectedPublisher,
    getIsUserWithoutPublisherUser,
    hasUserButNoPublisherUser,
    publisherUser,
    getUsername, 
    getUsernameIsAvailable, 
    getUsernameChecked,
    getSwitchToPublisherCompleted,
    switchToPublisher,
    getBillingInProgress,
    getBillingError,
    getBillingInfo,
    getBillingInfoStatuses,
    getBilling,

} from './slice'

import usePublisher from './usePublisher'
import usePublisherUser from './usePublisherUser'
import useSocials from './useSocials'
import useUsername from './useUsername'

export default {
    initialState,
    reducer,
    takes
}

export {
    usePublisher,
    usePublisherUser,
    useSocials,
    useUsername,
    getPublisherId,
    setPublisherOnClaimTokenExchange,
    setupPublisher,
    addSocialLinks,
    checkUsername,
    updatePublisher,
    getPublisher,
    getSetupPublisherError,
    getSetupPublisherComplete,
    getSetupPublisherInProgress,
    getUsernameCheckInProgress,
    getPublisherUpdateInProgress,
    getUsernameTemporary,
    getUsernameCheckError,
    getPublisherUpdateError,
    getMe,
    getMeComplete,
    getMeError,
    getMeInProgress,
    getPublications,
    getPublicationsHaveBeenFetched,
    getSelectedPublisher,
    getIsUserWithoutPublisherUser,
    hasUserButNoPublisherUser,
    publisherUser,
    getUsername, 
    getUsernameIsAvailable, 
    getUsernameChecked,
    getSwitchToPublisherCompleted,
    switchToPublisher,
    getBillingInProgress,
    getBillingError,
    getBillingInfo,
    getBillingInfoStatuses,
    getBilling,
}
