
import { select } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherIdFromAuthentication } from '../dependencies'
import { showConsoleLogs } from '@/utils/console'

const getMe = createAsyncSaga(
    `${name}/getMe`,
    function* ({
        payload,
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const publisherid = yield select(getPublisherIdFromAuthentication)
            showConsoleLogs() && console.log('getPublisherIdFromAuthentication', publisherid)
            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: '/me',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
            })

            yield fulfilled({
                ...response,
                calledFrom: 'getMe'
            })
        } catch (error) {
            yield rejected(error.message)
        }
    }
)

export default getMe
