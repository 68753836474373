import { putResolve, select, delay } from 'redux-saga/effects'

import {
    getBeacons,
    getBeaconsStatus,
    getInstagram,
    getInstagramStatus,
    getTiktok,
    getTiktokStatus,
    fetchBeaconsStatus,
    fetchInstagramStatus,
    fetchTiktokStatus,
    scrapeBeacons,
    scrapeLinktree,
} from '@/entities/Scrapeyard'

import { getPublisher } from '@/entities/Publisher'
import { showConsoleLogs } from '@/utils/console'

const SCRAPE_TIME_LIMIT = 75000
const POLL_INTERVAL = 3000

function* pollBeaconsStatus (response: any): any{
    const _beacons = yield select(getBeacons)
    const _publisher = yield select(getPublisher)
    const payload = {
        beacons: _beacons.handle,
        publisherId: _publisher.publisherId,
        scrape_id: _beacons.scrape_id,
    }

    const timeout = Date.now() + SCRAPE_TIME_LIMIT

    while(Date.now() < timeout) {
        const scrape_status = yield select(getBeaconsStatus)

        try {
            yield putResolve(fetchBeaconsStatus(payload))
            if (scrape_status === 'Done') {
                return
            }

            yield delay(POLL_INTERVAL)

        } catch (error) {
            showConsoleLogs() && console.log(error, 'err')
            return
        }
    }
}

function* pollInstagramStatus (response: any): any {
    const _ig = yield select(getInstagram)
    const _publisher = yield select(getPublisher)
    const payload = {
        instagram: _ig.handle,
        publisherId: _publisher.publisherId,
        scrape_id: _ig.scrape_id,
    }
    const timeout = Date.now() + SCRAPE_TIME_LIMIT

    while(Date.now() < timeout) {
        const scrape_status = yield select(getInstagramStatus)
        
        try {
           yield putResolve(fetchInstagramStatus(payload))
           if( scrape_status === 'Done') {
                const { linkinbio_platform, linkinbio_url } = yield select(getInstagram)
                
                const handle = new URL(linkinbio_url).pathname.replaceAll('/', '')

                switch (linkinbio_platform) {
                    case 'linktree':
                        yield putResolve(scrapeLinktree({
                            linkinbio_url,
                            linkinbio_platform: 'linktree',
                            social_handle: handle,
                            publisherId: _publisher.publisherId
                        }))

                        break
                    
                    case 'beacons': 
                        yield putResolve(scrapeBeacons({
                            linkinbio_url,
                            linkinbio_platform: 'beacons',
                            social_handle: handle,
                            publisherId: _publisher.publisherId
                        }))

                        break

                }

                // we can stop polling now
                return
            }
            
           yield delay(POLL_INTERVAL)
        
        } catch (error) {
            showConsoleLogs() && console.log(error, 'err')
            return
        }
    }
}

function* pollTiktokStatus (response: any): any {
    const _tt = yield select(getTiktok)
    const _publisher = yield select(getPublisher)
    const payload = {
        tiktok: _tt.handle,
        publisherId: _publisher.publisherId,
        scrape_id: _tt.scrape_id,
    }
    const timeout = Date.now() + SCRAPE_TIME_LIMIT

    while(Date.now() < timeout) {
        const scrape_status = yield select(getTiktokStatus)
        
        try {
           yield putResolve(fetchTiktokStatus(payload))
           if( scrape_status === 'Done') {
               // console.log('TT DONE')
                const { linkinbio_platform, linkinbio_url, handle: social_handle} = yield select(getTiktok)
                
                // this dispatch scrapes linktree/beacons and translates their links into Hype blocks
                switch (linkinbio_platform) {
                    case 'linktree':
                        yield putResolve(scrapeLinktree({
                            linkinbio_url,
                            linkinbio_platform: 'linktree',
                            publisherId: _publisher.publisherId,
                            social_handle,
                        }))

                        break

                    case 'beacons': 
                        yield putResolve(scrapeBeacons({
                            linkinbio_url,
                            linkinbio_platform: 'beacons',
                            publisherId: _publisher.publisherId,
                            social_handle,
                        }))
                }

                // we can stop polling now
                return
            }
            
           yield delay(POLL_INTERVAL)
        
        } catch (error) {
            showConsoleLogs() && console.log(error, 'err')
            return
        }
    }
}

export {
    pollBeaconsStatus,
    pollInstagramStatus,
    pollTiktokStatus,
}