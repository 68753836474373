import {
    initialState,
    reducer,
    getClaimFromQueryParams,
    getEmail,
    getName,
    getTagId,
    getTagType,
    getTagColor,
    getTagPackSize,
    getInApp,
    getLogin,
    setQueryParams,
    getUtmSource,
    getUtmMedium,
    getUtmCampaign,
    getUtmTerm,
    getUtmContent,
    getSourceFormId,
    getUsername,
    getGoTo,
} from './slice'

import useQueryParams from './useQueryParams'

export default {
    initialState,
    reducer,
}

export {
    useQueryParams,
    getClaimFromQueryParams,
    getEmail,
    getName,
    getTagId,
    getTagType,
    getTagColor,
    getTagPackSize,
    getInApp,
    getLogin,
    setQueryParams,
    getUtmSource,
    getUtmMedium,
    getUtmCampaign,
    getUtmTerm,
    getUtmContent,
    getSourceFormId,
    getUsername,
    getGoTo,
}
