
import { createAsyncSaga, name, endpoints } from '../dependencies'

const checkExistingEmail = createAsyncSaga(
    `${name}/checkExistingEmail`,
    function* ({
        payload: {
            email = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: endpoints.SETUP_EMAIL_EXIST_CHECK(),
                data: {
                    email
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected(error)
        }
    }
)

export default checkExistingEmail
