import {
    initialState,
    reducer,
    actions,
    getClaim,
    requestSocialUsernames,
    setClaimUsername,
    getClaimUsername,
    getClaimError,
    takes,
    getSocialUsernames, 
    setClaimError, 
    resetClaim, 
    getSocialFetchInProgress, 
    getSocialFetchError,
} from './slice'

import useClaimUsername from './useClaimUsername'

import ClaimCheck from './components'

export default {
    initialState,
    reducer,
    actions,
    takes
}

export {
    useClaimUsername,
    getClaim,
    actions,
    setClaimUsername,
    getClaimUsername,
    requestSocialUsernames,
    getClaimError,
    getSocialUsernames, 
    setClaimError, 
    resetClaim, 
    getSocialFetchInProgress, 
    getSocialFetchError,
    ClaimCheck,
}
