import { createAsyncSaga, name } from '../dependencies'
import { STORE_TOKENS } from '@/utils/store/middleware/networkMiddleware'
import { putResolve, take, call, put } from 'redux-saga/effects'
import { whatToDoAfterStoringTokens } from '@/entities/Authentication'

// happens after exchanging the claim for a token
// happens after logging in with social or email
// happens after signing up with social or email
// happens after setup is complete
const handleStoreTokens = createAsyncSaga(
    `${name}/handleStoreTokens`,
    function* ({
        payload,
        fulfilled,
        rejected,
    }) {
        try {
            const access_token = payload?.token?.token || payload?.token

            if (!access_token) {
                throw new Error('access_token not provided')
            }
            const refresh_token = payload?.token?.refresh_token || payload?.refresh_token
            
            yield putResolve({
                type: STORE_TOKENS,
                access_token,
                refresh_token,
            })
            
            yield put(yield call(whatToDoAfterStoringTokens, payload))
            
            yield take(whatToDoAfterStoringTokens.pending)

            const result = yield take([whatToDoAfterStoringTokens.fulfilled, whatToDoAfterStoringTokens.rejected])

            if (result.type === whatToDoAfterStoringTokens.rejected) {
                throw new Error('whatToDoAfterStoringTokens rejected', result)
            }
            if (result.type === whatToDoAfterStoringTokens.fulfilled) {
                yield fulfilled(payload)
            }
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default handleStoreTokens
