import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { featureFlags, requestFeatureFlags, isFetched } from "@/entities/FeatureFlags"
import { getUserId } from "@/entities/Authentication"

const useFeatureFlags = (fetch = false) => {
    const dispatch = useDispatch()
    const _featureFlags = useSelector(featureFlags)
    const _isFetched = useSelector(isFetched)
    const userId = useSelector(getUserId)

    useEffect(() => {
        if (!fetch) {
            return
        }

        if (_isFetched) {
            return
        }

        dispatch(requestFeatureFlags({
            userId,

        }))
    }, [fetch, _isFetched, userId, dispatch])


    return {
        featureFlags: _featureFlags,
        isFetched: _isFetched,
    }
}

export default useFeatureFlags
