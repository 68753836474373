import { createAsyncSaga, name, endpoints } from '../dependencies'

const scrapeBeacons = createAsyncSaga(
    `${name}/scrapeBeacons`,
    function* ({
        payload: {
            linkinbio_url = null,
            publisherId = null,
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const url = new URL(linkinbio_url)
            const handle = url.pathname.replaceAll('/', '')

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: '/scrapeyard',
                headers: {
                    publisherid: publisherId
                },
                data: {
                    social_handle: handle,
                    social_platform: 'beacons'

                }
            })

            yield fulfilled({
                scrape: {
                    scrape_id : response.scrape.scrape_id,
                    social_handle: handle,
                    social_platform: 'beacons',
                    status: response.scrape.status
                }
            })

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default scrapeBeacons
