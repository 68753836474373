
import { showConsoleLogs } from '@/utils/console'
import { createAsyncSaga, name, endpoints } from '../dependencies'

const hydrate = createAsyncSaga(
    `${name}/hydrate`,
    function* ({
        payload: {
            publisherId = null,
            scrapeyard = null,
            flow = null,
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!publisherId) {
                throw Error('Missing data')
            }
  
            const { ready, instagram, tiktok, linktree, beacons } = scrapeyard 
            let payload, retry = false, response, scrape_id

            if (ready) {
                showConsoleLogs() && console.log('ready')
                if (instagram.scrape_status === 'Done') {  
                    showConsoleLogs() && console.log('ig done')
                    if (instagram.linkinbio_platform === 'linktree' && linktree.profile) {
                        showConsoleLogs() && console.log(linktree, 'hydrate w ig/linktree')

                        scrape_id = linktree?.scrape_id

                        payload = {
                            social_platform: 'linktree',
                            social_handle: linktree?.profile?.account?.username,
                            linkinbio_platform: 'linktree',
                            links: linktree?.profile?.links,
                        }

                    } else if (instagram.linkinbio_platform === 'beacons' && beacons.scrape_status === 'Done') {
                        showConsoleLogs() && console.log(beacons, 'hydrate w ig/beacons')

                        scrape_id = beacons?.scrape_id
                        payload = {
                            social_platform: 'beacons',
                            social_handle: beacons?.handle,
                            linkinbio_platform: 'beacons',
                            links: beacons?.data
                        }
                    }
                } else if (tiktok.scrape_status === 'Done') {

                    if (tiktok.linkinbio_platform == 'linktree' && linktree.profile) {
                        showConsoleLogs() && console.log(linktree, 'hydrate w tt/linktree')

                        scrape_id = linktree?.scrape_id

                        payload = {
                            social_platform: 'linktree',
                            social_handle: tiktok?.handle,
                            linkinbio_platform: 'linktree',
                            links: linktree?.profile?.links,
                        }

                    } else if (tiktok.linkinbio_platform == 'beacons' && beacons.scrap_status === 'Done') {
                        showConsoleLogs() && console.log(beacons, 'hydrate w tt/beacons')

                        scrape_id = beacons?.scrape_id

                        payload = {
                            social_platform: 'tiktok',
                            social_handle: tiktok?.handle,
                            linkinbio_platform: 'beacons',
                            links: beacons?.data
                        }
                    }
                } else {
                    showConsoleLogs() && console.log('should not get here')
                    // TODO: RETRY
                }

                if (scrape_id !== undefined) {
                    response = yield request({
                        method: 'post',
                        baseURL: endpoints.PICO_API_URL(),
                        url: `/scrapeyard/hydrate/${scrape_id}`,
                        headers: {
                            publisherid: publisherId
                        },
                        data: payload
                    })
                } else if (flow !== 'remix') {
                    // FALLTHRU to Kit hydration
                    showConsoleLogs() && console.log('hydrate w kits')
                    response = yield request({
                        method: 'post',
                        baseURL: endpoints.PICO_API_URL(),
                        url: `/setup/creator/hydrate/page`,
                        headers: {
                            publisherid: publisherId,
                        },
                        data: {
                            remix
                        }
                    })
                }
    
            } else {
                // RETRY
                // const retry = yield request({
                //     method: 'post',
                //     baseURL: endpoints.PICO_API_URL(),
                //     url: `/scrapeyard/status/${scrape_id}`,
                //     headers: {
                //         publisherid: publisherId
                //     },
                //     data: payload
                // })

                // KIT hydration
                showConsoleLogs() && console.log('hydrate w kits')
                    response = yield request({
                        method: 'post',
                        baseURL: endpoints.PICO_API_URL(),
                        url: `/setup/creator/hydrate/page`,
                        headers: {
                            publisherid: publisherId
                        }
                    })
                
            }
            
            // console.log('redirect', response)
            yield fulfilled(response)

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default hydrate
