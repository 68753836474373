
import { createAsyncSaga, name, endpoints } from '../dependencies'

const scrapeTiktok = createAsyncSaga(
    `${name}/scrapeTiktok`,
    function* ({
        payload: {
            tiktok = null,
            publisherId = null
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: '/scrapeyard',
                headers: {
                    publisherid: publisherId
                },
                data: {
                    social_handle: tiktok,
                    social_platform: 'tiktok',
                }
            })

            yield fulfilled({
                scrape: {
                    scrape_id : response.scrape.scrape_id,
                    social_handle: tiktok,
                    social_platform: 'tiktok',
                    status: response.scrape.status
                }
            })

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default scrapeTiktok
