import {
    createDraftSafeSelector,
    createSlice,
    createAction,
    createEntityAdapter
} from '@reduxjs/toolkit'

import { name } from '../dependencies'
import setupPublisher from './setupPublisher'
import checkUsername from './checkUsername'
import updatePublisher from './updatePublisher'
import addSocialLinks from './addSocialLinks'
import getMe from './getMe'
import switchToPublisher from './switchToPublisher'
import getBilling from './getBilling'

import { showConsoleLogs } from '@/utils/console'

const publicationsAdapter = createEntityAdapter()

const initialState = publicationsAdapter.getInitialState({
    publisherId: null,
    user: {},
    publications: [],
    userButNoPublisherUser: null,
    setupPublisherInProgress: false,
    setupPublisherComplete: false,
    setupPublisherError: null,
    username: null,
    usernameAvailable: null,
    usernameChecked: false,
    usernameCheckInProgress: false,
    usernameCheckError: null,
    usernameTemporary: null,
    publisherUpdateInProgress: false,
    publisherUpdateError: null,
    redirectInProgress: false,
    redirectError: null,
    meInProgress: false,
    meError: null,
    meComplete: false,
    switchToPublisherInProgress: false,
    switchToPublisherError: null,
    switchToPublisherCompleted: null,
    billing: null,
    billingInProgress: false,
    billingError: null,
})

const setPublisherOnClaimTokenExchange = createAction(`${name}/SET_PUBLISHER_ON_CLAIM_TOKEN_EXCHANGE`)
const hasUserButNoPublisherUser = createAction(`${name}/HAS_USER_BUT_DOES_NOT_HAVE_A_PUBLISHER_USER`)

const publisherSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        'PURGE': (state: any) => {
            state = initialState
        },
        [setPublisherOnClaimTokenExchange]: (state: any, { payload: { publisher_id = null } }) => {
            state.publisherId = publisher_id
        },
        [hasUserButNoPublisherUser]: (state: any) => {
            state.userButNoPublisherUser = true
        },
        [setupPublisher.pending]: (state: any, { payload }: any) => {
            state.setupPublisherInProgress = true
            state.setupPublisherError = null
            state.setupPublisherComplete = false
        },
        [setupPublisher.fulfilled]: (state: any, { payload }: any) => {
            state.setupPublisherInProgress = false
            state.setupPublisherComplete = true
            state.publisherId = payload?.publisher?.id
            state.user = payload?.user
            publicationsAdapter.addOne(state, payload?.publisher)

            state.user = payload?.user
        },
        [setupPublisher.rejected]: (state: any, { payload: error }: any) => {
            state.setupPublisherInProgress = false
            state.setupPublisherError = error
            state.setupPublisherComplete = false
        },
        [checkUsername.pending]: (state: any, { payload }: any) => {
            state.usernameAvailable = null
            state.username = null
            state.usernameChecked = false
            state.usernameCheckInProgress = true
            state.usernameCheckError = null
        },
        [checkUsername.fulfilled]: (state: any, { payload }: any) => {
            state.usernameCheckInProgress = false
            state.usernameCheckError = null
            state.usernameChecked = true
            state.username = payload.username
            state.usernameAvailable = true
        },
        [checkUsername.rejected]: (state: any, { payload }: any) => {
            state.usernameCheckInProgress = false
            state.usernameAvailable = false
            state.usernameChecked = true
            state.username = payload.username
            state.usernameCheckError = payload.error
        },
        [updatePublisher.pending]: (state: any, { payload }: any) => {
            state.publisherUpdateInProgress = true
            state.publisherUpdateError = null
        },
        [updatePublisher.fulfilled]: (state: any, { payload }: any) => {
            state.publisherId = payload?.id
            state.publisherUpdateInProgress = false
            state.publisherUpdateError = null
            publicationsAdapter.upsertOne(state, payload)
        },
        [updatePublisher.rejected]: (state: any, { payload: error }: any) => {
            state.publisherUpdateInProgress = false
            state.publisherUpdateError = error
        },

        // SOCIALS STEP
        [addSocialLinks.pending]: (state: any, { payload: error }: any) => {
        //  console.log('socials pending reducer')
        },
        [addSocialLinks.fulfilled]: (state: any, { payload }: any) => {
            showConsoleLogs() && console.log('socials done reducer', payload)
            let _usernameTemporary = payload?.instagram || payload?.tiktok || null
            state.usernameTemporary = _usernameTemporary?.replace('@', '').replace('+', '').toLowerCase()
        },
        [addSocialLinks.rejected]: (state: any, { payload: error }: any) => {
            showConsoleLogs() && console.log('socials rejected reducer')
        },
        [getMe.pending]: (state: any) => {
            state.meInProgress = true
            state.meError = null
            state.meComplete = false
        },
        [getMe.fulfilled]: (state: any, { payload }: any) => {
            state.meInProgress = false
            state.meComplete = true
            publicationsAdapter.setAll(state, payload?.publications || [])
            state.publications = payload?.publications
        },
        [getMe.rejected]: (state: any, { payload: error }: any) => {
            state.meInProgress = false
            state.meError = error
            state.meComplete = false
        },
        [switchToPublisher.pending]: (state: any) => {
            state.switchToPublisherInProgress = true
            state.switchToPublisherError = null
            state.switchToPublisherCompleted = false
        },
        [switchToPublisher.fulfilled]: (state: any, { payload }: any) => {
            state.switchToPublisherInProgress = false
            state.switchToPublisherError = null
            state.switchToPublisherCompleted = true
            state.publisherId = payload?.publisher_id
        },
        [switchToPublisher.rejected]: (state: any, { payload: error }: any) => {
            state.switchToPublisherInProgress = false
            state.switchToPublisherError = error
            state.switchToPublisherCompleted = false
        },
        [getBilling.pending]: (state: any) => {
            state.billingInProgress = true
            state.billingError = null
        },
        [getBilling.fulfilled]: (state: any, { payload }: any) => {
            state.billingInProgress = false
            state.billingError = null
            state.billing = payload
        },
        [getBilling.rejected]: (state: any, { payload: error }: any) => {
            state.billingInProgress = false
            state.billingError = error
        },
    },
})

// SELECTORS
const getPublisher = (state: any) => state[name]

const { selectById }= publicationsAdapter.getSelectors((state: any) => state[name])

const getPublisherId = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.publisherId,
)
const publisherUser = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.user,
)
const getSetupPublisherInProgress = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.setupPublisherInProgress,
)
const getSetupPublisherError = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.setupPublisherError,
)
const getSetupPublisherComplete = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.setupPublisherComplete,
)
const getUsername = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.username,
)
const getUsernameTemporary = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.usernameTemporary
)
const getUsernameIsAvailable = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.usernameAvailable,
)
const getUsernameCheckInProgress = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.usernameCheckInProgress,
)
const getUsernameChecked = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.usernameChecked,
)
const getUsernameCheckError = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.usernameCheckError,
)
const getPublisherUpdateInProgress = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.publisherUpdateInProgress,
)
const getPublisherUpdateError = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.publisherUpdateError,
)
const getMeInProgress = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.meInProgress
)
const getMeComplete = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.meComplete
)
const getMeError = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.meError
)
const getPublications = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.publications,
)
const getPublicationsHaveBeenFetched = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.meComplete,
)
const getSelectedPublisher = createDraftSafeSelector(
    [
        getPublisherId,
        (state: any) => (id = null) => selectById(state, id) || {},
    ],
    (selectedPublisherId: any, _selectById: any) => {
        return _selectById?.(selectedPublisherId)
    }
)
const getIsUserWithoutPublisherUser = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.userButNoPublisherUser
)
const getSwitchToPublisherCompleted = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.switchToPublisherCompleted,
)
const getBillingInProgress = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.billingInProgress
)
const getBillingError = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.billingError,
)
const getBillingInfo = createDraftSafeSelector(
    getPublisher,
    (Publisher: any) => Publisher?.billing
)
const getBillingInfoStatuses = createDraftSafeSelector(
    getBillingInfo,
    (billingInfo: any) => billingInfo?.statuses || []
)

// GENERAL
const takes = [
    ...setupPublisher.takes,
    ...checkUsername.takes,
    ...updatePublisher.takes,
    ...addSocialLinks.takes,
    ...getMe.takes,
    ...switchToPublisher.takes,
    ...getBilling.takes,
]

const PublisherInitialState = {
    [name]: initialState,
}

const PublisherReducer = {
    [name]: publisherSlice.reducer,
}

const PublisherActions = {
    ...publisherSlice.actions
}

// EXPORTS
export default publisherSlice.reducer

export {
    takes,
    PublisherInitialState as initialState,
    PublisherReducer as reducer,
    PublisherActions as actions,
    getPublisherId,
    publisherUser,
    setupPublisher,
    addSocialLinks,
    checkUsername,
    getSetupPublisherInProgress,
    getSetupPublisherComplete,
    getPublisher,
    setPublisherOnClaimTokenExchange,
    updatePublisher,
    getUsername,
    getPublisherUpdateInProgress,
    getUsernameTemporary,
    getUsernameCheckInProgress,
    getUsernameChecked,
    getUsernameIsAvailable,
    getSetupPublisherError,
    getUsernameCheckError,
    getPublisherUpdateError,
    getMe,
    getMeInProgress,
    getMeComplete,
    getMeError,
    getPublications,
    getPublicationsHaveBeenFetched,
    selectById,
    getSelectedPublisher,
    hasUserButNoPublisherUser,
    switchToPublisher,
    getIsUserWithoutPublisherUser,
    getSwitchToPublisherCompleted,
    getBilling,
    getBillingInProgress,
    getBillingError,
    getBillingInfo,
    getBillingInfoStatuses,
}
