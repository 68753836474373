import { createAsyncSaga, name, endpoints } from '../dependencies'

const requestOneTimeCode = createAsyncSaga(
    `${name}/requestOneTimeCode`,
    function* ({
        payload: {
            email = null,
            verifyFlowId,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!email) {
                throw new Error('email not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `auth/publisher/code/init`,
     
                data: {
                    ...(email ? { email } : {}),
                    ...(verifyFlowId ? { verify_flow_id: verifyFlowId } : {}),

                }
            })

            yield fulfilled({
                ...response, 
                ...(email ? { email } : {}),
            })
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default requestOneTimeCode
