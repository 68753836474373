import { createAsyncSaga, name } from '../dependencies'
import { call } from 'redux-saga/effects'
const page = createAsyncSaga(
    `${name}/page`,
    function* ({
        payload: { analytics, payload = {} } = {},
        fulfilled,
        rejected,
    }) {
        try {
            if (!payload.page_number) {
                throw Error('page_number must be provided')
            }

            yield call(analytics.page, { ...payload, experiments: {} })
            yield fulfilled({ ...payload })
        } catch (error) {
            yield rejected(error?.message)
            console.error(`${name}/page`, { error })
        }
    }
)

export default page
