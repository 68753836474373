import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import fetchTags from './fetchTags'
import addTagToPublisher from './addTagToPublisher'

const tagsAdapter = createEntityAdapter({
    selectId: (tag: any) => tag?.id,
    sortComparer: (a: any, b: any) => a?.id - b?.id,
})

const initialState = tagsAdapter.getInitialState({
    fetchTagsInProgress: false,
    fetchTagsSuccess: null,
    fetchTagsError: null,
    addTagToPublisherInProgress: false,
    addTagToPublisherSuccess: null,
    addTagToPublisherError: null,
})

const tagsSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchTags.pending]: (state: any, { payload }: any) => {
            state.fetchTagsSuccess = null
            state.fetchTagsInProgress = true
            state.fetchTagsError = null
        },
        [fetchTags.fulfilled]: (state: any, { payload }: any) => {
            tagsAdapter.upsertMany(state, payload?.tags || [])
            state.fetchTagsSuccess = true
            state.fetchTagsInProgress = false
            state.fetchTagsError = null
        },
        [fetchTags.rejected]: (state: any, { payload: error }: any) => {
            state.fetchTagsSuccess = false
            state.fetchTagsInProgress = false
            state.fetchTagsError = error
        },
        [addTagToPublisher.pending]: (state: any, { payload }: any) => {
            state.addTagToPublisherSuccess = null
            state.addTagToPublisherInProgress = true
            state.addTagToPublisherError = null
        },
        [addTagToPublisher.fulfilled]: (state: any, { payload }: any) => {
            state.addTagToPublisherSuccess = true
            state.addTagToPublisherInProgress = false
            state.addTagToPublisherError = null
        },
        [addTagToPublisher.rejected]: (state: any, { payload: error }: any) => {
            state.addTagToPublisherSuccess = false
            state.addTagToPublisherInProgress = false
            state.addTagToPublisherError = error
        },
    },
})

// SELECTORS
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = tagsAdapter.getSelectors((state: any) => state[name])

const getTags = (state) => state[name]

const getActiveTags = createDraftSafeSelector(
    [selectAll],
    (tags) => {
        return tags
            ?.filter((tag: any) => tag.deactivatedAt === null) || []
    },
)
const countActiveTags = createDraftSafeSelector(
    [getActiveTags],
    (tags) => tags?.length,
)
const getFetchTagsInProgress = createDraftSafeSelector(
    getTags,
    (Tags: any) => Tags?.fetchTagsInProgress,
)
const getFetchTagsSuccess = createDraftSafeSelector(
    getTags,
    (Tags: any) => Tags?.fetchTagsSuccess,
)
const getFetchTagsError = createDraftSafeSelector(
    getTags,
    (Tags: any) => Tags?.fetchTagsError,
)
const getAddTagToPublisherInProgress = createDraftSafeSelector(
    getTags,
    (Tags: any) => Tags?.addTagToPublisherInProgress,
)
const getAddTagToPublisherSuccess = createDraftSafeSelector(
    getTags,
    (Tags: any) => Tags?.addTagToPublisherSuccess,
)
const getAddTagToPublisherError = createDraftSafeSelector(
    getTags,
    (Tags: any) => Tags?.addTagToPublisherError,
)
const getSalesChannels = createDraftSafeSelector(
    [getActiveTags],
    (tags) => {
        const salesChannels = tags.map((tag: any) => tag.edition.salesChannel)
        return salesChannels
    },
)
const getHasThirdPartySalesChannels = createDraftSafeSelector(
    [getSalesChannels],
    (salesChannels) => {
        return salesChannels.filter((salesChannel?: string) => salesChannel !== null && salesChannel !== 'in-person').length > 0
    },
)

// GENERAL
const tagsInitialState = {
    [name]: initialState,
}

const tagsReducer = {
    [name]: tagsSlice.reducer,
}

const takes = [
    ...fetchTags.takes,
    ...addTagToPublisher.takes,
]

// EXPORTS
export default tagsSlice.reducer

export {
    tagsInitialState as initialState,
    tagsReducer as reducer,
    takes,
    selectAll as tags,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
    fetchTags,
    getActiveTags,
    countActiveTags,
    addTagToPublisher,
    getAddTagToPublisherInProgress,
    getAddTagToPublisherSuccess,
    getAddTagToPublisherError,
    getSalesChannels,
    getHasThirdPartySalesChannels,
    getFetchTagsError,
    getFetchTagsInProgress,
    getFetchTagsSuccess,
}
