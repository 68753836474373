import {
    initialState,
    reducer,
    getCurrentStep,
    getStepChangeInProgress,
    getStepError,
    setStepError,
    getCurrentStepIsCompleted,
    getNextStepInfo,
    transitionToNextStep,
    goBackAStep,
    actions,
    getFlow,
    setFlow,
    setContinueFlowAfterLogin,
    getContinueFlowAfterLogin,
    goToLastStep,
    transitionComplete,
    setTotalSteps,
    getTotalSteps,
    constructRedirect,
    setRedirectBase,
    setRedirectPath,
    getRedirectUrl,
    takes,
    getRedirectInProgress,
    goToSecondLastStep,
} from './slice'

import useProgress from './useProgress'
import useProcessing from './useProcessing'
import useErrors from './useErrors'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    useProgress,
    getCurrentStep,
    getStepChangeInProgress,
    getStepError,
    setStepError,
    getCurrentStepIsCompleted,
    getNextStepInfo,
    transitionToNextStep,
    useErrors,
    goBackAStep,
    actions,
    useProcessing,
    getFlow,
    setFlow,
    setContinueFlowAfterLogin,
    getContinueFlowAfterLogin,
    goToLastStep,
    transitionComplete,
    setTotalSteps,
    getTotalSteps,
    constructRedirect,
    setRedirectBase,
    setRedirectPath,
    getRedirectUrl,
    getRedirectInProgress,
    goToSecondLastStep,
}