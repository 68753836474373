import { createAsyncSaga, name, endpoints } from '../dependencies'

const scrapeLinktree = createAsyncSaga(
    `${name}/scrapeLinktree`,
    function* ({
        payload: {
            linkinbio_url = null,
            linkinbio_platform = null,
            social_handle = null,
            publisherId = null,
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: '/scrapeyard/linktree',
                headers: {
                    publisherid: publisherId
                },
                data: {
                    linkinbio_url,
                    linkinbio_platform,
                    social_handle,
                }
            })

            yield fulfilled(response)

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default scrapeLinktree
