import {
    initialState,
    reducer,
    takes,
    tags,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
    fetchTags,
    countActiveTags,
    getActiveTags,
    addTagToPublisher,
    getAddTagToPublisherError,
    getAddTagToPublisherInProgress,
    getAddTagToPublisherSuccess,
    getHasThirdPartySalesChannels,
} from './slice'
import useTags from './useTags'

export default {
    initialState,
    reducer,
    takes,
}

export {
    tags,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
    fetchTags,
    countActiveTags,
    getActiveTags,
    addTagToPublisher,
    useTags,
    getAddTagToPublisherError,
    getAddTagToPublisherInProgress,
    getAddTagToPublisherSuccess,
    getHasThirdPartySalesChannels,
}
