import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPublisherId, isNil, useTags } from './dependencies'

import {
    getTrial,
    getTrialInProgress,
    getTrialError,
    getTrialInfo,
    getShowTrial,
    generateCheckoutSession,
    getGenerateCheckoutSessionInProgress,
} from './slice'

const useTrial = () => {
    const dispatch = useDispatch()
    const publisherId = useSelector(getPublisherId)
    const trial = useSelector(getTrial)
    const trialInProgress = useSelector(getTrialInProgress)
    const trialError = useSelector(getTrialError)
    const trialLengthInDays = trial?.trial_period_days
    const trialLengthInMonths = trialLengthInDays ? Math.floor(trialLengthInDays/30) : null
    const trialLengthInWeeks = trialLengthInDays ? Math.floor(trialLengthInDays/7) : null
    const shouldShowTrial = useSelector(getShowTrial)
    const generateCheckoutSessionInProgress = useSelector(getGenerateCheckoutSessionInProgress)
    const {
        hasThirdPartySalesChannels,
        salesChannels,
        fetchTagsInProgress,
    } = useTags()
    
    // in ENG-2158, it was suggested that we only show the trial if the publisher has third party sales channels, but why not offer it to everyone?
    const showTrial = shouldShowTrial && trialInProgress === false && trialError === null
    
    // if trialLengthInDays is null, then trialLength will be null
    let trialLength = isNil(trialLengthInDays) ? null : `${trialLengthInDays} days`
    if (trialLengthInDays === 1) {
        trialLength = `${trialLengthInDays} day`
    } else if (trialLengthInMonths === 1) {
        trialLength = `${trialLengthInMonths} month`
    } else if (trialLengthInMonths > 1) {
        trialLength = `${trialLengthInMonths} months`
    } else if (trialLengthInWeeks === 1) {
        trialLength = `${trialLengthInWeeks} week`
    } else if (trialLengthInWeeks > 1) {
        trialLength = `${trialLengthInWeeks} weeks`
    }
    const _getTrialInfo = useCallback(() => {
        dispatch(getTrialInfo({ publisherId }))
    }, [dispatch, publisherId])

    const _generateCheckoutSession = useCallback((queryToKeep) => {
        if (!generateCheckoutSessionInProgress) {
            dispatch(
                generateCheckoutSession({
                    publisherId,
                    appTierLabel: trial?.trial_app_tier_label,
                    queryToKeep,
                })
            )
        }
    }, [dispatch, publisherId, trial?.trial_app_tier_label, generateCheckoutSessionInProgress])

    return {
        getTrialInfo: _getTrialInfo,
        appTierId: trial?.trial_app_tier_id,
        appTierLabel: trial?.trial_app_tier_label,
        appTierName: trial?.trial_app_tier_name,
        days: trial?.trial_period_days,
        months: trialLengthInMonths,
        priceId: trial?.trial_price,
        trialInProgress,
        trialError,
        showTrial,
        trialLength,
        generateCheckoutSession: _generateCheckoutSession,
        generateCheckoutSessionInProgress,
    }
}

export default useTrial
