import { FORM_ERROR } from 'final-form'
import createAsyncSaga from '@/utils/store/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { formatSocialLinks } from '@/utils/form/formatString'
import { getTagId, getInApp } from '@/entities/QueryParams'
import { getSelectedKitOptionId } from '@/entities/KitOptions'
import { 
    getRefreshTokens, 
    getPublisherIdFromAuthentication, 
    getIsNewUser, 
    generateClaim, 
    getName,
    setPublisherIdFromAuthenticationOnClaimTokenExchange,
} from '@/entities/Authentication'

const name = 'publisher'

export {
    name,
    createAsyncSaga,
    endpoints,
    formatSocialLinks,
    FORM_ERROR,
    getRefreshTokens,
    getPublisherIdFromAuthentication,
    getIsNewUser,
    getTagId,
    getInApp,
    generateClaim,
    getName,
    getSelectedKitOptionId,
    setPublisherIdFromAuthenticationOnClaimTokenExchange,
}
