import {
    initialState,
    reducer,
    takes,
    actions,
    page,
    track,
    identify
} from './slice'

import useAnalytics from './useAnalytics'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    useAnalytics,
    page,
    track,
    identify
}