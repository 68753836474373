import { createAsyncSaga, name, endpoints } from '../dependencies'
// delete?
const requestKitIdFromSourceForm = createAsyncSaga(
    `${name}/requestKitIdFromSourceForm`,
    function* ({
        payload: {
            sourceFormId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            
            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `/setup/remix/info/${sourceFormId}`,
            })

            if (response.hype_kit_ref === null) {
                // set default if there isnt one
                yield fulfilled({
                    ...response,
                    hype_kit_ref: 'clegco5fj0bbk0ak58dab5zl4',
                    calledFrom: 'requestKitIdFromSourceForm'
                })
            } else {
                yield fulfilled({
                    ...response,
                    calledFrom: 'requestKitIdFromSourceForm'
                })
            }
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default requestKitIdFromSourceForm

