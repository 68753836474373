
import React from 'react'
import { SocialLoginButton, PICO_API_URL, Button } from '../../dependencies'
import cx from 'classnames'
import { InstagramIcon } from '../icons'

const InstagramLogin = ({ onLoginSuccess, onLoginFailure, processing = false, userId, hypeUsername, socialUsername, onButtonClick = () => {} }) => {
    return (
        <div className='flex justify-center'>
            <SocialLoginButton
                key={'facebook'}
                scope='claim'
                params={{ hype_username: hypeUsername , social_username: socialUsername }}
                type={'instagram'}
                api_url={PICO_API_URL()}
                userId={userId}
                disabled={processing}
                button={
                    <Button
                        color='secondary'
                        fullWidth
                        startIcon={<InstagramIcon />}
                        className={cx(
                            'btn-social',
                            {
                                processing: 'cursor-not-allowed'
                            }
                        )}
                        disabled={processing}
                    >
                        <span className={cx('w-full', 'plausible-event-name=Signup+Step+1a', 'plausible-event-method=Google')}>{processing ? 'Processing...' : 'Verify with Instagram'}</span>
                    </Button>
                }
                onLogin={onLoginSuccess}
                onFailure={onLoginFailure}
                onClick={onButtonClick}
            />
        </div>
    )
}

export default InstagramLogin
