import { showConsoleLogs } from '@/utils/console'
import { fieldValidations } from '@/utils/form/validators'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedKitOptionId, getName, getTagId } from './dependencies'
import { 
    checkUsername, 
    getUsername, 
    getUsernameIsAvailable, 
    getUsernameTemporary, 
    updatePublisher, 
    getUsernameCheckInProgress, 
    getUsernameChecked,
    getUsernameCheckError,
} from '@/entities/Publisher'

const useUsername = () => {
    const dispatch = useDispatch()
    const _usernameIsAvailable = useSelector(getUsernameIsAvailable)
    const _name = useSelector(getName)
    const _usernameEvaluated = useSelector(getUsername)
    const _usernameTemporary = useSelector(getUsernameTemporary)
    const _usernameCheckInProgress = useSelector(getUsernameCheckInProgress)
    const _usernameChecked = useSelector(getUsernameChecked)
    const _usernameCheckError = useSelector(getUsernameCheckError)
    const _kitSelected = useSelector(getSelectedKitOptionId)
    const _tagId = useSelector(getTagId)

    const requestToCheckUsername = useCallback((payload) => {
        if (showConsoleLogs()) {
            console.log('requestToCheckUsername', payload)
            console.log('username is valid?', fieldValidations.username(payload.username) === undefined)
        }
        if (fieldValidations.username(payload.username) === undefined) {
            dispatch(checkUsername(payload))
        }
    }, [dispatch])

    const requestToUpdateUsername = useCallback((payload) => {
        const { username, publisherId } = payload
        showConsoleLogs() && console.log(payload)

        dispatch(updatePublisher({
            username,
            company_name: username,
            publisher_id: publisherId,
            name: _name,
            ..._kitSelected && {
                hype_kit_id: _kitSelected,
            },
        }))
    }, [dispatch, _kitSelected, _name])

    return {
        requestToCheckUsername,
        usernameIsAvailable: _usernameIsAvailable,
        usernameChecked: _usernameChecked,
        requestToUpdateUsername,
        usernameTemporary: _usernameTemporary,
        usernameCheckInProgress: _usernameCheckInProgress,
        usernameEvaluated: _usernameEvaluated,
        usernameCheckError: _usernameCheckError,
    }
}

export default useUsername
