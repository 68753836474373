import { showConsoleLogs } from '@/utils/console'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { scrapeInstagram } from '@/entities/Scrapeyard'

const useInstagram = (fetchData = false) => {
    const dispatch = useDispatch()

    const requestInstagram = useCallback((payload) => {
        showConsoleLogs() && console.log('inside of requestInstagram', payload)
        dispatch(scrapeInstagram(payload))
    }, [dispatch])

    return {
        requestInstagram
    }
}

export default useInstagram
