import { FORM_ERROR } from 'final-form'
import createAsyncSaga from '@/utils/store/createAsyncSaga'  
import * as endpoints from '@piconetworks/pkg-endpoints'
import { getContinueFlowAfterLogin, setRedirectPath } from '@/entities/Progress'
import { getClaimFromQueryParams, getTagId, getInApp, getLogin, getGoTo } from '@/entities/QueryParams'
const name = 'authentication'

export {
    name,
    createAsyncSaga,
    endpoints,
    getClaimFromQueryParams,
    getTagId,
    getInApp,
    getLogin,
    FORM_ERROR,
    getContinueFlowAfterLogin,
    setRedirectPath,
    getGoTo,
}
