import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { 
    getCurrentStepIsCompleted,
    getNextStepInfo,
    getCurrentStep,
    getStepChangeInProgress,
    transitionComplete,
    getFlow,
    setFlow,
    setTotalSteps,
    getTotalSteps,
    goToLastStep,
    transitionToNextStep,
    getContinueFlowAfterLogin,
    setContinueFlowAfterLogin,
    setRedirectPath,
} from '@/entities/Progress'
import { showConsoleLogs } from '@/utils/console'

const useProgress = (flowName, inApp = false) => {
    const dispatch = useDispatch()
    const _currentStep = useSelector(getCurrentStep)
    const _lastStep = useSelector(getTotalSteps)
    const _flow = useSelector(getFlow)

    if (showConsoleLogs()) {
        console.log('_currentStep', _currentStep)
    }
    
    const _stepChangeInProgress = useSelector(getStepChangeInProgress)
    const _currentStepIsCompleted = useSelector(getCurrentStepIsCompleted)
    const _nextStepInfo = useSelector(getNextStepInfo)
    const _continueFlowAfterLogin = useSelector(getContinueFlowAfterLogin)

    const setQueryParamsForRedirect = useCallback((queryToKeep) => {
        if (queryToKeep) {
            dispatch(setRedirectPath(queryToKeep))
        }
    }, [dispatch])
    
    const setLastStep = useCallback((totalSteps) => {
        dispatch(setTotalSteps(totalSteps))
    }, [dispatch])

    const jumpToLastStep = useCallback(() => {
        if (lastStep) {
            dispatch(goToLastStep())
        } else {
            console.error('lastStep is not set')
        }
    }, [dispatch])

    const _setToFlow = useCallback(() => {
        showConsoleLogs() && console.log('setting flow to', flowName)
        dispatch(setFlow(flowName))
    }, [dispatch, flowName])

    useEffect(() => {
        if (flowName && _currentStep === 1) {
            _setToFlow()
        }
    }, [flowName, _currentStep, _setToFlow])

    const setToContinueFlowAfterLogin = useCallback((shouldContinue) => {
        dispatch(setContinueFlowAfterLogin(shouldContinue))
    }, [dispatch])

    const goToNextStep = useCallback((calledFrom) => {
        showConsoleLogs() && console.log ('calling go to next step from', calledFrom)
        dispatch(transitionToNextStep({ calledFrom }))
    }, [dispatch])

    const completeStep = useCallback(() => {
        dispatch(transitionComplete())
    }, [dispatch])

    const resetAllOfTheThings = useCallback(() => {
        dispatch({
            type: 'RESET_ALL'
        })
    }, [dispatch])

    return {
        currentStep: _currentStep,
        currentStepIsCompleted: _currentStepIsCompleted,
        nextStepInfo: _nextStepInfo,
        stepChangeInProgress: _stepChangeInProgress,
        completeStep,
        flow: _flow,
        resetAllOfTheThings,
        setLastStep,
        lastStep: _lastStep,
        isSecondToLastStep: _currentStep === (_lastStep - 1),
        isLastStep: _currentStep === _lastStep,
        jumpToLastStep,
        goToNextStep,
        setToContinueFlowAfterLogin,
        continueFlowAfterLogin: _continueFlowAfterLogin,
        setQueryParamsForRedirect,
    }

}

export default useProgress
