
import { createAsyncSaga, name, endpoints } from '../dependencies'

const fetchBeaconsStatus = createAsyncSaga(
    `${name}/beaconsStatus`,
    function* ({
        payload: {
            beacons = null,
            publisherId = null,
            scrape_id = null,
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {

        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `/scrapeyard/status/${scrape_id}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    social_handle: beacons,
                    social_platform: 'beacons',
                }
            })

            yield fulfilled(response)
            

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default fetchBeaconsStatus

