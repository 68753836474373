import { createAsyncSaga, name, endpoints } from '../dependencies'

const getTrialInfo = createAsyncSaga(
    `${name}/getTrialInfo`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisher not provided')
            }
            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `/nfc/checkout/trial`,
                headers: {
                    publisherid: publisherId
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message || 'Could not get trial info'
            })
        }
    }
)

export default getTrialInfo