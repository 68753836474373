import { useCallback, useEffect } from "react"
import { useAnalytics as AnalyticsHook } from "./dependencies"
import { useDispatch } from "react-redux"
import { page, track, identify } from './index'
import { showConsoleLogs } from "@/utils/console"

const useAnalytics = ({ user, publisherId, selectedPublisher }) => {

    const dispatch = useDispatch()
    const analytics = AnalyticsHook()

    showConsoleLogs() && console.log({publisherId, user})

    const makePayloadForAnalytics = useCallback((eventType, eventData = {}) => {
        const payloadForAnalytics = {
            eventData: {
                ...eventData,
                experiments: {},
                publisher_id: publisherId || selectedPublisher?.id,
            },
            eventType, 
            analytics
        }
        if (user?.id) {
            payloadForAnalytics.eventData.user_id = user.id
        }
        if (selectedPublisher?.name) {
            payloadForAnalytics.eventData.name = selectedPublisher.name
        }
        if (selectedPublisher?.username) {
            payloadForAnalytics.eventData.username = selectedPublisher.username
        }
        if (user?.email) {
            payloadForAnalytics.eventData.email = user.email
        }
        return payloadForAnalytics
    }, [analytics, publisherId, selectedPublisher, user])

    const pageLoadAction = useCallback((payload) => {
        if (showConsoleLogs()) {
            console.log('pageLoadAction', payload)
        }
        const payloadForAnalytics = makePayloadForAnalytics('page_load', {
            ...payload.page_number && { page_number: payload.page_number },
        })
        dispatch(page({ payload, analytics }))
    }, [analytics, dispatch, makePayloadForAnalytics])

    const pageSubmitAction = useCallback((payload) => {
        const payloadForAnalytics = makePayloadForAnalytics(payload.eventType, {
            ...payload.eventData.auth_method && { auth_method: payload.eventData.auth_method },
        })

        showConsoleLogs() && console.log('pageSubmitAction payloadForAnalytics', payloadForAnalytics)

        dispatch(track(payloadForAnalytics))
    }, [dispatch, makePayloadForAnalytics])

    useEffect(() => {
        if (user?.id && publisherId) {
            showConsoleLogs() && console.log('user update', user)
            const payload = makePayloadForAnalytics('user_update', {
                created_at: user.created_at,
            })
            dispatch(identify(payload))
        }
    }, [user, publisherId, selectedPublisher, makePayloadForAnalytics, dispatch, analytics])

    return {
        pageLoadAction,
        pageSubmitAction
    }
}

export default useAnalytics
