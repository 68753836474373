import {
    createNetworkMiddleware,
    _network,
    REQUEST,
    STORE_TOKENS,
    STORE_TOKENS_SUCCESS,
    STORE_TOKENS_ERROR,
    REMOVE_TOKENS,
    REMOVE_TOKENS_SUCCESS,
    REMOVE_TOKENS_ERROR,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    SET_SCOPE
} from '@piconetworks/pkg-network-middleware'

import { OAUTH_PUBLISHER_TOKEN, PICO_API_URL } from '@piconetworks/pkg-endpoints'

import { store } from '@/utils/store/index'
import { getPublisher } from '@/entities/Publisher'

const request = (payload) => {
    // console.log('request payload', payload)
    const { refresh_token, scope } = payload
    const state = store.getState()
    const _publisher = getPublisher(state)
    // console.log('inside of request', scope, _publisher)
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        refreshtoken: refresh_token,
        ...(_publisher?.id && { publisherid: _publisher?.id }),
    }

    // console.log('calling the get with ', scope)

    return {
        method: 'get',
        baseURL: PICO_API_URL(),
        url: `${OAUTH_PUBLISHER_TOKEN()}/${refresh_token}`,
        headers,
        timeout: 30000,
    }
}

const networkMiddleware = createNetworkMiddleware({
    concurrentRequests: 8,
    accessTokenAdapters: {
        response: ({ data }) => {
            // console.log('access token adapter data', data)
            return ({
                access_token: data.token,
                refresh_token: data.refresh_token,
            })
        },
    },
    refreshTokenAdapters: {
        request,
        response: ({ data }) => {
            // console.log('response refresh token adapter', data)
            return ({
                access_token: data.token,
                refresh_token: data.refresh_token,
            })
        },
    },
})

export default networkMiddleware

export {
    _network,
    REQUEST,
    STORE_TOKENS,
    STORE_TOKENS_SUCCESS,
    STORE_TOKENS_ERROR,
    REMOVE_TOKENS,
    REMOVE_TOKENS_SUCCESS,
    REMOVE_TOKENS_ERROR,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    SET_SCOPE
}
