import { useSelector } from 'react-redux'

import { getIsAuthenticated, getSignupDetails } from '@/entities/Authentication'

const useSignupDetails = () => {
    const _signupDetails = useSelector(getSignupDetails)
    const _isAuthenticated = useSelector(getIsAuthenticated)

    if (!_signupDetails.email || !_isAuthenticated) {
        return {
            firstName: null,
            email: null
        }
    }
    return {
        firstName: _signupDetails?.firstName || null,
        email: _signupDetails?.email || null,
    }
}

export default useSignupDetails
