import jwtDecode from 'jwt-decode'
import { putResolve } from 'redux-saga/effects'
import isNil from 'lodash/isNil'
import { createAsyncSaga, name } from '../dependencies'
import { setUserIdFromDecodedJwt } from '@/entities/Authentication'
import { hasUserButNoPublisherUser, setPublisherOnClaimTokenExchange } from '@/entities/Publisher'

const whatToDoAfterStoringTokens = createAsyncSaga(
    `${name}/whatToDoAfterStoringTokens`,
    function* ({
        payload,
        fulfilled,
        rejected,
    }) {
        try {
            const access_token = payload?.token?.token || payload?.token
            const decoded_token = jwtDecode(access_token)

            if (decoded_token?.user_id) {
                yield putResolve(setUserIdFromDecodedJwt({
                    user_id: decoded_token.user_id 
                }))
            }

            if (!isNil(payload.publisher?.id) || !isNil(payload.publisher_id)) {
                // scenario a CAN call setup, but doesn't have to
                yield putResolve(setPublisherOnClaimTokenExchange({ 
                    publisher_id: payload.publisher?.id || payload.publisher_id
                }))
            } else {
                // scenario b should call setup
                // or scenario c for claiming
                // if they are a User but don't have a PublisherUser yet
                yield putResolve(hasUserButNoPublisherUser())
            }

            yield fulfilled(payload)
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default whatToDoAfterStoringTokens
