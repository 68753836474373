const validateUsername = (value) => {
    if (!value) {
        return 'Username is required'
    }
    if (/^[^0-9a-zA-Z]/g.test(value)) {
        return 'Usernames must start with a letter or number'
    }
    
    if (!/^[\w-.]+$/g.test(value)) {
        return 'Usernames can only contain letters, numbers, dashes, dots and underscores'
    }
    if (value.length > 30) {
        return 'Usernames can only be 30 characters long or less'
    }
}

const fieldValidations = {
    required: (value) => (value || typeof value === 'number' ? undefined : 'Required'),
    minLength: (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined),
    email: (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value) ? 'Invalid email address' : undefined),
    state: (value) => (value && !/^((A[LKZR])|(C[AOT])|(D[EC])|(FL)|(GA)|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EDAINSOT])|(N[EVHJMYCD])|(O[HKR])|(PA)|(RI)|(S[CD])|(T[NX])|(UT)|(V[TA])|(W[AVIY]))$/i.test(value)
        ? 'Invalid State' : undefined),
    zipCode: (value) => (value && !/^\d{5}$|^\d{5}-\d{4}$/i.test(value) ? 'Invalid Zip Code' : undefined),
    alphaStringFirstName: (value) => (value && !/^[a-zA-Z]([\w -]*[a-zA-Z ])?$/i.test(value) ? 'First name can only contain letters, spaces, and hyphens' : undefined),
    username: (value) => validateUsername(value),
    alphaNumericStringSpaces: (value) => (value && !/^[a-z0-9 -]+$/i.test(value) ? 'Usernames can only contain letters, numbers, and spaces' : undefined),
}

const composeValidators = (...validators) => (value) => validators.reduce(
    (error, validator) => error || validator(value),
    undefined,
)

export { composeValidators, fieldValidations }
