import {
    createDraftSafeSelector,
    createSlice,
    createAction
} from '@reduxjs/toolkit'
import {
    STORE_TOKENS_SUCCESS,
    STORE_TOKENS_ERROR,
    REMOVE_TOKENS_SUCCESS,
    REMOVE_TOKENS_ERROR,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    SET_SCOPE,
} from '@/utils/store/middleware/networkMiddleware'

import { name } from '../dependencies'

import checkExistingEmail from './checkExistingEmail'
import authWithEmailPassword from './authWithEmailPassword'
import authWithSocial from './authWithSocial'
import signupWithEmailPassword from './signupWithEmailPassword'
import checkPasswordStrength from './checkPasswordStrength'
import requestOneTimeCode from './requestOneTimeCode'
import confirmOneTimeCode from './confirmOneTimeCode'
import exchangeClaimForToken from './exchangeClaimForToken'
import generateClaim from './generateClaim'
import iosLogin from './iosLogin'
import godLogin from './godLogin'
import handleStoreTokens from './handleStoreTokens'
import whatToDoAfterStoringTokens from './whatToDoAfterStoringTokens'

const initialState = {
    isAuthenticated: false,
    userId: null,
    publisherIdFromAuthentication: null,
    info: null,
    hasPassword: null,
    useRefreshTokenInProgress: false,
    useRefreshTokenError: null,
    socialAuthInProgress: false,
    socialAuthError: null,
    isNewUser: null,
    emailAuthInProgress: false,
    emailAuthError: null,
    emailSignupInProgress: false,
    emailSignupError: null,
    signupDetails: {
        email: null,
        firstName: null,
        name: null,
    },
    emailChecked: false,
    emailCheckInProgress: false,
    emailIsExists: false,
    emailCheckError: null,
    emailCheckMessage: null,
    socialScrapeInProgress: false,
    socialScrapeError: null,
    passwordStrength: null,
    passwordStrengthInProgress: false,
    passwordStrengthError: null,
    scopeIsSet: false,
    oneTimeCodeInProgress: false,
    confirmOneTimeCodeInProgress: false,
    oneTimeCodeSent: false,
    oneTimeCodeError: null,
    oneTimeCodeConfirmed: null,
    verifyFlowId: null,
    exchangeClaimForTokenInProgress: null,
    exchangeClaimForTokenError: null,
    outboundClaim: null,
    generateClaimInProgress: false,
    generateClaimError: null,
}

const setUserIdFromDecodedJwt = createAction(`${name}/SET_USER_ID_FROM_DECODED_JWT`)

const authenticationSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetOTP: (draftState: any) => {
            draftState.info = null
            draftState.oneTimeCodeInProgress = false
            draftState.oneTimeCodeSent = false
            draftState.oneTimeCodeError = null
            draftState.oneTimeCodeConfirmed = null
            draftState.verifyFlowId = null
        },
        deauthorize: (draftState: any) => {
            draftState = initialState
        },
        setPublisherIdFromAuthenticationOnClaimTokenExchange: (draftState: any, { payload }: any) => {
            draftState.publisherIdFromAuthentication = payload?.publisher_id
        },
    },
    extraReducers: {
        'PURGE': (state: any) => {
            state = initialState
        },
        [setUserIdFromDecodedJwt]: (state: any, { payload }: any) => {
            state.userId = payload.user_id
        },
        [SET_SCOPE]: (state: any) => {
            state.scopeIsSet = true
        },
        [REFRESH_TOKEN]: (state: any) => {
            state.useRefreshTokenInProgress = true
            state.useRefreshTokenError = null
        },
        [REFRESH_TOKEN_SUCCESS]: (state: any) => {
            state.useRefreshTokenInProgress = false
        },
        [REFRESH_TOKEN_ERROR]: (state: any, { payload: error }: any) => {
            state.useRefreshTokenInProgress = false
            state.useRefreshTokenError = error
        },
        [handleStoreTokens.fulfilled]: (state: any) => {
            state.isAuthenticated = true
        },
        [handleStoreTokens.rejected]: (state: any) => {
            state.isAuthenticated = false
        },
        [REMOVE_TOKENS_SUCCESS]: (state: any) => {
            state.isAuthenticated = false
        },
        [REMOVE_TOKENS_ERROR]: (state: any) => {
            state.isAuthenticated = false
        },
        [authWithEmailPassword.pending]: (state: any) => {
            state.emailAuthError = null
            state.emailAuthInProgress = true
        },
        [authWithEmailPassword.fulfilled]: (state: any, { payload }: any) => {
            state.hasPassword = null
            state.info = null
            state.emailAuthError = null
            state.emailAuthInProgress = false
            state.isNewUser = false
            state.publisherIdFromAuthentication = payload?.publisher_id
            state.signupDetails.firstName = payload.account_info.first_name
            state.signupDetails.email = payload.account_info.email
        },
        [authWithEmailPassword.rejected]: (state: any, { payload: error }: any) => {
            state.emailAuthError = error
            state.emailAuthInProgress = false
        },
        [authWithSocial.pending]: (state: any) => {
            state.socialAuthInProgress = true
            state.socialAuthError = null
        },
        [authWithSocial.fulfilled]: (state: any, { payload }: any) => {
            state.socialAuthInProgress = false
            state.socialAuthError = null
            state.hasPassword = null
            state.info = null
            state.publisherIdFromAuthentication = payload?.publisher_id
            state.signupDetails.email = payload.email
            state.signupDetails.firstName = payload.first_name
            state.isNewUser = payload.new_account
        },
        [authWithSocial.rejected]: (state: any, { payload: error }: any) => {
            state.socialAuthInProgress = false
            state.socialAuthError = error
        },
        [signupWithEmailPassword.pending]: (state: any) => {
            state.emailSignupInProgress = true
            state.emailSignupError = null
        },
        [signupWithEmailPassword.fulfilled]: (state: any, { payload }: any) => {
            state.signupDetails.email = payload.user.email
            state.signupDetails.firstName = payload.first_name
            state.isNewUser = payload?.was_created
            state.emailSignupInProgress = false
            state.hasPassword = true
            state.emailSignupError = null
        },
        [signupWithEmailPassword.rejected]: (state: any, { payload: error }: any) => {
            state.emailSignupError = error
            state.emailSignupInProgress = false
        },
        [checkExistingEmail.pending]: (state: any, { payload }: any) => {
            state.emailChecked = false
            state.emailCheckMessage = null
            state.emailCheckInProgress = true
            state.emailCheckError = null
        },
        [checkExistingEmail.fulfilled]: (state: any, { payload }: any) => {
            if (payload?.email_exists) {
                state.emailCheckMessage = 'An account with this email address already exists. Please log in.'
            } else {
                state.emailCheckMessage = null
            }
            state.emailIsExists = payload?.email_exists
            state.emailCheckInProgress = false
            state.emailChecked = true
        },
        [checkExistingEmail.rejected]: (state: any, { payload: error }: any) => {
            state.emailCheckInProgress = false
            state.emailCheckMessage = null
            state.emailCheckError = error
            state.emailChecked = false
        },
        [checkPasswordStrength.pending]: (state: any) => {
            state.passwordStrength = null
            state.passwordStrengthInProgress = true
            state.passwordStrengthError = null
        },
        [checkPasswordStrength.fulfilled]: (state: any, { payload }: any) => {
            state.passwordStrength = payload
            state.passwordStrengthInProgress = false
        },
        [checkPasswordStrength.rejected]: (state: any, { payload: error }: any) => {
            state.passwordStrength = null
            state.passwordStrengthInProgress = false
            state.passwordStrengthError = error
        },
        [confirmOneTimeCode.pending]: (state: any) => {
            state.oneTimeCodeConfirmed = null
            state.confirmOneTimeCodeInProgress = true
            state.oneTimeCodeError = null
        },
        [confirmOneTimeCode.fulfilled]: (state: any, { payload }: any) => {
            state.verifyFlowId = payload?.verifyFlowId
            state.claim = payload?.claim
            state.oneTimeCodeSent = false
            state.confirmOneTimeCodeInProgress = false
            state.publisherIdFromAuthentication = payload?.publisher_id
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = true
        },
        [confirmOneTimeCode.rejected]: (state: any, { payload: error }: any) => {
            state.confirmOneTimeCodeInProgress = false
            state.oneTimeCodeError = error
            state.oneTimeCodeConfirmed = false
        },
        [requestOneTimeCode.pending]: (state: any) => {
            state.oneTimeCodeInProgress = true
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = null
            state.emailAuthInProgress = true
        },
        [requestOneTimeCode.fulfilled]: (state: any, { payload }: any) => {
            state.info = payload
            state.verifyFlowId = payload?.verifyFlowId
            state.oneTimeCodeSent = true
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = null
            state.emailAuthInProgress = false
            state.oneTimeCodeConfirmed = null
        },
        [requestOneTimeCode.rejected]: (state: any, { payload: error }: any) => {
            state.verifyFlowId = null
            state.oneTimeCodeSent = null
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = error
            state.emailAuthInProgress = false
            state.oneTimeCodeConfirmed = null
        },
        [exchangeClaimForToken.pending]: (state: any, { payload: claim }: any) => {
            state.claim = claim
            state.exchangeClaimForTokenInProgress = true
            state.exchangeClaimForTokenError = null
        },
        [exchangeClaimForToken.fulfilled]: (state: any, { payload: token }: any) => {
            state.claim = null
            state.exchangeClaimForTokenInProgress = false
            state.exchangeClaimForTokenError = null
        },
        [exchangeClaimForToken.rejected]: (state: any, { payload: error }: any) => {
            state.claim = null
            state.exchangeClaimForTokenInProgress = null
            state.exchangeClaimForTokenError = error
        },
        [generateClaim.pending]: (state: any) => {
            state.generateClaimError = null
            state.generateClaimInProgress = true
        },
        [generateClaim.fulfilled]: (state: any, { payload }: any) => {
            state.outboundClaim = payload?.claim
            state.generateClaimInProgress = false
        },
        [generateClaim.rejected]: (state: any, { payload: error }: any) => {
            state.generateClaimError = error
            state.generateClaimInProgress = false
        },
    },
})

// SELECTORS
const getAuthentication = (state: any) => state[name]
const getEmail = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.info?.email,
)
const getClaim = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.claim,
)
const hasPassword = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.hasPassword,
)
const getEmailAuthInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailAuthInProgress,
)
const getEmailAuthError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailAuthError,
)
const getEmailSignupInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailSignupInProgress,
)
const getEmailSignupError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailSignupError,
)
const getIsNewUser = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.isNewUser,
)
const getIsAuthenticated = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.isAuthenticated,
)
const getRefreshTokenInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.useRefreshTokenInProgress,
)
const useRefreshTokenError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.useRefreshTokenError,
)
const getSignupDetails = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.signupDetails,
)
const getName = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.signupDetails?.firstName || Authentication?.signupDetails?.name,
)
const getEmailCheckInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailCheckInProgress,
)
const emailCheckMessage = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailCheckMessage,
)
const emailCheckError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailCheckError,
)
const getIsEmailChecked = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailChecked,
)
const getSocialAuthInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.socialAuthInProgress,
)
const getSocialAuthError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.socialAuthError,
)
const getPasswordStrength = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.passwordStrength,
)
const getPasswordStrengthInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.passwordStrengthInProgress,
)
const getPasswordStrengthError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.passwordStrengthError,
)
const getScopeIsSet = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.scopeIsSet,
)
const isEmailExists = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.emailIsExists
)
const oneTimeCodeSent = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.oneTimeCodeSent,
)
const oneTimeCodeError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.oneTimeCodeError,
)
const oneTimeCodeConfirmed = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.oneTimeCodeConfirmed,
)
const oneTimeCodeInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.oneTimeCodeInProgress,
)
const confirmOneTimeCodeInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.confirmOneTimeCodeInProgress,
)
const verifyFlowId = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.verifyFlowId,
)
const exchangeClaimForTokenInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.exchangeClaimForTokenInProgress,
)
const exchangeClaimForTokenError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.exchangeClaimForTokenError,
)
const getUserId = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.userId,
)
const getPublisherIdFromAuthentication = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.publisherIdFromAuthentication,
)
const getGenerateClaimError = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.generateClaimError,
)
const getGenerateClaimInProgress = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.generateClaimInProgress,
)
const getOutboundClaim = createDraftSafeSelector(
    getAuthentication,
    (Authentication: any) => Authentication?.outboundClaim,
)

const getRefreshTokens = (state: any) => state['_network']

// GENERAL
const takes = [
    ...authWithEmailPassword.takes,
    ...authWithSocial.takes,
    ...signupWithEmailPassword.takes,
    ...requestOneTimeCode.takes,
    ...confirmOneTimeCode.takes,
    ...checkExistingEmail.takes,
    ...checkPasswordStrength.takes,
    ...exchangeClaimForToken.takes,
    ...generateClaim.takes,
    ...iosLogin.takes,
    ...godLogin.takes,
    ...handleStoreTokens.takes,
    ...whatToDoAfterStoringTokens.takes,
]

const AuthenticationInitialState = {
    [name]: initialState,
}

const AuthenticationReducer = {
    [name]: authenticationSlice.reducer,
}

const AuthenticationActions = {
    ...authenticationSlice.actions
}

const { deauthorize, setPublisherIdFromAuthenticationOnClaimTokenExchange } = authenticationSlice.actions

// EXPORTS
export default authenticationSlice.reducer

export {
    deauthorize,
    takes,
    AuthenticationInitialState as initialState,
    AuthenticationReducer as reducer,
    AuthenticationActions as actions,
    setPublisherIdFromAuthenticationOnClaimTokenExchange,
    authWithEmailPassword,
    authWithSocial,
    signupWithEmailPassword,
    requestOneTimeCode,
    confirmOneTimeCode,
    handleStoreTokens,

    getIsAuthenticated,
    hasPassword,
    getIsNewUser,
    getEmail,
    getClaim,
    
    getSignupDetails,
    getRefreshTokens,
    useRefreshTokenError,
    getRefreshTokenInProgress,
    getEmailCheckInProgress,
    emailCheckMessage,
    emailCheckError,
    getIsEmailChecked,
    checkExistingEmail,
    checkPasswordStrength,
    getPasswordStrength,
    getPasswordStrengthInProgress,
    getScopeIsSet,
    getSocialAuthInProgress,
    getEmailAuthInProgress,
    getEmailSignupInProgress,
    isEmailExists,
    
    oneTimeCodeSent,
    oneTimeCodeError,
    oneTimeCodeInProgress,
    oneTimeCodeConfirmed,
    confirmOneTimeCodeInProgress,
    verifyFlowId,
    // error selectors
    getEmailAuthError,
    getEmailSignupError,
    getSocialAuthError,
    getPasswordStrengthError,
    exchangeClaimForToken,
    exchangeClaimForTokenError,
    exchangeClaimForTokenInProgress,
    setUserIdFromDecodedJwt,
    getUserId,
    getPublisherIdFromAuthentication,
    getName,
    generateClaim,
    getGenerateClaimError,
    getGenerateClaimInProgress,
    getOutboundClaim,
    iosLogin,
    godLogin,
    whatToDoAfterStoringTokens,
}
