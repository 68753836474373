import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { exchangeClaimForToken, exchangeClaimForTokenError, exchangeClaimForTokenInProgress } from './slice'
import { getClaimFromQueryParams, getGoTo } from './dependencies'
import { goToLastStep } from '@/entities/Progress'

const useClaim = () => {
    const _claim = useSelector(getClaimFromQueryParams)
    const _exchangeClaimError = useSelector(exchangeClaimForTokenError)
    const _exchangeClaimInProgress = useSelector(exchangeClaimForTokenInProgress)
    const _queryParamClaim = useSelector(getClaimFromQueryParams)
    const _goTo = useSelector(getGoTo)
    const dispatch = useDispatch()

    useEffect(() => {
        if (_exchangeClaimError) {
            return
        }

        if (!_queryParamClaim) {
            return
        }

        dispatch(exchangeClaimForToken({
            claim: _queryParamClaim
        }))
    }, [_queryParamClaim, _exchangeClaimError, _goTo, dispatch])

    useEffect(() => {
        if (_exchangeClaimError) {
            return
        }
        // check explicitly that _exchangeClaimInProgress is false because we want to make sure the claim has completed
        if (_goTo === 'app' && _exchangeClaimInProgress === false) {
            dispatch(goToLastStep())
        }
    }, [_exchangeClaimError, _exchangeClaimInProgress, _goTo, dispatch])

    return {
        claim: _claim,
        exchangeClaimError: _exchangeClaimError,
    }
}

export default useClaim
