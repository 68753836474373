
import { put, select, call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getInApp } from '../dependencies'
import { godLogin, iosLogin } from '@/entities/Authentication'

const authWithEmailPassword = createAsyncSaga(
    `${name}/authWithEmailPassword`,
    function* ({
        payload: {
            email = null,
            password = null,
            firstName = null,
            tagId = null,
            // formApi
        } = {},
        fulfilled,
        rejected,
        request,
    }) {

        try {
            if (!email) {
                throw Error('email must be provided')
            }
    
            if (!password) {
                throw Error('password must be provided')
            }
            
            // TODO later put /me with first name info
            // at a later point
            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: endpoints.OAUTH_PUBLISHER(),
                data: {
                    email,
                    password,
                    first_name: firstName,
                    tag_id: tagId
                },
            })

            const is_a_god = response?.account_info?.is_a_god
            const token = response?.token
            const publisher_id = response?.publisher_id

            const _getInApp = yield select(getInApp)
            if (_getInApp) {
                yield put(yield call(iosLogin, {
                    publisherId: publisher_id,
                    token,
                    isGod: is_a_god,
                }))
    
            } else if (is_a_god) {
                yield put(yield call(godLogin, {
                    publisherId: publisher_id,
                    token,
                }))
    
                throw new Error('god login taking over')
            } else {
                yield fulfilled({
                    ...response
                })    
            }
        } catch (error) {
            console.log('error', error)
            yield rejected(error?.response?.data?.message || (error?.message?.includes?.('a god') && error?.message) || 'Could not login.')
        }
    }
)

export default authWithEmailPassword

