import React, { forwardRef, ReactNode } from 'react'
import cx from 'classnames'

export type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'color'
> &
  {
    href?: string
    onClick?: () => void
    shape?: 'circle' | 'square'
    size?: 'lg' | 'md' | 'sm' | 'xs'
    variant?: 'outline' | 'link' | 'primary-black'
    color?: 'primary' | 'secondary' | 'secondary-gray'
    fullWidth?: boolean
    responsive?: boolean
    animation?: boolean
    loading?: boolean
    active?: boolean
    startIcon?: ReactNode
    endIcon?: ReactNode
    type?: 'button' | 'submit' | 'reset'
  }

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      href,
      shape,
      size,
      variant,
      color,
      startIcon,
      endIcon,
      fullWidth,
      responsive,
      animation = true,
      loading,
      active,
      disabled,
      className,
      style,
      type = 'submit',
      onClick,
      ...props
    },
    ref
  ): JSX.Element => {
    const classes = cx(
      'btn',
      className,
      {
        'loading': loading,
      },
      ((startIcon && !loading) || endIcon) && 'gap-2', {
        [`btn-${size}`]: size,
        [`btn-${shape}`]: shape,
        [`btn-${variant}`]: variant,
        [`btn-${color}`]: color,
        'btn-block': fullWidth,
        'btn-xs md:btn-sm lg:btn-md xl:btn-lg': responsive,
        'no-animation': !animation,
        'btn-active': active,
        'btn-disabled': disabled,
      }
    )

    if (href) {
      return (
        <a className={classes} style={style} href={href}>
          {startIcon && startIcon}
          {children}
          {endIcon && endIcon}
        </a>
      )
    } else {
      return (
        <button
          {...props}
          onClick={onClick}
          ref={ref}
          className={classes}
          style={style}
          disabled={disabled}
          type={type}
        >
          {startIcon && !loading && startIcon}
          {children}
          {endIcon && endIcon}
        </button>
      )
    }
  }
)

Button.displayName = 'Button'

export default Button