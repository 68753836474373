
import { call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, formatSocialLinks } from '../dependencies'

const addSocialLinks = createAsyncSaga(
    `${name}/addSocialLinks`,
    function* ({
        payload: {
            publisherId = null,
            instagram = null,
            tiktok = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const social_links = []
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            if (tiktok) {
                social_links.push({
                    url: `${tiktok}`,
                    type: 'tiktok'
                })
            }

            if (instagram) {
                social_links.push({
                    url: `${instagram}`,
                    type: 'instagram'
                })
            }

            if (social_links.length > 0) {
                yield request({
                    method: 'post',
                    baseURL: endpoints.PICO_API_URL(),
                    url: `/social_links`,
                    headers: {
                        publisherid: publisherId
                    },
                    data: { social_links }
                })
            }

            const instagramUsername = yield call(formatSocialLinks, { value: instagram, removeSymbol: true })
            const tiktokUsername = yield call(formatSocialLinks, { value: tiktok, removeSymbol: false })
            yield fulfilled({
                instagram: instagramUsername,
                tiktok: tiktokUsername,
                publisherId,
                calledFrom: 'addSocialLinks'
            })
        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default addSocialLinks
