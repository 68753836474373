import {
    initialState,
    scrapeInstagram,
    scrapeTiktok,
    fetchInstagramStatus,
    fetchTiktokStatus,
    fetchBeaconsStatus,
    reducer,
    scrapeyard,
    takes,
    getInstagram,
    getTiktok,
    getBeacons,
    getInstagramStatus,
    getTiktokStatus,
    getBeaconsStatus,
    getLinktree,
    getScrapeyard,
    scrapeLinktree,
    scrapeBeacons,
    hydrate,
    pollBeaconsStatus,
    pollInstagramStatus,
    pollTiktokStatus,
    getHydrated,
} from './slice'

import useInstagram from './useInstagram'
import useTiktok from './useTiktok'
import useHydration from './useHydration'

export default {
    initialState,
    reducer,
    takes
}

export {
    takes,
    hydrate,
    useInstagram,
    useTiktok,
    useHydration,
    scrapeyard,
    scrapeInstagram,
    scrapeTiktok,
    fetchInstagramStatus,
    fetchTiktokStatus,
    fetchBeaconsStatus,
    getBeacons,
    getBeaconsStatus,
    getInstagram,
    getInstagramStatus,
    getLinktree,
    getScrapeyard,
    getTiktok,
    getTiktokStatus,
    pollBeaconsStatus,
    pollInstagramStatus,
    pollTiktokStatus,
    scrapeLinktree,
    scrapeBeacons,
    getHydrated,
}