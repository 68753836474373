import {
    createAction,
    createSlice,
    createDraftSafeSelector
} from '@reduxjs/toolkit'

import { name } from '../dependencies'
import requestSocialUsernames from './requestSocialUsernames'
import claimPageWithEmail from './claimPageWithEmail'

const initialState = {
    username: null,
    claimInProgress: null,
    claimError: null,
    claimConfirmed: false,
    socialFetchInProgress: null,
    socialFetchError: null,
    socialUsernames: {
        tiktok: null,
        instagram: null,
        email: null
    }
}

const setClaimUsername = createAction(`${name}/SET_CLAIM_USERNAME`)
const setClaimError = createAction(`${name}/SET_CLAIM_ERROR`)
const resetClaim = createAction(`${name}/RESET_CLAIM`)

const extractUsername = (url) => {
    const socialUrl = new URL(url)
    return socialUrl.pathname.split('/')[1].replace(/^@/, '')
}

const claimSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        'PURGE': () => initialState,
        [setClaimUsername]: (state: any, { payload }: any) => {
            state.claimError = null
            state.username = payload.username
        },
        [resetClaim]: (state: any) => {
            state.claimError = null
        },
        [setClaimError]: (state: any, { payload: error }: any) => {
            state.claimError = error
        },
        [requestSocialUsernames.pending]: (state: any) => {
            state.socialFetchInProgress = true
            state.socialFetchError = null
        },
        [requestSocialUsernames.fulfilled]: (state: any, { payload }: any) => {
            const socialLinks = payload?.social_links ?? []

            state.socialUsernames = socialLinks.reduce(
                (acc, item) => {
                    const response = {
                        ...acc
                    }
                    if (item.type === 'tiktok' || item.type === 'instagram' ) {
                        response[item.type] = extractUsername(item.url)
                    } else if (item.type === 'email') {
                        const email = item.url?.split('mailto:')[1] || item.url
                        response[item.type] = email
                    } else if (item.type === 'facebook') {
                        response[item.type] =  item.url?.split('https://www.facebook.com/')[1]
                    }

                    return response
                },
                { tiktok: null, instagram: null, email: null }
            )
            state.socialFetchError = null
            state.socialFetchInProgress = false
        },
        [requestSocialUsernames.rejected]: (state: any, { payload: error }: any) => {
            state.socialFetchInProgress = false
            state.socialFetchError = error
        },
        [claimPageWithEmail.pending]: (state: any) => {
            state.claimInProgress = true
            state.claimError = null
        },
        [claimPageWithEmail.fulfilled]: (state: any, { payload }: any) => {
            state.claimError = null
            state.claimInProgress = false
            state.claimConfirmed = true
        },
        [claimPageWithEmail.rejected]: (state: any, { payload: error }: any) => {
            state.claimInProgress = false
            state.claimError = error
        }
    },
})

// SELECTORS
const getClaim = (state: any) => state[name]
const getClaimUsername = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.username,
)
const getSocialUsernames = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.socialUsernames,
)
const getSocialFetchInProgress = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.socialFetchInProgress,
)
const getSocialFetchError = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.socialFetchError,
)
const getClaimProgress = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.claimInProgress,
)
const getClaimError = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.claimError,
)
const getClaimConfirmed = createDraftSafeSelector(
    getClaim,
    (Claim: any) => Claim?.claimConfirmed,
)
// GENERAL
// GENERAL
const takes = [
    ...requestSocialUsernames.takes,
    ...claimPageWithEmail.takes,
]

const ClaimInitialState = {
    [name]: initialState,
}

const ClaimReducer = {
    [name]: claimSlice.reducer,
}

const ClaimActions = {
    ...claimSlice.actions
}

// EXPORTS
export default claimSlice.reducer

export {
    ClaimInitialState as initialState,
    ClaimReducer as reducer,
    ClaimActions as actions,
    takes,
    getClaim,
    getClaimUsername,
    getSocialUsernames,
    setClaimUsername,
    setClaimError,
    getClaimProgress,
    getClaimConfirmed,
    getClaimError,
    resetClaim,
    claimPageWithEmail,
    requestSocialUsernames,
    getSocialFetchInProgress,
    getSocialFetchError
}
