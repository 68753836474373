import { showConsoleLogs } from '@/utils/console'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { scrapeTiktok } from '@/entities/Scrapeyard'

const useTiktok = (fetchData = false) => {
    const dispatch = useDispatch()

    const requestTiktok = useCallback((payload) => {
        showConsoleLogs() && console.log('inside of requestTiktok', payload)
        dispatch(scrapeTiktok(payload))
    }, [dispatch])

    return {
        requestTiktok
    }
}

export default useTiktok
