import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'
import scrapeInstagram from './scrapeInstagram'
import scrapeTiktok from './scrapeTiktok'
import fetchInstagramStatus from './fetchInstagramStatus'
import fetchTiktokStatus from './fetchTiktokStatus'
import fetchBeaconsStatus from './fetchBeaconsStatus'
import scrapeLinktree from './scrapeLinktree'
import scrapeBeacons from './scrapeBeacons'
import hydrate from './hydrate'
import { pollBeaconsStatus, pollInstagramStatus, pollTiktokStatus } from './polls'
import { showConsoleLogs } from '@/utils/console'

const initialState = {

    // platforms 
    instagram: {}, // for storing ig scrape results
    tiktok: {}, // for storing tt scrape results
    twitter: {}, // who knows?
    // etc., add other social platforms here

    // here's the stuff we actually need scraped
    linktree: {}, // for storing linktree results
    beacons: {}, // for storing beacons results
    snipfeed: {},
    // etc, add other LiB products here

    // biz flow
    arbitration: false, // provisional, for determining hydration logic
    hydrated: false, // whether we have hydrated blocks 

    ready: false // whether we are ready to redirect to dashboard, w or wo scraped stuff
}

const scrapeyardSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        // TODO: profile / banner image saving
        'PURGE': (state: any) => {
            state = initialState
        },
        // HYDRATE
        [hydrate.pending]: (state: any, { payload: error }: any) => {
        },
        [hydrate.fulfilled]: (state: any, { payload }: any) => {
            showConsoleLogs() && console.log('arbitrate done reducer', payload)
           // state.ready = payload?.ready
           state.hydrated = true
        },
        [hydrate.rejected]: (state: any, { payload: error }: any) => {
            showConsoleLogs() && console.log('hydrate rejected reducer')
        }, 

        // INSTAGRAM
        [fetchInstagramStatus.pending]: (state: any, { payload }: any) => {
            //console.log('inside ig status pending', payload)
            state.instagram.in_progress = true 
            state.instagram.error = null
            state.instagram.scrape_status = 'Pending'
        },
        [fetchInstagramStatus.fulfilled]: (state: any, { payload }: any) => {
            // console.log('ig status fulfilled', payload)
            state.instagram.in_progress = false 
            state.instagram.error = null 
            state.instagram.scrape_status = payload?.status?.status
            state.instagram.linkinbio_platform = payload?.status?.linkinbio_platform
            state.instagram.linkinbio_url = payload?.status?.linkinbio_url
            state.instagram.profile = payload?.status?.data
        },
        [fetchInstagramStatus.rejected]: (state: any, { payload: error }: any) => {
           // console.log('ig status rejected')
            state.instagram.in_progress = false 
            state.instagram.error = error 
            state.instagram.scrape_status = 'Failed'
        },

        [scrapeInstagram.pending]: (state: any, { payload }: any) => {
           // console.log('ig pending', payload)
            state.instagram.in_progress = true 
            state.instagram.error = null
        },
        [scrapeInstagram.fulfilled]: (state: any, { payload }: any) => {
            //console.log('ig fulfilled', payload?.scrape?.status)
            state.instagram.in_progress = false 
            state.instagram.error = null 
            state.instagram.scrape_id = payload?.scrape?.scrape_id 
            state.instagram.scrape_status = payload?.scrape?.status
            state.instagram.handle = payload?.scrape?.social_handle
        },
        [scrapeInstagram.rejected]: (state: any, { payload: error }: any) => {
           // console.log('ig error', error) 
            state.instagram.in_progress = false 
            state.instagram.error = error 
            state.instagram.handle = null 
            state.instagram.scrape_status = null
        }, 

        // TIKTOK
        [fetchTiktokStatus.pending]: (state: any, { payload }: any) => {
            //console.log('inside ig status pending', payload)
            state.tiktok.in_progress = true 
            state.tiktok.error = null
            state.tiktok.scrape_status = 'Pending'
        },
        [fetchTiktokStatus.fulfilled]: (state: any, { payload }: any) => {
            // console.log('fetch tt status fulfilled', payload)
            state.tiktok.in_progress = false 
            state.tiktok.error = null 
            state.tiktok.scrape_status = payload?.status?.status
            state.tiktok.linkinbio_platform = payload?.status?.linkinbio_platform
            state.tiktok.linkinbio_url = payload?.status?.linkinbio_url
            state.tiktok.profile = payload?.status?.data
        },
        [fetchTiktokStatus.rejected]: (state: any, { payload: error }: any) => {
           // console.log('ig status rejected')
            state.tiktok.in_progress = false 
            state.tiktok.error = error 
            state.tiktok.scrape_status = 'Failed'
        },

        [scrapeTiktok.pending]: (state: any, { payload }: any) => {
           // console.log('ig pending', payload)
            state.tiktok.in_progress = true 
            state.tiktok.error = null
        },
        [scrapeTiktok.fulfilled]: (state: any, { payload }: any) => {
            // console.log('tt fulfilled', payload?.scrape?.status)
            state.tiktok.in_progress = false 
            state.tiktok.error = null 
            state.tiktok.scrape_id = payload?.scrape?.scrape_id 
            state.tiktok.scrape_status = payload?.scrape?.status
            state.tiktok.handle = payload?.scrape?.social_handle
        },
        [scrapeTiktok.rejected]: (state: any, { payload: error }: any) => {
           // console.log('ig error', error) 
            state.tiktok.in_progress = false 
            state.tiktok.error = error 
            state.tiktok.handle = null 
            state.tiktok.scrape_status = null
        }, 

        // BEACONS
        [scrapeBeacons.pending]: (state: any, { payload }: any) => {
            // console.log('ig pending', payload)
            state.beacons.in_progress = true 
            state.beacons.error = null
        },
        [scrapeBeacons.fulfilled]: (state: any, { payload }: any) => {
            //console.log('ig fulfilled', payload?.scrape?.status)
            state.beacons.in_progress = false 
            state.beacons.error = null 
            state.beacons.scrape_id = payload?.scrape?.scrape_id 
            state.beacons.scrape_status = payload?.scrape?.status
            state.beacons.handle = payload?.scrape?.social_handle
            state.ready = true
         },
        [scrapeBeacons.rejected]: (state: any, { payload: error }: any) => {
        // console.log('ig error', error) 
            state.beacons.in_progress = false 
            state.beacons.error = error 
            state.beacons.handle = null 
            state.beacons.scrape_status = null
        }, 
        [fetchBeaconsStatus.pending]: (state: any, { payload }: any) => {
            //console.log('inside ig status pending', payload)
            state.beacons.in_progress = true 
            state.beacons.error = null
            state.beacons.scrape_status = 'Pending'
        },
        [fetchBeaconsStatus.fulfilled]: (state: any, { payload }: any) => {
            // console.log('ig status fulfilled', payload)
            state.beacons.in_progress = false 
            state.beacons.error = null 
            state.beacons.scrape_status = payload?.status?.status
            state.beacons.linkinbio_platform = payload?.status?.linkinbio_platform
            state.beacons.linkinbio_url = payload?.status?.linkinbio_url
            state.beacons.data = payload?.status?.data
        },
        [fetchBeaconsStatus.rejected]: (state: any, { payload: error }: any) => {
           // console.log('ig status rejected')
            state.instagram.in_progress = false 
            state.instagram.error = error 
            state.instagram.scrape_status = 'Failed'
        },
        
        // LINKTREE
        [scrapeLinktree.pending]: (state: any, { payload: error }: any) => {
            // console.log('ig pending reducer')
            state.linktree.in_progress = true 
            state.linktree.error = null 
        },
        [scrapeLinktree.fulfilled]: (state: any, { payload }: any) => {
            // console.log('linktree done reducer', payload)
             state.linktree.in_progress = false 
             state.linktree.error = null 
             state.linktree.profile = payload?.profile 
             state.ready = true
             state.linktree.scrape_id = payload?.scrape?.scrape_id
        },
        [scrapeLinktree.rejected]: (state: any, { payload: error }: any) => {
            // console.log('ig rejected reducer')
            state.linktree.in_progress = false 
            state.linktree.error = error 
        },

        
    }
})

// SELECTORS
const getScrapeyard = (state: any) => state[name]

const scrapeyard = createDraftSafeSelector(
    getScrapeyard,
    (Scrapeyard: any) => Scrapeyard
)

const getInstagram = createDraftSafeSelector(
    getScrapeyard,
    (Scrapeyard: any) => Scrapeyard.instagram
)
const getInstagramStatus = createDraftSafeSelector(
    getInstagram,
    (Instagram: any) => Instagram.scrape_status
)

const getLinktree = createDraftSafeSelector(
    getScrapeyard,
    (Scrapeyard: any) => Scrapeyard.linktree
)

const getBeacons = createDraftSafeSelector(
    getScrapeyard,
    (Scrapeyard: any) => Scrapeyard.beacons
)
const getBeaconsStatus = createDraftSafeSelector(
    getBeacons,
    (Beacons: any) => Beacons.scrape_status
)

const getTiktok = createDraftSafeSelector(
    getScrapeyard,
    (Scrapeyard: any) => Scrapeyard.tiktok
)

const getTiktokStatus = createDraftSafeSelector(
    getTiktok,
    (Tiktok: any) => Tiktok.scrape_status
)

const getHydrated = createDraftSafeSelector(
    getScrapeyard,
    (Scrapeyard: any) => Scrapeyard.hydrated
)

// GENERAL
const takes = [
    ...scrapeInstagram.takes,
    ...scrapeTiktok.takes,
    ...fetchInstagramStatus.takes,
    ...fetchTiktokStatus.takes,
    ...fetchBeaconsStatus.takes,
    ...scrapeLinktree.takes,
    ...scrapeBeacons.takes,
    ...hydrate.takes,
]

const ScrapeyardInitialState = {
    [name]: initialState
}

const ScrapeyardReducer = {
    [name]: scrapeyardSlice.reducer,
}

const ScrapeyardActions = {
    ...scrapeyardSlice.actions
}

// EXPORTS
export default scrapeyardSlice.reducer 

export {
    takes,
    ScrapeyardInitialState as initialState,
    ScrapeyardReducer as reducer,
    ScrapeyardActions as actions,
    scrapeyard,
    scrapeInstagram,
    scrapeTiktok,
    fetchInstagramStatus,
    fetchTiktokStatus,
    fetchBeaconsStatus,
    getInstagram,
    getBeacons,
    getInstagramStatus,
    getTiktokStatus,
    getBeaconsStatus,
    getLinktree,
    getScrapeyard,
    scrapeLinktree,
    scrapeBeacons,
    hydrate,
    getTiktok,
    pollBeaconsStatus,
    pollInstagramStatus,
    pollTiktokStatus,
    getHydrated,
}