import { put, select, call } from 'redux-saga/effects'
import { createAsyncSaga, name, getInApp, getLogin} from '../dependencies'
import { godLogin, iosLogin } from '@/entities/Authentication'

const authWithSocial = createAsyncSaga(
    `${name}/authWithSocial`,
    function* ({
        payload: {
            token = null,
            refresh_token = null,
            email = null,
            first_name = null,
            tag_id = null,
            publisher_id = null,
            new_account = null,
            is_a_god = false,
        },
        fulfilled,
        rejected,
    }) {
        try {
            if (!token) {
                throw Error('token must be provided')
            }

            if (!refresh_token) {
                throw Error('refresh_token must be provided')
            }

            if (!email) {
                throw Error('email must be provided')
            }
            const _getInApp = yield select(getInApp)
            const _getLogin = yield select(getLogin)

            if (_getInApp && _getLogin) {
                yield put(yield call(iosLogin, {
                    publisherId: publisher_id,
                    token,
                    isGod: is_a_god,
                }))
    
            } else if (is_a_god) {
                yield put(yield call(godLogin, {
                    publisherId: publisher_id,
                    token,
                }))
    
                throw new Error('god login taking over')
            } else {
                yield fulfilled({
                    token,
                    refresh_token,
                    email,
                    first_name,
                    tag_id,
                    publisher_id,
                    new_account
                })
            }
        } catch (error) {
            // console.log(error)
            yield rejected(error?.message)
        }
    }
)

export default authWithSocial

