import createAsyncSaga from '@/utils/store/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { getIsKitOptionsSettingInProgress } from '@/entities/KitOptions'
import { getClaimError } from '@/features/ClaimUsernameCheck'
// import { 
//     iosLogin, 
//     generateClaim, 
//     getRefreshTokens 
// } from '@/entities/Authentication'
// import { getPublisherId } from '@/entities/Publisher'
import { getInApp } from '@/entities/QueryParams'

const name = 'progress'

export {
    name,
    createAsyncSaga,
    endpoints,
    getIsKitOptionsSettingInProgress,
    getClaimError,
    getInApp,
    // can't import these because of circular dependency?
    // iosLogin,
    // generateClaim,
    // getPublisherId,
    // getRefreshTokens,
}