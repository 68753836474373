import { createAsyncSaga, name, endpoints } from '../dependencies'

const scrapeInstagram = createAsyncSaga(
    `${name}/scrapeInstagram`,
    function* ({
        payload: {
            instagram = null,
            publisherId = null
            
    } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: '/scrapeyard',
                headers: {
                    publisherid: publisherId
                },
                data: {
                    social_handle: instagram,
                    social_platform: 'instagram',
                }
            })

            yield fulfilled({
                scrape: {
                    scrape_id : response.scrape.scrape_id,
                    social_handle: instagram,
                    social_platform: 'instagram',
                    status: response.scrape.status
                }
            })

        } catch (error) {
            yield rejected(error?.message)
        }
    }
)

export default scrapeInstagram
