import { createAsyncSaga, name, endpoints } from '../dependencies'

const requestFeatureFlags = createAsyncSaga(
    `${name}/requestFeatureFlags`,
    function* ({
        payload: {
            userId = null,
            publisherId = null,
        },
        fulfilled,
        rejected,
        request,
    }) {
        try {

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.FEATURE_FLAGS()}/guest`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    ...userId && {
                        user_id: userId,
                    }
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default requestFeatureFlags
