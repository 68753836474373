const instagramUrl = 'https://www.instagram.com/'
const tiktokUrl = 'https://www.tiktok.com/'
const regex = /(?:https?:\/\/)?(?:www.)?(?:twitter|medium|tiktok|facebook|vimeo|instagram)(?:.com\/)?([@a-zA-Z0-9-_.]+)/m

export const parseURLFromUsername = (value, type) => {
    let username = value || null

    const regexValue = (value.match(regex) || [value])[1]

    if (regexValue) {
        username = regexValue
    }

    if (username === null) {
        username = ''
    }

    let noQuestionMark = username.replace(/[?]/g, '')

    switch (type) {
        case 'instagram':
            if (username) {
                const noSpecialChars = username.replace(/[@,?]/g, '')
                return instagramUrl + noSpecialChars
            }
            else {
                return ''
            }
        case 'tiktok':
            if (username) {
                if (!username.startsWith('@')) {
                    noQuestionMark = '@' + noQuestionMark
                }
                return tiktokUrl + noQuestionMark
            }
            else {
                return ''
            }


        default:
            return ''
    }
}


export const formatSocialLinks = ({ value, removeSymbol = false }) => {
    let username = value || ''

    const regexValue = (value?.match(regex) || [value])[1]

    if (regexValue) {
        username = regexValue
    }

    if (username === null) {
        username = '@'
    }

    if (value === instagramUrl || value === tiktokUrl) {
        username = ''
    }

    if (username) {
        const noSpecialChars = username.replace(/[@,?]/g, '')
        return (removeSymbol ? '' : '@') + noSpecialChars

    } else {
        return ''
    }
}
