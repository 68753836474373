import { createAsyncSaga, name, endpoints } from '../dependencies'

const exchangeClaimForToken = createAsyncSaga(
    `${name}/exchangeClaimForToken`,
    function* ({
        payload: {
            claim = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!claim) {
                throw new Error('claim not provided')
            }

            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `auth/publisher/claim/${claim}`,

            })

            yield fulfilled(response)
        } catch (error) {
            console.error(error)
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default exchangeClaimForToken
